.custom-modal {
  &-md {
    width: 100vw;
    max-width: 552px !important;
  }

  &-md-jumbo {
    width: 100vw;
    max-width: 744px !important;
  }

  &-lg {
    width: 100vw;
    max-width: 1104px !important;
  }

  &-xl {
    width: 100vw;
    max-width: 1400px !important;
    max-height: 95vh;
  }

  .mat-dialog-container {
    padding: 24px;
    border-radius: 12px;
    display: inline-block;
  }

  .inner-label {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.005em;
    color: #616161;
  }

  .page-title {
    font-weight: 500;
  }

  &.add-client-modal {
    .mat-dialog-container {
      padding-bottom: 0;
    }
  }

  max-height: 90vh !important;
  overflow-y: auto;

  &.add-task-modal {
    width: 100vw;
    max-width: 90vw !important;

    .mat-dialog-container {
      padding: 0;
      overflow: hidden;
    }
  }
}

.guide-modal {
  .mat-dialog-container {
    padding: 0;
  }
}

// Node module css----------------

.notes-section {
  padding-top: 20px;

  .note-title {
    p {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.0025em;
      color: #212121;
      margin-bottom: 8px;
    }
  }

  .mat-card {
    height: 200px !important;
  }

  .notes-fixed-scroll {
    height: 142px !important;
    overflow-y: auto;
  }

  .task-list-heading {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.0025em;
    color: #212121;
    padding: 14px 30px 0;
  }

  .mat-cart-over-view {
    .card.mat-card.box-card {
      padding: 10px !important;
    }
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: transparent !important;
  }

  .pro-over-p {
    .mat-form-field-flex {
      padding: 0 !important;
    }
  }
}

.project-list-view-common {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 19px;
  .title {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.005em;
    color: #212121;
  }

  .view-all {
    button {
      background-color: transparent;
      border: none;
      padding: 0;
      font-family: "Manrope";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.005em;
      color: #276ef1;
      text-transform: capitalize;

      &:hover {
        color: #276ef1 !important;
      }
    }
  }
}

.favorite-full-height {
  height: calc(100vh - 310px);
}

.favorite-view-all-hide {
  display: none;
}

.all-project-hide {
  display: none;
}

.add-rem-member-button {
  width: 100%;
  text-align: center;
  .mat-ripple.mat-button-ripple.mat-button-ripple-round {
    display: none;
  }
  .mat-button-focus-overlay {
    display: none;
  }
  button {
    color: #276ef1 !important;

    &:hover {
      color: #276ef1 !important;
    }
  }
}

.list-page-common {
  .mat-drawer-inner-container {
    padding-right: 0px;
  }
  .favorite-full-height {
    height: calc(100vh - 350px);
  }

  .favorite-view-all-hide {
    display: none;
  }

  .all-project-hide {
    display: none;
  }

  .all-project-full-height {
    height: calc(100vh - 350px);
  }
}

.pro-details-edit {
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;

  .detail-title {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.0025em;
    color: #212121;
  }

  .details-des {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #9e9e9e;
  }
}

.over-view-cal {
  .mat-card-content {
    display: flex;
    flex-direction: column;
  }
}

.over-view-date {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 8px;
  padding-bottom: 20px;
  position: relative;

  .start-date {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    p {
      margin-bottom: 0;
      font-family: "Manrope";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.005em;
      color: #9e9e9e;
    }

    span {
      margin-bottom: 0;
      font-family: "Manrope";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.0015em;
      color: #616161;

      b {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #212121;
      }
    }
  }

  .over-view-image {
    button {
      border: none;
      background: #edf3fd;
      border-radius: 12px;
      padding: 10px;
      width: 54px;
      height: 54px;
      margin-right: 16px;
    }
  }

  .arrow {
    margin: 0 20px;
  }

  .mat-form-field-infix {
    width: 100px;
    border: none !important;
  }
  .calender-check-box {
    position: absolute;
    bottom: 3px;
  }
}

.custom-model-width {
  max-width: 680px !important;
}

.fovorite-title-group {
  .mat-card-header-text {
    margin: 0 !important;
  }
}

.project-gragh-cart {
  padding-left: 20px;

  li {
    list-style: square;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 8px;
  }
}

.card-title-list {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 28px;
  letter-spacing: 0.0025em;
  color: #212121;
}

.card-title-list-sub {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px;
  letter-spacing: 0.005em;
  color: #616161;

  span {
    color: #9e9e9e;
  }
}

.custom-checkbox {
  justify-content: flex-start !important;
}

.custom-checkbox-p {
  padding-left: 23px !important;
}

.mat-form-field-custom {
  .mat-form-field {
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;

      .mat-form-field-flex {
        background-color: transparent !important;
      }
    }
  }
}

.mat-form-field-custom-pa-0 {
  .mat-icon {
    position: absolute;
    right: -12px;
    top: -2px;
  }

  .mat-form-field-infix {
    padding: 3px 0 10px !important;
  }
}

.mat-datepicker-toggle button:hover {
  color: #616161 !important;
}

.common-list-nav-bar {
  li {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #212121;
    border-bottom: 1px solid #eeeeee;
    padding-top: 12px;
    background-color: #ffffff;
  }
}

.project-list-view-search {
  ul {
    padding: 0px 10px;

    li {
      input {
        width: 100%;
      }
    }

    .input-width {
      width: 80% !important;
    }

    .search-box {
      .mat-form-field-flex {
        background-color: transparent !important;
        padding: 0 !important;
      }

      .mat-form-field-underline {
        display: none;
      }
    }

    .mat-form-field-infix {
      padding: 0 !important;
      width: unset !important;
    }
  }
}

.task-list-heading {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.0025em;
  color: #212121;
  padding: 14px 30px 0;
}

.mat-cart-over-view {
  .card.mat-card.box-card {
    padding: 10px !important;
  }
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent !important;
}

.pro-over-p {
  .mat-form-field-flex {
    padding: 0 !important;
  }
}

.all-project-full-height {
  height: calc(100vh - 310px);

  &.rt-project-full-height {
    height: calc(100vh - 240px);
  }
}

.ctm-date-picker-cls {
  .mat-datepicker-toggle {
    button {
      height: auto !important;
      padding-left: 0;
      margin-left: 0;
      justify-content: left !important;
      width: 100% !important;
    }
  }
}

.ctm-date-picker-cls .custom-input-field.mat-form-field .mat-form-field-prefix {
  top: 7px;
}

.ctm-date-picker-cls .mat-focus-indicator.btn--primary {
  opacity: 1;
}

ul.ctm-calendar-btn {
  li {
    .mat-form-field-type-mat-date-range-input {
      .mat-form-field-wrapper {
        .mat-form-field-underline {
          display: none;
        }

        .mat-form-field-flex {
          padding-left: 7px !important;
          padding-right: 7px !important;
          .mat-form-field-infix {
            display: none;
          }

          .mat-form-field-suffix {
            padding-bottom: 0;
            border: 1px solid #aaa;
            border-radius: 4px;
            margin-top: -13px;

            .mat-focus-indicator.mat-icon-button.mat-button-base {
              .mat-button-focus-overlay {
                display: none;
              }

              .mat-ripple.mat-button-ripple.mat-button-ripple-round {
                display: none;
              }
            }
          }
        }
      }

      .mat-datepicker-toggle {
        button {
          height: 37px !important;

          .mat-icon.material-icons.mat-ligature-font.mat-icon-no-color {
            position: unset !important;
          }
        }
      }
    }
  }
}

.ctm-nav-view {
  .mat-tab-list {
    .mat-tab-links {
      .mat-tab-link {
        &:hover {
          font-weight: 500 !important;
        }
      }

      .mat-tab-label-active {
        color: #212121;
        opacity: 1;
        font-weight: 700;
        border-bottom: solid 2px #276ef1;

        &:hover {
          font-weight: 700 !important;
        }
      }
    }
  }
}

.ctm-project-overview-list
  .search-box-cal-icon
  .mat-form-field
  .mat-form-field-wrapper {
    .mat-datepicker-toggle {
      button {
        height: 37px !important;
      }
    }
  .mat-focus-indicator.mat-icon-button.mat-button-base {
    .mat-ripple.mat-button-ripple.mat-button-ripple-round {
      display: none;
    }

    .mat-button-focus-overlay {
      display: none;
    }
  }
}

.add-time-log-model {
  .c-checkbox-button.mat-checkbox-checked
    .mat-checkbox-layout
    .mat-checkbox-label {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.005em;
    color: #276ef1 !important;
  }

  .c-radio-button .mat-radio-label {
    background-color: #f5f5f5 !important;
  }

  .mat-radio-checked .mat-radio-label {
    background-color: #ffffff !important;
  }

  .c-radio-button.mat-radio-checked .mat-radio-label {
    border-width: 1px;
  }

  .mat-radio-container {
    opacity: 0 !important;
  }

  .c-radio-button.mat-radio-checked .mat-radio-label .mat-radio-label-content {
    color: #276ef1;
  }

  .form-feild-select-custom:after {
    display: none !important;
    border: 0px solid transparent !important;
  }

  .custom-input-field.mat-form-field .mat-form-field-infix,
  .bottom-side-space {
    margin-bottom: 0px;
  }

  .mat-form-field-prefix .mat-datepicker-toggle .mat-icon-button {
    height: inherit !important;
    width: inherit !important;
    padding: 0;
    position: relative;
  }

  .mat-form-field-prefix
    .mat-datepicker-toggle
    .mat-icon-button
    .mat-button-wrapper {
    position: relative;
    top: 7px;
  }

  .custom-input-field.mat-form-field.mat-form-field-should-float
    .mat-form-field-prefix,
  .custom-input-field.mat-form-field.mat-focused .mat-form-field-prefix {
    top: 0 !important;
  }

  .custom-input-field.mat-form-field .mat-form-field-prefix {
    top: 0px !important;
  }

  .select-custom-time {
    display: flex;

    .mat-form-field-appearance-fill .mat-form-field-flex {
      border-radius: 4px 4px 0 0;
      padding: 0;
    }

    .select-custom-time-field {
      .mat-form-field-wrapper {
        margin-right: 14px;
      }
    }

    .mat-form-field-infix {
      width: 150px;
      border-top: 0em solid transparent !important;
      input {
        padding-left: 32px;
        font-family: "Manrope";
        font-style: normal;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 22px;
        letter-spacing: 0.005em;
        color: #9e9e9e;
        width: 114px;
      }

      img {
        position: absolute;
      }
    }

    .mat-form-field-appearance-fill .mat-form-field-underline:before {
      background-color: #eeeeee;
    }

    .mat-form-field-ripple {
      background-color: #276ef1 !important;
      color: #276ef1 !important;
    }
  }

  .task-field {
    padding-left: 34px;
    font-style: normal;
    font-weight: 500 !important;
    width: 90%;
  }

  .task-icons {
    position: absolute;
    top: 9px;
  }

  .time-title {
    h4 {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.005em;
      color: #616161;
      margin-bottom: 8px;
    }
  }

  .mat-dialog-content {
    overflow-x: hidden;
  }
}

.mat-form-textarea {
  .project-icons {
    position: absolute;
    top: 11px;
  }

  .mat-form-field-infix {
    textarea {
      padding-left: 34px;
      resize: none;
    }
  }
}

.model-footer-ctm {
  .model-footer-ctm-top {
    border-top: 1px solid #eeeeee;
  }

  .total-time {
    display: flex;
    flex-direction: column;

    p {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.005em;
      color: #616161;
      margin-bottom: 0;
    }

    h4 {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.0025em;
      color: #616161;
      margin-bottom: 0;
    }
  }
}

.start-end-time {
  position: relative;
  left: 17px;

  input {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #9e9e9e;
    border-radius: 8px;

    &::placeholder {
      font-weight: 500;
      font-size: 14px;
    }
  }

  .mat-time-pciker.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 3px 0 5px;
  }

  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0.25em 5px !important;
  }

  .mat-form-field-flex:hover .mat-form-field-outline {
    color: #276ef1 !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 8px 0px 0px 8px;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0 8px 8px 0;
  }
}

.form-feild-select-custom .mat-chip-list-wrapper .mat-standard-chip {
  margin: 4px 4px 4px 8px !important;
}

// ----------project overview responsive css ---------

@media (min-width: 1600px) {
  .project-overview-container {
    max-width: 100% !important;
    padding: 0 24px !important;
  }
}

@media (min-width: 1199px) {
  .project-overview-container {
    max-width: 98% !important;
    padding: 0px 24px !important;
  }
}

.ctm-project-overview-list .mat-cart-over-view .card.mat-card.box-card {
  padding: 15px !important;
}

@media (max-width: 1600px) {
  .add-task-modal {
    max-height: 100vh !important;
  }

  .ctm-project-overview-list .start-date .mat-checkbox {
    right: 0;
  }

  .over-view-date .over-view-image button {
    margin-right: 10px;
  }

  .donut-inners {
    top: 2px;
  }

  .ctm-fav-card-layout .float-end.me-1 {
    font-size: 10px !important;
  }

  .due-background {
    padding: 5px 4px !important;
    font-size: 10px !important;
  }
}

@media (max-width: 1500px) {
  .project-member {
    .mat-card-content {
      .mat-tab-body-wrapper {
        .card.mat-card.box-card {
          width: 150px !important;
        }

        .n-s-s .slick-list {
          padding: 0 0px 0 0 !important;

          .slick-track {
            display: flex !important;
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .project-overview-chart canvas {
    width: 85px !important;
    height: 85px !important;
  }

  .project-overview-chart {
    .donut-inner {
      margin-top: -54px !important;
      margin-left: 39px !important;

      &.do-in {
        margin-top: 0 !important;
        margin-left: 0 !important;
      }
    }
  }

  .ctm-fav-card-layout .project-gragh-cart li .list-details .count-background {
    margin-left: 0px !important;
  }

  .mat-drawer-content-wrap-cls .ctm-fav-card-layout {
    padding: 20px !important;
  }

  .donut-inners {
    top: 1px !important;
  }
}

.custom-mat-drawer-content-wrp {
  .project-list-mat-tab-ctm {
    .mat-tab-group {
      .mat-tab-body-wrapper {
        display: inline-block !important;
      }
    }
  }
}

.project-overview-container {
  .status-analysis-card-view {
    .mat-card-content {
      .second-part {
        .graph-view {
          position: relative;

          .donut-inners-status {
            position: absolute;
            font-weight: 700;
            font-size: 18px;
            color: #212121;
          }
        }
      }
    }
  }
}

// Timesheet Calendar Css

.main-dialog-calendar {
  &.math_ui_block-dialog-calendar {
    width: 400px !important;
    height: 172px !important;

    .labeling {
      label {
        font-size: 14px !important;
      }
    }
    .first-part {
      width: 50%;
      input {
        width: 100% !important;
        margin-top: 10px;
      }
    }
    .second-part {
      width: 50%;
      input {
        width: 100% !important;
        margin-top: 10px;
      }
    }
  }
}

// Task List Timelog Css
.add-edit-task-main-block {
  p.total-hr.new-postion-set-cls {
    border-bottom-right-radius: 8px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .math_ui_inside-actual-hours-number {
      flex-direction: row;
      display: flex;
      .math_ui_inside-actual-hours-label,
      .math_ui_inside-estimate-hours-label {
        color: #616161 !important;
      }

      .math_ui_inside-actual-hours-number {
        color: #1d53b7 !important;
      }
      .math_ui_inside-estimate-hours-label-number {
        color: #16b975 !important;
      }
    }
  }
}
