@import "./custom-variable";

.main-content-coantainer-dms {
  padding: 53px 20px;
}

.dms-container {
  height: calc(100vh - 132px);
}

.team-folder-box-list {
  border-top: 1px solid #eeeeee;

  h4 {
    padding: 24px 24px 0;
  }
}

.team-folder-box {
  border-top: none;
  border-bottom: 1px solid #eeeeee;
  padding: 0px 24px 24px;
  overflow-y: scroll;
  max-height: 285px;
  > li {
    a {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.005em;
      color: #616161;
      display: flex;
      align-items: center;
      padding: 12px 0;
      column-gap: 10px;
    }
  }
}

.progress-block {
  padding: 24px;
  border-top: 1px solid #eeeeee;

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #616161;
    margin-top: 10px;
    display: inline-block;
  }

  .mat-progress-bar {
    border-radius: 4px;

    .mat-progress-bar-buffer {
      background: #e0e0e0;
      border-radius: 4px;
    }

    .mat-progress-bar-fill:after {
      background-color: #276ef1;
    }
  }
}

.card.file-folder-card {
  border: 1px solid #eeeeee;
  border-radius: 8px;

  &.file-card {
    .card-body {
      height: 160px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .card-footer {
    background: transparent;
    border-top: 0;
    padding: 11px 13px;

    .cmn-txt {
      max-width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &:hover {
    border-color: #67acf0;
  }
}

.file-folder-view {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
  grid-gap: 1rem;
  height: auto;
  align-items: flex-start;
  overflow-y: scroll;

  @media screen and (max-height: 1020px) {
    max-height: 37.8vh;
  }

  @media screen and (min-height: 1020px) {
    grid-gap: 1.2rem;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    height: 40vh;
  }

  @media screen and (min-height: 1440px) {
    grid-gap: 1.2rem;
    grid-template-columns: repeat(auto-fill, minmax(385px, 1fr));
    height: 40vh;
  }

  @media screen and (min-height: 1446px) {
    grid-gap: 1.4rem;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    height: 41.5vh;
  }

  @media screen and (min-height: 1646px){
    grid-gap: 1.4rem;
    grid-template-columns: repeat(auto-fill, minmax(475px, 1fr));
    height: 35.5vh;
  }
}

.no-file-folder-found {
  height: 400px !important;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f5f5f5;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none;
  p {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.0025em;
    color: #212121;
    margin: 10px 0 0;
  }

  small {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #616161;
    margin-top: 8px;
  }
}

.right-menu {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgb(33 33 33 / 8%);
  border-radius: 8px;
  width: 256px;
  padding: 8px;
  overflow-y: auto;

  button {
    border: 0;
    background: transparent;
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #616161;
    padding: 10px 24px;
    display: flex;
    align-items: center;

    &:hover {
      background: #f5f5f5;
      color: #212121 !important;
    }
  }
}

.dms-sub-menu {
  .color-menu {
    height: 8px;
    width: 8px;
    border-radius: 2px;
    margin-right: 12px;
  }
}

.no-bin-found {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  p {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.0025em;
    color: #212121;
    margin: 10px 0 0;
  }

  small {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #616161;
    margin-top: 8px;
  }
}

.doc-search {
  max-width: 500px;
  height: 34px;
  font-size: 14px;
  border: 1px solid #aaa !important;
  background-color: #fafafa;
}

.dms-content-block {
  height: 100%;

  app-my-folder,
  app-shared,
  app-favourite,
  app-team-folders {
    height: 100%;

    > div {
      height: 100%;

      > div {
        height: 100%;

        > .table-responsive {
          min-height: 50%;
          max-height: 50%;
        }
      }
    }
  }

  app-bin {
    height: 100%;

    > div.custom-bin {
      height: 100%;

      > div.tables,
      > div.grid-view-main-block {
        height: 100%;

        > .table-responsive {
          min-height: 50%;
          max-height: 50%;
        }
      }
    }
  }
}

.dms-upload-screen {
  position: absolute;
  background: #80808024;
  z-index: 999999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 2px solid gray;
  border-style: dashed;
  border-radius: 15px;
  width: 99%;
  height: 98%;
  display: none;
}

.grey-custom-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.005em;
  color: #9e9e9e;
  text-decoration: none;
}

.input-with-check {
  position: relative;

  .mat-checkbox {
    position: absolute;
    top: 6px;
    left: 10px;
  }

  input.c-s-input {
    background-position: left 35px center;
    padding-left: 60px;
  }
}

.share-member-list {
  .checklist-member-team {
    > li {
      padding-left: 10px;
      margin-bottom: 10px;
    }
  }
}

.custom-modal {
  &.dms-modal {
    width: 100%;
    max-width: 576px !important;
  }
}

.owner-detail-block {
  > img {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: 1px solid #ffffff;
    object-fit: cover;
    margin-right: 16px;
  }

  .user-detail {
    h3 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.005em;
      color: #212121;
      margin-bottom: 0;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.005em;
      color: #9e9e9e;
      margin-bottom: 0;
    }
  }

  & + small {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    letter-spacing: 0.005em;
    color: #9e9e9e;
  }
}

.mat-checkbox.only-name {
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: #212121;
}

.dms-detail-side-bar {
  width: 312px;
  background: #fff;
  padding: 18px;
  overflow: visible !important;
}

.dms-detail-list > li {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.005em;
  color: #9e9e9e;
  margin-bottom: 10px;
}

.close-drawer {
  position: absolute;
  right: calc(100% - 12px);
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 4px 12px rgba(33, 33, 33, 0.08);
  border-radius: 16px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.access-member-list {
  > li {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & + a {
    img {
      transform: rotate(90deg);
    }
  }
}

.fav-icon {
  height: 30px;
  width: 30px;
  background: #0000007a;
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 15px;
  right: 20px;
}

.w-180 {
  width: 180px;
}

.mb-5-px {
  margin-bottom: 5px;
}

.dms-custom-checkbox {
  border-color: #9e9e9e;
  margin-top: -5px;
}

.collapse-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.team-folder-box.team-folders-overflow {
  overflow-y: hidden;
  max-height: 215px;
}

.custom-divider {
  margin: 7px 0 24px;
}

.checkbox-section {
  .mat-checkbox {
    margin: 5px 0;
    width: 100%;

    .mat-checkbox-layout {
      .mat-checkbox-inner-container {
        margin-right: 20px;
      }

      .mat-checkbox-label {
        .user-check {
          width: 20px;
          height: 20px;
          object-fit: contain;
          margin-right: 10px;
        }

        color: $text-gery-color;
        font-size: $font-size-14;
        font-weight: $Medium;
        letter-spacing: 0.005em;
      }
    }
  }
}

.user-dms-container {
  height: calc(100vh - 76px);
}
