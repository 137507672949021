.plan--card {
  max-width: 360px;
  background: #ccc;
  border-radius: 16px;
  border: 0;
  width: 100vw;
  .card-body {
    padding: 24px;
    h6 {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0.0015em;
      color: #212121;
      margin-bottom: 0;
    }
    p {
      font-weight: 500;
      font-size: 40px;
      line-height: 54px;
      color: #000;
      margin-bottom: 0;
      sub {
        font-size: 14px;
        bottom: 0;
      }
    }
    small {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.005em;
      color: #616161;
      display: inline-block;
    }
    ul {
      > li {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.005em;
        color: #616161;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        img {
          margin-right: 13px;
        }
      }
    }
  }
  .blue-gredient-btn.mat-button.btn {
    font-size: 16px;
  }
  &.main {
    background: var(--priamry-gredient);
    .card-header {
      padding: 14px 0;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      color: #ffffff;
      background: #212121;
      border-radius: 16px 16px 0 0;
      text-align: center;
      font-weight: 700;
    }
    .card-body {
      padding: 24px 24px 54px;
      .blue-gredient-btn.mat-button.btn {
        background: #ffffff;
        box-shadow: 0px 8px 16px rgb(33 33 33 / 12%);
        border-radius: 12px;
        color: #212121 !important;
        font-size: 16px;
      }
      .plan--benefit-list {
        > li:last-child {
          margin-bottom: 40px;
        }
      }
      small.plan--price-duration {
        color: #d4e2fc;
      }
      .hr--divider {
        opacity: 0.2;
      }
    }
  }
}

.plan--list {
  > span {
    position: relative;
    &:nth-child(2) > li {
      margin: 0 -10px;
      position: relative;
      z-index: 1;
    }
    &:last-child > li {
      .plan--card {
        .card-body {
          padding: 24px 24px 24px 32px;
        }
      }
    }
  }
}
.hr--divider {
  border-bottom: 1px solid #ffffff;
  margin: 16px 0 24px;
  &.hr-width-4 {
    border-bottom-width: 4px;
  }
  &.grey-border {
    border-bottom-color: #eeeeee;
  }
}

.custom--switch {
  background: #edf3fd;
  border-radius: 16px;
  padding: 8px 16px;
  width: fit-content;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #616161;
}
.offer--txt {
  position: absolute;
  z-index: 10;
  left: calc(100% - 78px);
  top: -15px;
  .first--img {
    position: absolute;
    top: -47px;
  }
}
.plan__client-section {
  margin-top: 96px;
  padding-bottom: 96px;
}
.plan__faq-section {
  padding: 96px 0;
  background: #f5f5f5;
}
:root .mat-expansion-panel.white-bg-custom-collapse {
  background: #ffffff;
  border-radius: 12px !important;
  box-shadow: none;
  margin-bottom: 12px;
  .mat-expansion-panel-header {
    padding: 32px 24px;
    height: auto;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.0025em;
    color: #212121;
    &:hover {
      background: #fff !important;
    }
  }
  .mat-expansion-panel-body {
    padding: 0 24px 32px;
  }
}

.proffessional-plus-list {
  > li {
    &::after {
      content: "";
      height: 4px;
      width: 4px;
      background: #616161;
      border-radius: 50%;
      display: inline-block;
      vertical-align: middle;
      margin: 7px;
      margin-top: 5px;
    }
    &:last-child::after {
      display: none;
    }
  }
}

.billing--table {
  th {
    background: #44b8ea;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.005em;
    color: #ffffff;
    border-bottom: 0;
  }
  td {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.005em;
    color: #212121;
    vertical-align: bottom;
    border-bottom: 0;
  }
  tbody {
    tr:first-child {
      td {
        border-bottom: 1px solid #eeeeee;
      }
    }
    tr:last-child {
      td {
        background: #edf3fd;
      }
    }
    tr:not(:first-child) {
      td {
        text-align: right;
      }
    }
    .highlighted-cell {
      background: #f5f5f5;
      font-weight: bold;
    }
  }
}
.payment-box {
  padding: 16px;
  background: #f5f5f5;
}
