@import "./custom-variable";

button {
  &:focus {
    outline: none;
  }

  &:hover {
    color: $white-color;
  }
}

.btn.blue-gredient-btn {
  &.mat-button.btn {
    background: var(--priamry-gredient);
    box-shadow: 0px 8px 16px rgba(33, 33, 33, 0.1);
    border-radius: 12px;
    padding: 16px 24px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0025em;
    color: #ffffff;
    white-space: nowrap;
    width: auto;
    display: flex;
    align-items: center;
    &.fixed-bottom {
      position: fixed;
      bottom: 16px;
      right: 24px;
      z-index: 100;
      left: unset;
    }
  }
  &_md {
    height: 40px;
  }
  &_border-rdius-8 {
    border-radius: 8px;
  }
}

// collapse
.custom-accordion {
  .arrow-icon {
    transition: 0.2s linear;
  }
  a[aria-expanded="true"] {
    .arrow-icon {
      transform: rotate(180deg);
    }
  }
}

.btn.btn {
  &.mat-button {
    padding: 5px 32px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
  }
  &--primary {
    background: #276ef1 !important;
    color: #ffffff !important;
  }
  &--bordered {
    background: #ffffff !important;
    border: 1px solid #eeeeee !important;
    color: #276ef1 !important;
    &:hover {
      background: #ffffff !important;
      border: 1px solid #eeeeee;
      color: #276ef1 !important;
    }
    &--primary {
      border: 1px solid #276ef1 !important;
    }
  }
}
