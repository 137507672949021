@import "./custom-variable";
$colors: (
  "c-grey": #757575,
  "c-red": #dc3535,
  "c-violet": #6f5ce3,
  "c-lightorange": #fab222,
  "c-lightgreen": #19b1bb,
  "c-darkgreen": #16b975,
  "c-darkorange": #f2793d,
  "c-darkblue": #3477dc,
);

input,
textarea {
  &:focus {
    outline: 0;
  }
}
.custom-input {
  height: 40px;
  color: #9e9e9e;
  border: 1px solid #eeeeee;
  outline: 0;
  &:focus {
    outline: 0;
  }
}

.custom-input-field.mat-form-field {
  .mat-form-field-prefix {
    margin-right: 15px;
    transition: top 0.2s linear;
    top: -4px;
  }
  .mat-form-field-infix {
    padding: 0.5em 0 12px;
    border-top: 0px;
  }
  &.mat-form-field-should-float,
  &.mat-focused {
    .mat-form-field-prefix {
      top: -10px;
    }
    .mat-form-field-label {
      color: var(--primary-color);
    }
  }
  &.mat-focused {
    .mat-form-field-ripple {
      opacity: 0.4 !important;
    }
    .mat-form-field-underline {
      background-color: transparent;
    }
  }
  .mat-form-field-ripple {
    background-color: var(--primary-color);
  }
  .mat-form-field-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #9e9e9e;
  }
  .mat-form-field-underline {
    background-color: #eeeeee;
  }
  input {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #212121;
    height: 22px;
  }
  .mat-form-field-flex {
    padding-top: 0;
  }

  .p-s-h-btn {
    .mat-icon {
      color: var(--primary-color);
    }
    &[aria-pressed="true"] {
      .mat-icon {
        color: #bdbdbd;
      }
    }
  }
  &.custom-date-input-field {
    .mat-form-field-prefix {
      margin-right: 0;
      margin-left: -10px;
      position: relative;
      top: 4px;
    }
    &.mat-form-field-should-float,
    &.mat-focused {
      .mat-form-field-prefix {
        top: -10px;
      }
    }
  }
}

.c-s-input {
  background: #f5f5f5 url("./../icons/searchicon.svg") no-repeat;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  width: 100vw;
  max-width: 264px;
  padding-left: 40px;
  background-position: left 10px center;
  &:hover,
  &:active,
  &:focus {
    background: #f5f5f5 url("./../icons/searchicon.svg") no-repeat;
    background-position: left 10px center;
    box-shadow: none;
  }
}

//radio button custom

.c-radio-button,
.c-checkbox-button {
  .mat-radio-label,
  .mat-checkbox-layout {
    padding: 6px 15px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    position: relative;
    .mat-radio-container,
    .mat-checkbox-inner-container {
      height: 0;
      width: 0;
      position: static;
      margin: 0;
      .mat-radio-outer-circle,
      .mat-checkbox-ripple {
        display: none;
      }
      .mat-radio-inner-circle {
        height: 100%;
        width: 100%;
        border-radius: 6px;
      }
      .mat-checkbox-background {
        transform: scale(0);
        height: 100%;
        width: 100%;
        border-radius: 8px;
        svg {
          display: none;
        }
      }
      .mat-checkbox-frame {
        display: none;
      }
    }
    .mat-radio-label-content,
    .mat-checkbox-label {
      position: relative;
      z-index: 0;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.005em;
      color: #616161;
      padding-left: 0;
    }
  }
  &.mat-radio-checked,
  &.mat-checkbox-checked {
    .mat-checkbox-layout {
      padding-left: 30px;
    }
    .mat-radio-inner-circle,
    .mat-checkbox-background {
      transform: scale(1) !important;
      background-color: #fff !important;
      > svg {
        display: block !important;
        position: static;
        width: 20px;
        margin: 0 7px;
        .mat-checkbox-checkmark-path {
          stroke: var(--primary-color) !important;
        }
      }
    }
    .mat-radio-label,
    .mat-checkbox-layout {
      border-color: var(--primary-color);
      border-width: 2px;
      .mat-radio-label-content,
      .mat-checkbox-label {
        color: #616161;
      }
    }
  }
  &.mat-radio-button.mat-accent .mat-radio-inner-circle,
  &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #bdc2ca !important;
  }
}

// mat chip
:root {
  .mat-chip.u-m-chip {
    background: #eeeeee;
    border-radius: 18px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.005em;
    color: #616161;
    display: flex;
    align-items: center;
    padding: 2px 10px 2px 2px;
    > img {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 1px solid #fff;
    }
    &.m-s-u-chip {
      padding: 2px 6px;
    }
    .user-short-name {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      font-size: 10px;
    }
  }
  .custom-checkbox {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #212121;
    .mat-checkbox-frame {
      border-color: #bdbdbd;
    }
    &.mat-checkbox-checked {
      .mat-checkbox-background {
        background-color: var(--primary-color);
      }
    }
    .custom-checkbox {
      .mat-checkbox-frame {
        border-color: #bdbdbd;
      }
      &.mat-checkbox-checked {
        .mat-checkbox-background {
          background-color: var(--primary-color);
        }
      }
      .mat-ripple {
        display: none;
      }
    }
  }
}
.custom-color-radiobutton {
  .mat-radio-container {
    height: 32px;
    width: 32px;
    .mat-radio-outer-circle {
      height: 32px;
      width: 32px;
      border: 0;
    }
    .mat-radio-inner-circle {
      height: 30px;
      width: 30px;
      left: -2px;
      top: -2px;
    }
  }
  @each $colorcode, $color in $colors {
    &.#{$colorcode} {
      .mat-radio-outer-circle {
        background: $color;
      }
    }
  }
  &.mat-radio-checked {
    .mat-radio-inner-circle {
      background: url(../images/whietcheckicon.svg) no-repeat;
      background-color: unset !important;
      background-position: center center;
      background-size: 26px;
    }
    .mat-radio-outer-circle {
      position: relative;
      &:after {
        content: "";
        height: 33px;
        width: 33px;
        border: 1px solid #212121;
        position: absolute;
        border-radius: 50%;
        left: -4px;
        top: -4px;
      }
    }
    .mat-ripple {
      display: none;
    }
  }
}

//file uploaad
.file-upload-block {
  padding: 50px;
  background: #f5f5f5;
  border: 1px dashed #e0e0e0;
  border-radius: 8px;
}

.c-text-checkbox {
  .mat-checkbox-inner-container {
    display: none;
  }
  .mat-checkbox-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #616161;
    border-bottom: 1px solid transparent;
  }
  &.mat-checkbox-checked {
    .mat-checkbox-label {
      color: var(--primary-color);
      border-bottom-color: var(--primary-color);
    }
  }
}

.custom-field-box {
  .custom-input-field.mat-form-field {
    .mat-form-field-underline {
      display: none;
    }
    .mat-form-field-label {
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.005em;
      color: var(--primary-color);
    }
  }
}

.custom-input-field {
  input,
  textarea {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #444;
  }
}
.custom-model-width {
  .form-feild-select-custom span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #9e9e9e;
  }
  .mat-checkbox {
    margin-top: -12px;
    .mat-checkbox-layout .mat-checkbox-label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.005em;
      color: #9e9e9e;
    }
    .mat-checkbox-inner-container {
      width: 13px;
      height: 13px;
    }
  }
}

.custom-modal.add-client-modal .mat-dialog-container {
  overflow-x: hidden;
}

.mat-chip-list {
  .mat-chip-list-wrapper {
    .mat-chip.u-m-chip.m-s-u-chip {
      background: #eeeeee !important;
      &:focus {
        background: #eeeeee !important;
        &::after {
          content: unset;
        }
      }
    }
  }
}

.mat-datepicker-actions {
  .mat-focus-indicator.mat-button.mat-button-base:hover {
    color: #616161 !important;
  }
}
