// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
@use "./assets/scss/auth";
@use "./assets/scss/button";
@use "./assets/scss/responsive";
@use "./assets/scss/navbar";
@use "./assets/scss/note";
@use "./assets/scss/text";
@use "./assets/scss/input";
@use "./assets/scss/commonUtility";
@use "./assets/scss/profile";
@use "./assets/scss/card";
@use "./assets/scss/modal";
@use "./assets/scss/management";
@use "./assets/scss/company";
@use "./assets//scss/table";
@use "./assets/scss/orgsettings";
@use "./assets/scss/plan";
@use "./assets/scss/task";
@use "./assets/scss/addTask";
@use "./assets/scss/chatbox";
@use "./assets/scss/attachmentbx";
@use "./assets/scss/logactivity";
@use "./assets/scss/customField";
@use "./assets/scss/dms";
@use "./assets/scss/user-profile";
@use "./assets/scss/laptop_responsive";
@use "./assets/scss/leave-management";
@import "./assets/scss/custom-variable";
@import "./assets/scss/notification";
@import "./assets/scss/reminder";
@import "./assets/scss/_email_management";
@import "./assets/scss/reports";
@import "./assets/scss/addService";
@import "./assets/scss/dashboard";

// Plus imports for other components in your app.
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$font-family_1: "Manrope", sans-serif;
@include mat.core();
@import "@angular/material/theming";
$custom-typography: mat-typography-config(
  $font-family: $font-family_1,
);
@include mat.core($custom-typography);
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$taskopadnewversion-primary: mat.define-palette(mat.$indigo-palette);
$taskopadnewversion-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);
// The warn palette is optional (defaults to red).
$taskopadnewversion-warn: mat.define-palette(mat.$red-palette);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$taskopadnewversion-theme: mat.define-light-theme(
  (
    color: (
      primary: $taskopadnewversion-primary,
      accent: $taskopadnewversion-accent,
      warn: $taskopadnewversion-warn,
    ),
  )
);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($taskopadnewversion-theme);

/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
$font-family_1: "Manrope", sans-serif;

:root {
  .mat-button-toggle,
  .mat-card {
    font-family: $font-family_1;
  }
}

body {
  font-family: $font-family_1;
  overflow-x: hidden;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mat-form-field img {
  pointer-events: none;
}

html,
body {
  height: 100%;
}

:root {
  .ngx-mat-tel-input-container {
    .mat-button {
      text-align: left;
      padding: 6px 0 12px;
      background-position: right top 5px;
    }
  }

  .ngx-mat-tel-input-mat-menu-panel {
    max-height: 300px;

    &::-webkit-scrollbar-track {
      background-color: #fff !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #fafafa !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
      border: 31px solid #fafafa;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #fff;
      z-index: 0;
      border-radius: 14px;
      overflow: auto;
      border: 6px solid #ccc;
    }
  }
}

.user-profile-pic-section
  .mat-form-field-appearance-legacy
  .mat-form-field-underline {
  height: 0px;
}

.word-break-all {
  word-break: break-all;
}

.min-width-200 {
  min-width: 200px;
}

.max-width-200 {
  min-width: 200px;
}

.w-50p {
  width: 50%;
}

.w-20 {
  width: 20px;
}

// .country-selector{
// 	opacity:1 !important;
//   }
.mat-tabe-content {
  .mat-tab-header {
    background: $white-color;

    .mat-tab-label {
      opacity: 1;

      .mat-tab-label-content {
        color: $text-dark-grey-clor;
        font-size: $font-size-14;
        font-weight: $Medium;
        text-align: center;
      }
    }

    .mat-tab-label-active {
      .mat-tab-label-content {
        color: $text-dark-black-color;
        font-size: $font-size-14;
        font-weight: $Bold;
        text-align: center;
      }
    }

    .mat-ink-bar {
      background-color: var(--primary-color);
    }
  }

  .mat-tab-body-wrapper {
    .mat-tab-body {
      .mat-tab-body-content {
        overflow: auto;
        height: calc(100vh - 250px);

        .user-chat-box-cover {
          padding: 0 20px;

          .user-chat-box {
            display: flex;
            align-items: center;
            justify-content: left;
            text-decoration: none;
            padding: 10px 0px;
            cursor: pointer;
            // border-bottom: 1px solid $light-grey-color;

            .user-sortname {
              width: 32px;
              height: 32px;
              line-height: 32px;
              object-fit: cover;
              border-radius: 50%;
              font-size: $font-size-12;
              text-transform: uppercase;
              vertical-align: middle;
              position: relative;
              color: $white-color;
              font-weight: $Medium;
              text-align: center;

              .usertag {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                position: absolute;
                bottom: 0;
                right: 0;
              }
            }

            // .user-ba {
            //     background: #3477dc;
            // }
            // .user-fr {
            //     background: #6f5ce3;
            // }
            // .user-ui {
            //     background: #f2793d;
            // }
            // .user-pk {
            //     background: #19b1bb;
            // }
            .user-name {
              padding-left: 16px;
              width: calc(100% - 32px);

              h2 {
                font-size: $font-size-14;
                font-weight: $Medium;
                color: $text-dark-black-color;
                letter-spacing: 0.005em;
                text-align: left;
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-transform: capitalize;
                margin-bottom: 0;

                small {
                  font-size: $font-size-11;
                  color: $text-gery-color;
                  font-weight: $Semi-bold;
                  letter-spacing: 0.005em;
                }
              }

              p {
                font-size: $font-size-12;
                color: $text-gery-color;
                font-weight: $Medium;
                letter-spacing: 0.005em;
                padding-top: 5px;
                margin-bottom: 0;

                // span {
                //     min-width: 30px;
                //     float: right;
                //     text-align: center;
                //     font-size: 11px;
                //     font-weight: $Semi-bold;
                //     color: $white-color;
                //     background: #DC3535;
                //     border-radius: 16px;
                //     padding: 4px;
                // }
                img.thanksimg {
                  max-width: 100%;
                  margin-top: -2px;
                }
              }
            }
          }
        }

        .active {
          // background-color: #edf3fd !important;
          position: relative;

          &::before {
            background: #276ef1;
            border-radius: 0px 4px 4px 0px;
            width: 4px;
            height: 44px;
            // content: "";
            position: absolute;
            left: 0;
            top: 17px;
          }
        }
      }
    }
  }
}

.word-wrap {
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mat-drawer-content {
  overflow: inherit;
}

::-webkit-scrollbar-track {
  background-color: #f5f1f5 !important;
  z-index: 0;
  border-radius: 10px;
  overflow: auto;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f1f5 !important;
  z-index: 0;
  border-radius: 10px;
  overflow: auto;
}

// Company Details in Organization
.company-details-only {
  max-height: 100vh;
  overflow: auto;

  &::-webkit-scrollbar-track {
    background-color: #fff !important;
    z-index: 0;
    border-radius: 10px;
    overflow: auto;
    border: 10px solid #fff !important;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #fafafa !important;
    z-index: 0;
    border-radius: 10px;
    overflow: auto;
    border: 31px solid #fafafa;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    z-index: 0;
    border-radius: 14px;
    overflow: auto;
    border: 6px solid #ccc;
  }
}

.main-content-coantainer.ctm-user-list-view.dashboard-content.math_ui_block-dashboard-rest-content
  .math_ui_block-dashboard-rest-content
  .math_ui_inside-tasks
  .user-profile-task-list.math_block-user-profile-tasklist-tab.math_ui-onHover.dashboard-class
  .list-table-hscroll {
  &::-webkit-scrollbar-track {
    // background-color: #d613d6 !important;
    z-index: 0;
    border-radius: 10px;
    overflow: auto;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #f5f1f5 !important;
    z-index: 0;
    border-radius: 10px;
    overflow: auto;
  }

  &::-webkit-scrollbar-thumb {
    // background-color: #23ce1d !important;
    z-index: 0;
    border-radius: 10px;
    overflow: auto;
  }
}

.common-quill-editor {
  &::-webkit-scrollbar-track {
    // background-color: #d613d6 !important;
    z-index: 0;
    border-radius: 10px;
    overflow: auto;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #f5f1f5 !important;
    z-index: 0;
    border-radius: 10px;
    overflow: auto;
  }

  &::-webkit-scrollbar-thumb {
    // background-color: #ccc !important;
    z-index: 0;
    border-radius: 10px;
    overflow: auto;
  }
}

::-webkit-scrollbar-thumb {
  background-color: #f5f1f5;
  z-index: 0;
  border-radius: 10px;
  overflow: auto;
}

.card-footer.d-flex.alng.ign-items-center {
  cursor: pointer;
}

.list-view-table tbody tr td.text-dark.word-break-all.w-50p {
  cursor: pointer !important;
}

.team-folder-box {
  li {
    display: contents;

    a {
      label {
        -webkit-box-orient: vertical;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: calc(100% - 16px);
      }
    }
  }
}

img.img-avatar.ml-auto {
  margin-left: auto;
}

.mat-tab-links {
  .mat-tab-link {
    padding: 0 16px;
    min-width: 115px;
    margin: 0 5px;
    color: #212121;
    border-bottom: solid 2px transparent;
    font-weight: 500;

    &:hover {
      color: #212121;
      opacity: 1;
      font-weight: bold;
      border-bottom: solid 2px #276ef1;

      path {
        fill: #212121;
        opacity: 1;
      }
    }
  }

  .cdk-focused {
    opacity: 1;
    border-bottom: solid 2px #276ef1;
    font-weight: bold;

    path {
      fill: #212121;
      opacity: 1;
    }
  }
}

.dms-container-content {
  .mat-drawer-content {
    overflow: visible;
  }
}

app-task-list-tab {
  mat-table {
    display: table;
    width: 100%;
  }

  .mat-expansion-panel {
    overflow: visible;
  }
}

app-kanban-view {
  height: calc(100vh - 200px);
  display: block;

  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-label {
    transform: translateY(-0.85em) scale(0.75) perspective(100px)
      translateZ(0.00106px) !important;
  }

  .create-task-scroll-box {
    width: 100%;
    // height: calc(100vh - 390px) !important;
    overflow: scroll;
  }

  .create-task-scroll-box {
    .custom--status-menu-btn {
      background: none;
      align-items: center;
      justify-content: left;
      display: flex;
      padding: unset;

      &:hover {
        background: none;
      }
    }

    .mat-slider-horizontal .mat-slider-wrapper {
      left: 0;
      right: 0;
      top: 10px;
      height: 4px;
    }

    .mat-slider-horizontal {
      height: 34px;
    }

    .ng-scrollbar-wrapper {
      .scrollbar-control {
        right: -10px !important;
      }
    }
  }
}

.mat-drawer-inner-container {
  padding-right: 0px;
}

.task-design-card {
  .mat-progress-bar-fill:after {
    background-color: #fab222 !important;
    border-radius: 28px;
  }
}

.task-design-card {
  .mat-progress-bar-buffer {
    background-color: #e0e0e0;
    border-radius: 28px;
  }
}

.task-design-card {
  .mat-progress-bar {
    height: 8px;
    border-radius: 28px;
  }
}

.mat-form-field-underline {
  // border: none;
  // background: transparent !important;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 13px !important;
}

.main-box-start {
  .mat-tab-body-content {
    height: calc(100vh - 197px) !important;
  }
}

.main-box-start {
  .mat-tab-header-pagination-controls-enabled {
    .mat-tab-header-pagination {
      display: none;
    }
  }
}

.main-box-start {
  .mat-tab-label {
    min-width: 73px;
    padding: 0 12px;
  }
}

.main-box-start {
  .mat-tabe-content {
    .mat-tab-header {
      .mat-ink-bar {
        border-radius: 10px 10px 0px 0px;
        height: 2px;
      }
    }
  }
}

.custom_general_task_cls {
  .mat-ink-bar {
    border-radius: 10px 10px 0 0;
    height: 5px;
  }
}

.textarea-emoji-control .ql-stroke {
  stroke: #9e9e9e !important;
  stroke-width: 1 !important;
}

.textarea-emoji-control .ql-fill {
  fill: #9e9e9e !important;
  stroke-width: 1 !important;
}

p {
  margin-bottom: 0px !important;
}

.online-state {
  background: #fab222;
  border: 1px solid #ffffff;
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  bottom: -1px;
  right: 0px;
  position: absolute;
}

.default-active-status {
  background: green;
}

.menudotscone {
  cursor: pointer;
}

.mat-card-actions.chat-msg-send-action-panel .ql-editor p {
  width: 75%;
}

.modal-portion-same .mat-select-value {
  margin-top: 27px;
}

.modal-portion-same .mat-form-field-underline {
  background-color: #eee !important;
}

.modal-portion-same .mat-form-field-wrapper {
  padding-bottom: 16px;
}

.only-chat-portion-to-edit {
  display: initial !important;
}

.create-new-chat-message p {
  margin: 8px 0;
}

.read22 {
  height: auto !important;
  margin-bottom: 0px !important;
}

.border-set-proper {
  border-bottom: 1px solid #d8dcde;
}

.ctm-project-overview-list {
  .search-box-cal-icon {
    margin: 0 !important;

    .mat-form-field .mat-form-field-wrapper {
      padding-bottom: 0;
      border: 1px solid #eeeeee;
      border-radius: 4px;
      margin-top: -1px;
    }
  }

  .mat-tab-body-content {
    .mat-card.mat-focus-indicator.over-view-cal {
      background: #ffffff;
      border: 1px solid #eeeeee;
      border-radius: 16px;
      box-shadow: unset;
    }
  }

  .start-date {
    .mat-form-field-subscript-wrapper {
      margin-top: 0;
    }

    .mat-form-field.custom-input-field {
      .mat-form-field-wrapper {
        padding: 0;

        .mat-form-field-infix {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }

    .mat-form-field-underline {
      // background-color: transparent;
      bottom: 3px;
    }

    .mat-checkbox {
      position: absolute;
      bottom: 5px;

      .mat-checkbox-inner-container {
        width: 13px;
        height: 13px;
        position: relative;
        top: -3px;
      }
    }

    > span {
      height: 32px;
    }
  }

  .updated-date-list-view {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    letter-spacing: 0.005em;
  }

  .right-side-card-view {
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 16px;
    box-shadow: unset;
    height: 133px;
  }

  .pro-details-edit {
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 16px;
    box-shadow: unset;
  }

  .file-folder-view.collapse-folder-table.half-height {
    padding: 0 !important;

    .sidebar-menu-section.common-list-nav-bar {
      li {
        padding-left: 12px !important;
        padding-right: 12px !important;

        a {
          position: relative;
          top: -4px;

          .mat-button-focus-overlay {
            opacity: 0;
          }
        }

        &:hover {
          background-color: #fafafa;
        }
      }
    }
  }

  .file-folder-view.collapse-folder-table.full-height {
    padding: 0 !important;

    .sidebar-menu-section.common-list-nav-bar {
      li {
        padding-left: 12px !important;
        padding-right: 12px !important;

        a {
          position: relative;
          top: -4px;

          .mat-button-focus-overlay {
            opacity: 0;
          }
        }

        &:hover {
          background-color: #fafafa;
        }
      }
    }
  }

  .user-analysis-card-view {
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 16px;
    box-shadow: unset;
    height: 481px;
  }

  .status-analysis-card-view {
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 16px;
    box-shadow: unset;
    height: 319px;
  }
}

.border-radius-card {
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 16px !important;
  box-shadow: unset !important;
}

.custom-input-field.mat-form-field .mat-form-field-infix {
  margin-bottom: 10px;
}

.bottom-side-space {
  margin-bottom: 10px;
}

.common-padding-cls {
  padding: 9px 32px 9px 32px;
}

.font-decrease-size {
  font-size: 10px;
}

.no-data-available {
  font-size: 13px;
}

.handle-to-bottom {
  right: -3px !important;
  top: 21px;
}

.btun-custom-cls-proper-add-use .btn-custom-cls-add {
  background-color: transparent !important;
  box-shadow: none !important;
  -webkit-appearance: none !important;
}

.btun-custom-cls-proper-add-use .btn-custom-cls-add:hover {
  background-color: transparent !important;
  box-shadow: none !important;
}

.custom-discussion-cls .mat-dialog-container {
  box-shadow: none;
  border-radius: 20px;
}

.custom-discussion-cls {
  overflow: revert !important;
  max-height: initial !important;
}

.overflow-auto-cls {
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 13px;
}

.heading-search-input
  .mat-form-field-appearance-legacy
  .mat-form-field-wrapper {
  padding-bottom: 0px !important;
}

.open-preview-cls h4 {
  font-weight: 500;
  font-size: 24px;
  text-align: left;
}

.open-preview-cls .mat-dialog-container {
  box-shadow: none;
}

.image-preview-content .preview-img-open {
  // width: 300px;
  // margin: 0 auto;
  // object-fit: cover;
  // height: 300px;
  // border-radius: 20px;

  max-width: 450px;
  margin: 0 auto;
  object-fit: contain;
  max-height: 450px;
  border-radius: 20px;
}

.open-preview-cls .crossblack-cls-svg {
  width: 30px;
  height: 30px;
}

.arrow-pointer {
  cursor: pointer;
}

.main-box-start .mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0px;
}

.main-box-start .mat-form-field-appearance-legacy .mat-form-field-ripple {
  height: 0px;
}

.main-box-start .mat-form-field-infix {
  position: relative;
  top: -5px;
}

.calendar-design-cls {
  .mat-form-field-underline {
    &:before {
      background-color: transparent;
    }
  }
}

.calendar-design-cls {
  .mat-form-field-type-mat-date-range-input {
    .mat-form-field-infix {
      width: auto;
    }
  }
}

.badge-btn {
  background: #d6e4f8;
  border-radius: 4px;
  padding: 4px 8px 4px 8px;
  color: #2a5fb0;
  font-size: 11px;
  font-weight: 600;
}

.info-box-click {
  border: 1px solid #ffffff;
  border-radius: 16px;
  background: linear-gradient(180deg, #eff4fc 0%, #fef4f0 100%);
  width: fit-content;
  padding: 18px;
  margin-left: auto;
}

.mail-btn-add {
  background: #d6e4f8;
  border-radius: 4px;
  padding: 4px 7px 4px 7px;
  margin-left: 2px;
  color: #2a5fb0;
  font-weight: 600;
}

.info-box-click {
  h6 {
    color: #212121;
    font-weight: 700;
    font-size: 18px;
  }
}

.info-box-click {
  p {
    color: #212121 !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }
}

.info-box-click {
  label {
    color: #616161;
    font-weight: 500;
    font-size: 16px;
  }
}

.info-cls-click {
  cursor: pointer;
}

.other-click-option {
  background: #f5f5f5;
  border-radius: 0px 0 16px 16px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 16px;
  margin-left: auto;
  margin-top: -10px;
  z-index: -1;
  position: relative;
}

.other-click-option {
  .other-option-pvt {
    h5 {
      color: #212121;
      font-weight: 700;
      font-size: 14px;
    }
  }
}

.other-click-option {
  .other-option-pvt {
    h6 {
      color: #616161;
      font-weight: 700;
      font-size: 14px;
    }
  }
}

.other-click-option {
  .other-option-pvt {
    p {
      color: #212121;
      font-weight: 500;
      font-size: 14px;
    }
  }
}

.custom-editor-check {
  margin-left: initial !important;
}

.set-to-position {
  position: absolute;
  left: 50%;
  right: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.set-to-position-set {
  position: absolute;
  left: 50%;
  right: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.custom-quil-editor-cls {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 600px !important;
}

.custom-scroll-add {
  max-height: 215px !important;
  height: 215px !important;
}

.custom-discussion-cls {
  height: 100px !important;
  max-height: 100px !important;
}

.cdk-overlay-connected-position-bounding-box {
  .msgContentBox {
    overflow-y: auto !important;
    height: 270px !important;
    max-height: 270px;
  }
}

.chat-content .quill-editor-controls a {
  margin: 0 4px 0 4px;
}

.send-btn {
  margin-left: 4px;
}

.onlycustom-meber-cls .span {
  margin-left: 13px !important;
}

.onlycustom-meber-cls .user-detail h3 {
  margin-left: 13px !important;
}

.main-box-start {
  .mat-tab-labels {
    justify-content: center;
  }
}

.mat-calendar-body-selected {
  background: #276ef1;
  border: none !important;
  color: #fff !important;
}

.mat-calendar-controls button:hover span {
  color: #616161 !important;
}

.mat-calendar button span.mat-button-wrapper span {
  color: #616161;
  font-size: 14px;
}

.mat-calendar-controls button.mat-focus-indicator {
  color: #616161;
}

.mat-calendar-content thead.mat-calendar-table-header tr th {
  color: #616161;
}

.mat-calendar-content
  tbody.mat-calendar-body
  tr
  td
  .mat-calendar-body-cell-content {
  color: #212121;
}

.mat-datepicker-content .mat-datepicker-actions.ng-star-inserted button {
  background: transparent;
  color: #276ef1;
  font-size: 14px;
  text-transform: uppercase;
}

.mat-datepicker-content .mat-datepicker-actions.ng-star-inserted button:hover {
  color: #276ef1 !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background-color: transparent;
  border: transparent;
}

.modal-portion-same .mat-form-field.custom-input-field {
  display: block;
}

.discussion-radio-status .mat-radio-button .mat-radio-label-content {
  color: #616161;
  font-weight: 500;
  font-size: 12px;
}

.discussion-radio-status span.mat-radio-outer-circle {
  border-color: #616161 !important;
}

.discussion-radio-status
  .mat-radio-button.mat-accent.mat-radio-checked
  .mat-radio-outer-circle {
  border-color: #276ef1 !important;
}

.discussion-radio-status .mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #276ef1 !important;
}

.custom-header-search-area .custom-header-search-box:focus {
  border-color: #ced4da;
  outline: none;
  box-shadow: none;
}

.ql-mention-list-container-top {
  border: 1px solid #eeeeee !important;
  box-shadow: 0 8px 8px #21212114 !important;
  border-radius: 8px !important;
  min-width: 360px !important;
  max-height: 400px !important;
  padding: 15px;
  margin: -28px 0 0 0;
}

.ql-mention-list-container.ql-mention-list-container-top
  .ql-mention-list-item.selected {
  background-color: transparent;
  text-decoration: none;
}

.only-use-this-for-modal {
  width: 34px !important;
  height: 34px !important;
}

.check-list-class {
  margin-left: 410px !important;
}

.quick-comment-class {
  margin-left: 325px !important;
}

// .task-comment-class{
//     margin-left: 625px !important;
// }
.custom-time-sheet-container {
  padding: 65px 0px;
}

.decrease-pd-cls {
  padding-left: 34px;
  padding-right: 34px;
}

.time-sheet-calendar .task-search input.custom-input {
  height: 40px;
  border: 1px solid #eeeeee !important;
  border-radius: 8px;
  background: #fafafa;
  padding: 13px 13px 13px 36px;
}

.time-sheet-calendar .task-search input.custom-input:focus {
  outline: none;
  box-shadow: none;
}

.time-sheet-calendar .task-search {
  max-width: 240px;
}

.set-position-auto {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0px 0 10px;
}

.download-cls-add {
  border: 1px solid #eeeeee;
  border-radius: 8px;
  background: #fafafa;
  padding: 7px 13px 7px 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.timesheet-report .mat-tab-list .mat-tab-label-active {
  padding-left: 0px;
  padding-right: 0px;
  min-width: 110px;
  opacity: 1;
  margin-right: 27px;
}

.timesheet-report .mat-tab-list .mat-tab-label {
  opacity: 1;
  padding-left: 0px;
  padding-right: 0px;
}

.timesheet-report .mat-tab-list .mat-tab-label-active span {
  color: #212121;
  font-weight: 700;
  font-size: 14px;
}

.timesheet-report .mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background: #276ef1;
  border-radius: 4px 4px 0px 0px;
}

.timesheet-report mat-tab-group .mat-tab-label-content span {
  color: #9e9e9e;
  font-size: 14px;
  font-weight: 500;
}

.action--list.time-sheet-report
  button.mat-button
  span.mat-button-focus-overlay {
  background: none;
}

.custom-time-sheet-container .mat-tab-body-content {
  overflow: inherit;
}

.timesheet-manually-cls li.list-inline-item {
  color: #212121;
  font-weight: 700;
  font-size: 18px;
}

.timesheet-manually-cls a.anchor-link {
  font-size: 16px;
  font-weight: 700;
}

.timesheet-manually-cls button.mat-focus-indicator {
  color: #616161 !important;
}

.timesheet-manually-cls button.mat-focus-indicator:hover {
  color: #616161 !important;
}

.timesheet-manually-cls .custom-tab-list {
  border: none !important;
}

.timesheet-manually-cls
  .custom-tab-list
  li.list-inline-item
  button.btn.btn-dark {
  color: #616161;
  font-weight: 500;
  font-size: 12px;
  padding: 3px 16px 3px 16px;
  border: 1px solid #eeeeee !important;
}

.second-unstyled-list button.btn.btn-dark:hover,
.active {
  background: #276ef1 !important;
}

.second-unstyled-list li.list-inline-item li {
  margin-right: 0px;
}

.second-unstyled-list
  li.list-inline-item
  ul.list-unstyled.list-inline.custom-tab-list.tab-sm
  li.list-inline-item
  button {
  border-radius: 0px;
}

.timesheet-manually-cls .second-unstyled-list .same-btn-radius-first {
  border-radius: 4px 0px 0px 4px !important;
}

.timesheet-manually-cls .second-unstyled-list .same-btn-radius-last {
  border-radius: 0px 4px 4px 0px !important;
}

.submit-approval-cls {
  max-width: 450px !important;
  max-height: initial !important;
}

.submit-approval-cls mat-dialog-container {
  padding-top: 22px !important;
}

.submit-approval-cls h2 {
  font-weight: 700 !important;
  font-size: 18px;
}

.total-time-sheet-modal mat-dialog-container {
  padding-top: 10px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.total-time-sheet-modal .both-side-responsive {
  padding-left: 35px;
  padding-right: 35px;
}

.total-time-sheet-modal h2 {
  font-weight: 700 !important;
  font-size: 24px;
}

.submit-approval-body .inner-scrolling-data {
  max-height: 428px;
  overflow-y: auto;
}

.submit-approval-body .inner-scrolling-data:last-child {
  border-bottom: none;
}

.submit-approval-body .inner-scrolling-data .image-container {
  width: 32px;
  height: 32px;
}

.submit-approval-body .inner-scrolling-data .log-body .log-title {
  font-size: 14px;
  margin-bottom: 1px;
}

.submit-approval-body .inner-scrolling-data .log-body .log-date {
  color: #9e9e9e;
  font-weight: 500;
  margin-bottom: 6px !important;
}

.diff-color-cls {
  color: #9e9e9e;
  font-weight: 600;
  font-size: 12px;
}

.submit-approval-body .inner-scrolling-data .log-body .log-comment {
  color: #616161;
  font-weight: 500;
  font-size: 12px;
}

.submit-approval-body .inner-scrolling-data .log-details {
  padding-right: 0px;
  align-items: flex-start;
  border-radius: 0px;
  margin-bottom: 0px;
  border-bottom: 1px solid #e0e0e0;
  padding-left: 0px;
}

.submit-approval-body .inner-scrolling-data .log-status {
  padding: 2px 8px;
  font-weight: 600 !important;
}

.notes-set label {
  color: #616161;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 5px;
}

.notes-set textarea {
  resize: none;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  // color: #bdbdbd !important;
  font-size: 12px;
  font-weight: 600;
}

.notes-set textarea::placeholder {
  // color: #bdbdbd !important;
  font-size: 12px;
  font-weight: 600;
}

.submit-btn .btn--bordered {
  border: 1px solid #eeeeee !important;
  border-radius: 4px;
  padding: 6px 22px !important;
  color: #276ef1 !important;
  font-weight: 500 !important;
}

.submit-btn .btn--primary {
  padding: 9px 22px 9px 22px !important;
  border-radius: 4px !important;
}

.status-name {
  cursor: pointer;
}

.total-time-sheet-modal .table-container.table-responsive {
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 0px;
}

.total-time-sheet-modal mat-dialog-container tr th.name-column:first-child {
  padding-left: 26px;
}

.total-time-sheet-modal .table-container {
  text-align: center;
}

.total-time-sheet-modal .table-container.table-responsive .avatar-column {
  padding-left: 16px;
}

.total-time-sheet-modal .total-container {
  padding-left: 28px;
  padding-right: 24px;
}

.total-time-sheet-modal .table-container.table-responsive td .delete-icon {
  padding-right: 16px;
}

.submit-approval-body h2 {
  font-weight: 500 !important;
}

.submit-approval-body table.table thead tr th:first-child {
  text-align: left;
}

.submit-approval-body
  .table-container.table-responsive
  table.table
  thead
  tr
  th {
  border-bottom: none;
}

.submit-approval-body .table-container.table-responsive tbody tr.date-row td {
  border-bottom: none;
}

.submit-approval-body .table-container.table-responsive tr.date-row td {
  font-weight: 700;
}

.submit-approval-body table.table tbody tr td {
  vertical-align: middle;
}

.total-container p {
  color: #616161;
  font-weight: 500;
}

.total-container p span {
  font-weight: 700;
  margin-left: 5px;
}

.submit-approval-body span.badge {
  padding: 2px 8px 2px 8px;
  font-size: 11px;
  font-weight: 600;
}

.submit-approval-body .common-cls {
  color: #9e9e9e;
  font-weight: 500;
  font-size: 14px;
}

.submit-approval-body .table-container.table-responsive td.total-hours {
  font-size: 14px;
}

.time-sheet-calendar .mat-form-field-wrapper {
  padding: 0;
  margin: 0;
}

.time-sheet-calendar .mat-form-field-infix {
  padding: 0;
  margin: 0;
}

.timesheet-report table,
tr,
td,
th {
  border: none;
}

.hide-separator {
  .mat-date-range-input-separator {
    display: none;
  }
}

.submit-approval-body .table-container.table-responsive .avatar-column p {
  text-align: left;
}

.time-sheet-calendar .mat-form-field-wrapper {
  background: #fafafa;
}

.time-sheet-calendar .mat-form-field-flex {
  padding: 3px !important;
}

.time-sheet-calendar .mat-form-field-prefix {
  top: 1px !important;
}

.time-sheet-calendar .mat-form-field-infix {
  top: -4px;
}

.approve-btnstyle .log-status.accepted-status {
  padding: 4px 8px 4px 8px;
  font-size: 10px;
  font-weight: 700;
  border-radius: 4px;
}

.fixed-header-table thead {
  background: #fafafa;
}

.fixed-header-table tr td {
  text-align: left !important;
}

.fixed-header-table thead tr th {
  text-align: left !important;
}

.status-tag .status-name {
  background: #fef0d3;
  border-radius: 4px;
  padding: 2px 8px 2px 8px;
  font-size: 11px;
  font-weight: 600;
  color: #c88e1b !important;
}

.timesheet-report table thead tr th {
  // color: #9e9e9e;
  // font-weight: 600;
  // font-size: 12px;
}

.timesheet-report table tbody tr .inline-btn {
  color: #212121;
  font-size: 14px;
  font-weight: 500;
}

// .timesheet-report tfoot {
//   background: #eeeeee;
// }

.timesheet-report tfoot th {
  font-weight: 700;
  color: #212121;
  text-align: left !important;
}

.timesheet-report tfoot th:first-child {
  padding-left: 34px;
}

.timesheet-report tfoot th:last-child {
  padding-right: 34px;
}

.timesheet-report tbody .timing-cls {
  color: #616161;
  font-weight: 700;
  font-size: 14px;
}

.timesheet-report tbody .overall-same {
  color: #616161;
  font-weight: 500;
  font-size: 14px;
}

.custom-dropdown-menu {
  padding: 8px 15px 17px 15px;
  background: #ffffff !important;
  box-shadow: 0 0 12px #00000026 !important;
  border-radius: 12px !important;
  min-width: 243px !important;
  overflow: inherit !important;
}

.service-export-custom-dropdown-menu {
  background: #ffffff !important;
  box-shadow: 0 0 12px #00000026 !important;
  border-radius: 12px !important;
  min-width: 200px !important;
  overflow: inherit !important;
}

.custom-dropdown-menu .time-log {
  font-size: 14px;
  font-weight: 700;
  color: #212121;
}

.bg-background-cls {
  padding: 2px 12px 2px 12px;
  background: #f5f5f5;
  margin-top: 7px;
}

.bg-background-cls label {
  color: #9e9e9e;
  font-weight: 500;
  font-size: 14px;
}

.custom-dropdown-menu .time-log {
  font-weight: 700;
  font-size: 14px;
  color: #212121;
  margin-bottom: 8px !important;
}

.custom-dropdown-menu span.time-add-manual {
  color: #616161;
  font-weight: 500;
  font-size: 12px;
}

.border-cls-add {
  border-bottom: 1px solid #eeeeee;
  margin-top: 13px;
}

.timesheet-report .mat-tab-header {
  padding-left: 34px;
  padding-right: 34px;
}

.timesheet-manually-cls {
  padding-left: 34px;
  padding-right: 34px;
}

.timesheet-report .mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: none !important;
}

.timesheet-report .mat-tab-label-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.only-align-cls-add {
  background: transparent !important;
}

.dating-pick {
  background: #f5f5f5;
  padding: 8px 0;
  position: relative;
}

.timesheet-report tbody tr td:first-child {
  padding-left: 34px;
}

.timesheet-report thead th:first-child {
  padding-left: 34px;
}

.timesheet-report thead th:last-child {
  padding-right: 34px;
}

.timesheet-report tbody tr td:last-child {
  padding-right: 34px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.timesheet-report tbody tr td:before {
  content: "";
  border-bottom: 1px solid #ddd;
  position: absolute;
  left: 0;
  right: 0;
  width: 96%;
  margin: 31px auto 0 auto;
}

.timesheet-report tbody tr td:before:last-child {
  content: "";
  border-bottom: none;
}

.export-time-sheet-text .page-title {
  font-size: 20px;
  font-weight: 500;
}

.excel-pdf-btn {
  margin-top: 13px;
}

.excel-pdf-btn button {
  padding: 11px 40px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
}

.export-time-sheet-text {
  button {
    position: relative;
    top: -3px;
    left: 9px;

    &.modal-btn-close:hover {
      color: #212121;
    }
  }

  .mat-button-focus-overlay {
    display: none;
  }

  span.mat-ripple.mat-button-ripple.mat-button-ripple-round {
    display: none;
  }
}

.export-time-sheet-text {
  margin: -12px 0 0 0;
}

.bg-cls-add-time {
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timesheet-report {
  height: calc(100vh - 260px);
}

.position-set-empty {
  text-align: center;
}

.bg-cls-add-time p {
  color: #212121;
  font-size: 18px;
  font-weight: 700;
}

.bg-cls-add-time small {
  color: #616161;
  font-size: 14px;
  font-weight: 500;
}

.timesheet-report table tbody tr td {
  cursor: pointer;
}

.time-log-add {
  height: calc(100vh - 291px) !important;
}

.timesheet-report.time-log-add .bg-cls-add-time .center-button button {
  border: 1px solid #276ef1 !important;
}

// .new-postion-set-cls {
//   position: absolute !important;
//   bottom: 2% !important;
// }

.allselected-cls {
  padding-left: 11px;
  padding-top: 13px;
}

.allselected-cls span.mat-checkbox-label {
  font-size: 16px !important;
  font-weight: 500;
  text-transform: capitalize;
}

.estimated-hrs-design-cls {
  background-color: #fff9ee;
  padding: 3px 8px 3px 19px;
}

.estimated-hrs-design-cls li {
  color: #9e9e9e;
  font-weight: 500;
  font-size: 12px;
}

.estimated-hrs-design-cls li strong {
  color: #212121;
  font-weight: 700;
  font-size: 12px;
}

.first-li-border-cls {
  border-right: 1px solid #eeeeee;
  padding-right: 17px;
}

.position-inherit-cls {
  top: initial !important;
}

.mail-btn {
  padding: 0;
  margin: 0;
}

.new-common-notification-cls-add .main-custom-notification-globally-cls {
  max-height: 425px;
  height: 425px;
  overflow-y: auto;
}

.new-common-notification-cls-add {
  max-width: 350px !important;
  width: 350px;
}

.new-common-notification-cls-add .notification-title {
  padding: 4px 16px 10px 20px !important;
}

.new-common-notification-cls-add {
  padding: 0;
}

.completed-status {
  padding: 4px 8px 4px 8px;
  font-weight: 700;
  font-size: 10px;
  border-radius: 4px;
}

.new-tooltip-cls-add .tooltip {
  position: absolute !important;
  margin: -8px 0 0 7px;
}

.new-tooltip-cls-add .user--img {
  height: 34px;
  width: 34px;
  min-height: 34px;
  min-width: 34px;
}

.new-tooltip-cls-add .tooltip-inner {
  padding: 12px 9px 14px 12px;
}

.new-tooltip-cls-add .tooltip .tooltip-arrow::before {
  margin: 64px 0px 0 24px;
}

.new-tooltip-cls-add .online--status {
  bottom: -1px !important;
  right: 3px !important;
}

.main-custom-notes-module {
  max-width: 44.5vw !important;
}

.main-custom-notes-module .ql-editor {
  overflow-y: scroll;
  max-height: 320px;
  height: 320px;
}

.main-custom-notes-module .ql-editor::-webkit-scrollbar {
  width: 0px !important;
}

.pin-icon {
  background: #dc3535;
  border-radius: 8px;
  padding: 3px 6px 4px 6px;
}

.file-folder-view .card img {
  border-radius: 7px 7px 0px 0px !important;
}

.file-folder-view .card img:hover {
  border-radius: 7px 7px 0px 0px !important;
}

.custom-preview-file iframe {
  height: calc(100vh - 60px) !important;
}

.custom-preview-file .mat-focus-indicator {
  position: absolute;
  top: 0;
  margin: 50px 65px 0 0px;
}

.only-breadcum-item-cls .breadcrumbs-link {
  color: #9e9e9e !important;
}

.list-view-table .progress-bar {
  position: inherit !important;
}

li.breadcrumbs-item:last-child a {
  color: #3477dc !important;
}

.last-img-cls-margin-decrease {
  margin-left: 0px !important;
}

.new-client-info-cls-add .other-detail-value-block {
  word-wrap: break-word;
  white-space: break-spaces;
  width: 220px !important;
}

.new-client-info-cls-add .other-detail-block {
  max-height: 375px;
  overflow-y: auto;
}

// .mega-chart-menusscroll {
//   overflow-y: auto;
//   max-height: 275px;
//   width: 18%;
// }

.mega-chart-menusscroll li {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mega-chart-menusscroll .project-gragh-cart {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 10px;
  column-gap: 35px;
}

.notes-modal .in-oneline-cls-add {
  display: flex !important;
}

.scroll-hide-cls::-webkit-scrollbar {
  width: 0px;
}

.submit-approval-body .cmn-txt {
  text-align: left;
  white-space: break-spaces;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.submit-approval-body .sub-block-title-sm {
  white-space: break-spaces;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.submit-approval-body .table-responsive thead th:first-child {
  width: 175px;
}

.submit-approval-body .table-responsive tr td:first-child {
  width: 175px;
}

// .only-support-this-calendar-menu {
//   min-width: 576px !important;
//   // height: 390px;
// }

.add-task-rght-block-tab .crossicon {
  position: absolute;
  right: -7px;
  margin: 40px 0px 0 0px;
}

.chat-room-modal-cls img.crossicon {
  position: absolute;
  right: 0;
  margin: 42px 0 0 0;
}

// .chat-room-modal-cls .user-chat-search-header.custom-header-search-area {
//     position: absolute;
//     width: 90%;
// }
.chatmenuclsopenwidth .crossicon {
  position: absolute;
  right: -8px;
  margin: 42px 6px 0 0px;
}

.add-task-rght-block-tab .mat-tab-body {
  height: calc(100vh - 216px);
}

// .add-task-rght-block-tab .task-comment .userMsgBg .right-msg{
//   margin-left: auto;
// }

.dms-content-block.mub-dms-content-block .right-msg .userMsgBg {
  margin-left: auto;
}

.add-task-rght-block-tab .search-box-icone {
  margin-left: auto;
}

.add-task-rght-block-tab .dms-content-block {
  height: calc(100vh - 210px) !important;
}

.chatmenuclsopenwidth .userMsgBg .right-msg {
  margin-left: auto;
}

.chat-room-modal-cls .msgContentBox {
  max-height: 460px !important;
}

// .add-task-rght-block-tab .msgContentBox {
//   height: calc(100vh - 275px) !important;
// }

.chatmenuclsopenwidth .msgContentBox {
  max-height: 350px !important;
}

.chatmenuclsopenwidth .dms-content-block {
  height: 360px !important;
}

.discussion-module-cls .dms-content-block {
  height: 100% !important;
}

.only-support-this-calendar-menu {
  min-width: 576px !important;
  // height: 390px;
}

.w-80p {
  width: 80%;
}

.w-160p {
  width: 160px;
}

// .custom-tab.add-task-rght-block-tab
//   .user-chat-search-header.custom-header-search-area {
//   position: absolute;
//   width: 96%;
//   left: 0;
//   right: 0;
//   margin: 0 0px 0 29px;
// }

// .new-custom-menu-checklist-cls-only .common-quill-editor.checklist-common-quill .chat-content {
//   width: 86% !important;
// }
.new-custom-menu-checklist-cls-only mat-card-actions.mat-card-actions {
  position: initial !important;
}

.add-task-rght-block-tab .quill-editor-controls {
  margin: 0 20px 0 0;
}

.trim-info {
  max-width: 150px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 25px;
  position: relative;

  &.mx-w-480 {
    max-width: 480px;
  }
}

.empty-cover {
  // position: relative;
  // min-height: 733px;
  // background: #fff;
  // border-radius: 8px;
  // border: 1px solid #eee;
  // margin: 0 30px;
  height: calc(100vh - 14em);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  margin: 0 30px;
  border-radius: 8px;
  border: 1px solid #eee;
  text-align: center;

  .empty-inner {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // text-align: center;
  }

  // @media screen and (min-width: 2400px) {
  //   min-height: calc(100vh - 340px);
  // }
  // @media screen and (min-width: 1921px) {
  //   min-height: calc(100vh - 240px);
  // }
  // @media screen and (max-width: 1910px) {
  //   min-height: 623px;
  // }
  // @media screen and (max-width: 1500px) {
  //   min-height: 643px;
  // }
  // &.custom-height-table {
  //   min-height: 363px !important;
  //   @media screen and (min-width: 1921px) {
  //     min-height: calc(100vh - 870px) !important;
  //   }
  // }
  &.nomore-margin-small {
    margin: 0 0px 15px 0px;
  }

  &.status-report-empty-small-card {
    height: calc(100vh - 29em);
    margin-top: 15px;
    border: 0px;
  }
}

.pt-top-40 {
  padding-top: 40px;
}

.bg-only-for-report {
  background-color: #fafafa !important;
  height: -webkit-fill-available;
}

// App User Report Styles
.app-user-report-project-report,
.app-user-performance-report,
.app-user-status-report,
.app-user-activity-report {
  .bg-only-for-report {
    background-color: transparent !important;
    padding-top: 20px;

    .empty-cover {
      margin: 0;
    }
  }
}

// App User Report Styles End
.empty-report {
  color: #9e9e9e;
  text-align: center;
}

.tab-name {
  color: black;
}

.default-folder {
  text-transform: capitalize;
  font-weight: bold !important;
}

.user-profile-font {
  text-transform: capitalize;
  padding-left: 0px;
  font-size: 15px !important;
}

.buildVersion {
  margin-bottom: 10px;
  bottom: 0;
  position: absolute;
  width: 100%;
}

.custom-type-oflist-using-adding-cls-only {
  overflow: revert !important;
}

.custom-type-oflist-using-adding-cls-only .list-unstyled {
  max-height: 300px;
  overflow-y: auto;
}
.custom-edit-teamGroup-2 span.mat-select-min-line {
  display: none;
}
.custom-edit-teamGroup-2 .mat-chip-list-wrapper {
  position: relative;
  top: 22px;
  z-index: 12;
  max-width: 94%;
}

.as__custom-row {
  &.mat-menu-panel {
    max-width: 29vw !important;
    width: 100% !important;
    min-width: 28vw;
    margin-top: 15px;
    max-height: 280px;
    overflow-y: scroll;
  }
}
.as__add-service-modal {
  .cdk-overlay-pane {
    width: 100% !important;
  }
}

.apply_leave {
  .mat-form-field {
    width: 100%;
  }
  .mat-form-field-infix {
    border: 0.5px solid #e0e0e0;
    display: flex;
    padding: 5px 10px;
    justify-content: space-around;
    border-radius: 6px;
  }
  .mat-form-field-underline {
    display: none;
  }
  .mat-form-field-appearance-legacy .mat-form-field-label,
  label#mat-form-field-label-5,
  label#mat-form-field-label-7 {
    position: relative;
    top: -7px !important;
    left: 6px !important;
    font-size: 12px;
    background: #fff;
    width: fit-content !important;
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float
    .mat-input-server:focus
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    transform: none !important;
  }
}

.apply_leave .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0px !important;
}

.disable-screen-loader{
  pointer-events: none !important;
}