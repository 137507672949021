.m {
  &-b {
    &-32 {
      margin-bottom: 32px;
    }
  }
}

.bg-light-grey {
  background: #fafafa;
}

.bg-dark-grey {
  background: #f5f5f5;
  border-radius: 4px;
}

.bg-dark-grey input.custom-input {
  color: #bdbdbd !important;
  font-weight: 500 !important;
  font-size: 14px;
  height: 48px;
}

.bg-dark-grey input.custom-input::placeholder {
  color: #bdbdbd !important;
}

.divider-hr {
  border-top: 1px solid #eeeeee;
  opacity: 1;
}

.cdk-overlay-dark-backdrop {
  background: rgba(33, 33, 33, 0.5);
  backdrop-filter: blur(6px);
}

.main-content-coantainer {
  padding: 75px 20px;

  &.container-one{
    padding: 75px 20px 0 20px;
  }
}

// custom Tabs
.custom-tab.mat-tab-group.mat-primary {
  .mat-ink-bar {
    height: 1px;
    border-radius: 4px 4px 0px 0px;
    background-color: #276ef1;
  }
  .mat-tab-label {
    min-width: auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #9e9e9e;
    padding: 0 20px;
    opacity: 1;
    &.mat-tab-label-active {
      color: #212121;
      font-weight: 700;
    }
  }
  .mat-tab-header {
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 16px;
  }
  .mat-tab-body-content {
    overflow: visible;
  }
  &.n-m-h {
    .mat-tab-body,
    .mat-tab-body-wrapper {
      min-height: auto;
      width: 100%;
    }
  }
  &.n-m-b-h-tab {
    .mat-tab-header {
      margin-bottom: 0;
      & + .mat-tab-body-wrapper > .mat-tab-body {
        overflow: visible;
      }
    }
  }
  &.task--tab {
    .mat-tab-label {
      padding: 0 10px;
    }
  }
  &.add-task-rght-block-tab {
    height: 100%;
    .mat-tab-header {
      height: 4em;
      padding: 16px 24px 0;
      border-bottom: 1px solid #e0e0e0;
      margin-bottom: 0;
      .mat-tab-label-container {
        align-items: flex-end;
        .mat-tab-labels {
          display: flex;
          justify-content: space-around;
          .mat-tab-label {
            padding: 0 5px;
          }
        }
      }
    }
    .mat-tab-body-wrapper {
      flex: 1 1 auto;
    }
  }
}

.avatar_text {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 2px;
}

.hide-tab mat-tab-header.mat-tab-header {
  display: none;
}

// avatar
img.img-avatar {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  object-fit: cover;
}

.avatar {
  background-color: #dc3535;
  justify-content: center;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: #ffffff;
  text-align: center;
  min-width: 3.5em;
  height: 3.5em;
  border-radius: 15px;
  font-size: 14px;
  text-transform: uppercase;
  &--lg {
    height: 80px;
    width: 80px;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.0025em;
    color: #ffffff;
  }
}

// dropdown
.dropdown-block {
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 12px 12px rgb(33 33 33 / 8%);
  border-radius: 8px;
  min-width: 216px;
}

.custom-dropdown-menu {
  &.mat-menu-panel {
    @extend .dropdown-block;
  }
  .mat-menu-item {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #616161;
    padding: 9px 24px;
    height: auto;
    display: flex;
    align-items: center;
    &:hover {
      color: #212121 !important;
    }
  }
  &.filter {
    min-width: 360px;
    .mat-menu-content {
      padding: 16px 24px;
      input {
        border-radius: 8px;
        border: 0;
        height: 48px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.005em;
        color: #bdbdbd;
        background: #f5f5f5 url(../icons/searchicon.svg) no-repeat;
        background-position: left 15px center;
        padding-left: 45px;
      }
      ul {
        margin-top: 17px;
        > li {
          margin-bottom: 18px;
          &:last-child {
            margin-bottom: 0;
          }
          .mat-checkbox-label {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.005em;
            color: #616161;
          }
        }
      }
    }
  }
}

.custom-search-feild {
  background: #ffffff url(../icons/searchicon.svg) no-repeat;
  background-position: left 15px center;
  padding-left: 45px;
}

:root .custom-select-menu.mat-select-panel {
  @extend .dropdown-block;
  .mat-option {
    .mat-option-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.005em;
      color: #616161;
    }
  }
}

.btn.float-btn {
  position: absolute;
  top: 80px;
  right: 20px;
}

.user-profile-pic-section {
  input.uploadFile {
    opacity: 0;
    width: 120px;
    height: 120px;
    position: absolute;
  }
  p {
    font-size: 12px;
    font-weight: 500;
    color: #bdbdbd;
  }
  mat-form-field {
    width: 120px;
    height: 120px !important;
    background: #eeeeee;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 24px;
    &.round {
      border-radius: 50%;
    }
  }
  .mat-form-field-infix {
    padding: 0 !important;
  }
  &.rounded {
    mat-form-field {
      border-radius: 50%;
    }
  }
}

.no-underline .mat-form-field-underline {
  height: 1px;
  display: none;
}

.mat-drawer-container {
  min-height: 100%;
}

// no items
.no-items-block {
  max-width: 360px;
  text-align: center;
  h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.0025em;
    color: #212121;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #616161;
  }
}

.v-middle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  right: 0px;
}

.f-w {
  &-312 {
    max-width: 312px;
  }
  &-50 {
    width: 50px;
  }
  &-100 {
    max-width: 100%;
  }
  &-115 {
    width: 115px;
  }
  &-200 {
    width: 200px;
  }
  &-33 {
    width: 33px;
  }
}

.m-w {
  &-100 {
    max-width: 100%;
  }
  &-216 {
    max-width: 216px;
  }
}

.s-icon {
  width: 8px;
  height: 8px;
  background: #757575;
  border-radius: 2px;
  display: inline-block;
}

.badge {
  background: #eeeeee;
  border-radius: 4px;
  padding: 2px 5px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  letter-spacing: .005em;
  color: #757575;
  &.badge-md {
    padding: 5px;
  }
  &.l-b-badge {
    background: #edf3fd;
    border-radius: 4px;
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #1d53b7;
    display: flex;
    align-items: center;
  }
  &--primary {
    background: #edf3fd;
    color: var(--primary-color);
  }
  &--success {
    background: #16b975;
    color: #fff;
  }
  &.caret {
    position: relative;
    padding-right: 20px;
    &:after {
      content: "";
      border: 4px solid transparent;
      border-top-color: #00000082;
      position: absolute;
      right: 7px;
      top: 9px;
    }
  }
  &.status-completed {
    background: #d0f1e3;
    color: #12945e;
  }
  &.status-failed {
    background: #f8d7d7;
    color: #dc3535;
  }
  &.default {
    background: #eeeeee;
    color: #757575;
  }
  &.warning {
    background: #fef0d3;
    color: #c88e1b;
  }
  &.info {
    background: #d1eff1;
    color: #148e96;
  }
  &.violet {
    background: #e2def9;
    color: #594ab6;
  }
  &.danger {
    background: #f8d7d7;
    color: #dc3535;
  }
  &.success {
    background: #d0f1e3;
    color: #12945e;
  }
}

.n-s-s {
  .slick-list {
    padding: 0 100px 0 0 !important;
    margin: 0 -10px;
    .slick-slide {
      margin: 0 10px;
    }
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 131px;
      height: 247px;
      background: linear-gradient(
          90deg,
          #ffffff 59.37%,
          rgba(255, 255, 255, 0) 100%
        )
        no-repeat;
      transform: rotateY(180deg);
      background-position: -75px;
    }
    .slick-track {
      margin-left: unset;
    }
  }
  .slick-prev,
  .slick-next {
    z-index: 10;
    height: 30px;
    width: 30px;
    &::before {
      content: "";
      background: #fff url("../icons/nextslideicon.svg") no-repeat;
      opacity: 1;
      height: 30px;
      width: 30px;
      display: inline-block;
      border-radius: 50%;
      background-position: center;
      border: 1px solid #eeeeee;
    }
    &:not(.slick-next) {
      left: 0;
      transform: translate(0, -50%) rotate(180deg);
    }
    &:not(.slick-prev) {
      right: 0;
    }
    &.slick-disabled {
      display: none;
    }
  }
}

// toggle
:root .mat-slide-toggle.mat-checked {
  .mat-slide-toggle-thumb {
    background-color: var(--primary-color);
  }
  .mat-slide-toggle-bar {
    background-color: #d4e2fc;
  }
}

.c-details {
  h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.0025em;
    color: #212121;
    margin-bottom: 4px;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #9e9e9e;
    margin-bottom: 0;
  }
}

.border-bottom {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
}

:root .mat-tab-body.mat-tab-body-active {
  overflow: visible;
}

:root .mat-menu-panel {
  min-height: auto;
}

.mat-multi-select {
  .mat-select-trigger {
    height: auto;
  }
}

.custom-multi-select-with-chips {
  &.mat-select-panel {
    min-width: 100% !important;
    background: #ffffff;
    border: 1px solid #eeeeee;
    box-shadow: 0px 8px 8px rgb(33 33 33 / 8%);
    border-radius: 8px;
    .mat-option-text {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.005em;
      color: #616161;
      > img {
        height: 20px;
        width: 20px;
        border-radius: 50%;
      }
    }
    .user-short-name {
      height: 24px;
      width: 24px;
      min-height: 24px;
      min-width: 24px;
      font-size: 10px;
    }
    .mat-pseudo-checkbox-checked {
      & + .mat-option-text {
        color: #212121;
        font-weight: 700;
      }
    }
    .mat-option {
      background: #fff !important;
    }
    .mat-chip-list-wrapper {
      padding: 10px 0;
      &:empty {
        padding: 0;
      }
    }
  }
  .c-s-input {
    border-radius: 0;
    height: 48px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    &:focus {
      background-color: transparent;
      box-shadow: none;
    }
    &::placeholder {
      color: #bdbdbd;
    }
  }
  .cdk-overlay-pane {
    transform: translateX(0);
  }
}

.scrollbar-control {
  opacity: 0;
  transition: opacity 120ms ease-out;
}

ng-scrollbar:hover .scrollbar-control {
  opacity: 1;
  transition: opacity 340ms ease-out;
}

.d-divider {
  border-bottom: 1px solid #eeeeee;
}

.custom-multiselect__dropdpwn {
  .multiselect-dropdown {
    outline: 0;
    input[type="checkbox"] {
      & + div:before {
        border-color: #bdbdbd !important;
        border-radius: 2px;
      }
      &:checked {
        & + div:before {
          border-color: #0c78ff !important;
          border-radius: 2px;
          background: #0c78ff !important;
        }
      }
    }
  }
  .dropdown-btn {
    border: 0 !important;
    border-bottom: 1px solid #eeeeee !important;
    padding: 10px 0 !important;
    border-radius: 0 !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #9e9e9e;
    min-height: 48px;
    outline: 0;
  }
  div.dropdown-list {
    border: 1px solid #eeeeee;
    box-shadow: 0 12px 12px #21212114;
    border-radius: 8px;
    position: relative;
  }
}

.user-short-name {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  background: #f2793d;
  border: 1px solid #ffffff;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: #ffffff;
  height: 34px;
  width: 34px;
  min-height: 34px;
  min-width: 34px;
  border-radius: 50%;

  &.border-radius-30 {
    border-radius: 30%;
  }
}

.mat-expansion-panel-body {
  overflow: visible;
}

.vertical-tab-group.mat-tab-group {
  flex-direction: row;
  .mat-tab-header {
    width: 300px;
    .mat-tab-labels {
      width: 100%;
      flex-direction: column;
      .mat-tab-label {
        height: auto;
        border-bottom: 1px solid #eeeeee;
        padding: 0;
        span {
          padding: 16px;
        }
      }
      .mat-tab-label-content {
        justify-content: flex-start;
        width: 100%;
        text-align: left;
      }
    }
    border-bottom: 0 !important;
    background: #fafafa;
  }
  .mat-tab-label-container {
    min-height: calc(100vh - 250px);
  }
  .mat-tab-body-active {
    padding: 0 0 24px 24px;
  }
  .mat-tab-body-wrapper {
    flex: 1 1 auto;
  }
  .mat-ink-bar {
    display: none;
  }
}

:root {
  .cdk-overlay-pane.custom-overlay-panel {
    transform: translateY(30px) !important;
    min-width: 360px !important;
  }
  .custom-multi-select-with-chips {
    .mat-pseudo-checkbox {
      border: 0;
      &.mat-pseudo-checkbox-checked {
        background-color: transparent;
        &::after {
          border-color: #fff !important;
          border-left-color: #424242 !important;
          border-bottom-color: #424242 !important;
          transform: rotate(-45deg) !important;
          height: 8px !important;
          width: 12px !important;
        }
      }
      &::after {
        border: 2px solid #9e9e9e;
        height: 16px;
        width: 16px;
        transform: rotate(0);
        top: 3px;
        left: 0;
        border-radius: 2px;
        box-sizing: border-box;
        opacity: 1;
      }
    }
  }
  .custom-checkbox__only-border {
    .mat-checkbox-inner-container {
      .mat-checkbox-background,
      .mat-checkbox-frame {
        display: none;
      }
      border: 2px solid #9e9e9e;
      height: 14.5px;
      width: 14.5px;
      transform: rotate(0);
      top: 0;
      left: 0;
      border-radius: 2px;
      box-sizing: border-box;
      opacity: 1;
    }
    &.mat-checkbox-checked {
      .mat-checkbox-inner-container {
        border-color: #fff !important;
        border-left-color: #000 !important;
        border-bottom-color: #000 !important;
        transform: rotate(-45deg) !important;
        height: 8px !important;
        width: 12px !important;
      }
      .user-list {
        display: flex;
        align-items: center;
        > li {
          margin-left: -4px;
          > img {
            height: 24px;
            width: 24px;
            border-radius: 50%;
            object-fit: cover;
            border: 2px solid #fff;
          }
          .more__txt {
            height: 24px;
            width: 24px;
            border-radius: 50%;
            background: #fff;
            border: 2px solid #fff;
            display: inline-block;
            font-weight: 700;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: 0.005em;
            color: #212121;
          }
        }
      }
    }
  }
  .mat-select-multiple {
    .mat-select-trigger {
      height: auto;
    }
  }
}

.px-36 {
  padding-left: 36px;
  padding-right: 36px;
}

.mat-checkbox-label {
  display: flex;
  align-items: center;
}

.capitalize {
  .mat-option-text {
    text-transform: capitalize;
  }
}

.custom-input-select-field {
  .mat-form-field-infix {
    display: flex;
    .mat-select {
      max-width: 100px;
      margin-right: 10px;
    }
  }
}

.container-fixed-width-900 {
  max-width: 900px !important;
}

.custom-dropdown-menu.custom-dropdown-menu-ctm {
  max-width: 300px;
  background: #ffffff;
  box-shadow: 5px 5px 24px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
}

.container-fixed-width-744 {
  max-width: 1000px !important;
}

.timesheet-ctm-com .organization-day .mat-checkbox-background {
  background-color: transparent !important;
  opacity: 0 !important;
}
.timesheet-ctm-com
  .organization-day
  .c-checkbox-button.mat-checkbox-checked
  .mat-checkbox-layout
  .mat-checkbox-label {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.005em;
  color: #276ef1 !important;
}
.timesheet-ctm-com
  .organization-day
  .c-checkbox-button.mat-checkbox-checked
  .mat-checkbox-layout {
  padding-left: 15px;
  border-width: 1px;
  background-color: #ffffff !important;
}
.timesheet-ctm-com .organization-day .c-checkbox-button .mat-checkbox-layout {
  padding: 6px 16px 5px;
  background-color: #f5f5f5 !important;
}

.timesheet-ctm-com .organization-day .table.custom-table.header-bg th {
  background: #fafafa !important;
}

.timesheet-ctm-com
  .organization-day
  .mat-form-field-appearance-outline
  .mat-form-field-wrapper {
  margin: 0.25em 3px;
}
.timesheet-ctm-com
  .organization-day
  .mat-form-field-appearance-outline
  .mat-form-field-flex {
  padding: 0 4px !important;
  margin-top: -0.25em;
  position: relative;
  cursor: pointer;
}
.timesheet-ctm-com .organization-day .mat-form-field-infix .mat-input-element {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.005em;
  color: #616161;
  text-align: center;
}
.timesheet-ctm-com
  .organization-day
  .mat-form-field-appearance-outline
  .mat-form-field-infix {
  padding: 7px 0px 13px;
}
.timesheet-ctm-com .organization-day .table.custom-table tbody td {
  border-bottom-width: 0px;
}
.timesheet-ctm-com .organization-day .break-down-border {
  border-top: 1px solid #eeeeee;
}
.mat-footer-weekly {
  color: #616161 !important;
  font-weight: 500;
  font-size: 14px;
}
.total-week-hourse {
  margin: 0 !important;
  h5 {
    font-weight: 600;
    font-size: 16px;
  }
  button {
    height: 32px;
    border-radius: 6px;
    line-height: 14px !important;
    span {
      line-height: 14px;
    }
  }
}

.timesheet-ctm-com .organization-day .table.custom-table.timesheet-table td {
  color: #616161 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px !important;
}
.timesheet-ctm-com .organization-day .table.custom-table .tatal-time-bold {
  font-weight: 700 !important;
  font-size: 14px !important;
}

.timesheet-month-calendar {
  padding: 0px 0px !important;
  .mat-checkbox-background {
    background-color: transparent !important;
    opacity: 0 !important;
  }
  .c-checkbox-button.mat-checkbox-checked
    .mat-checkbox-layout
    .mat-checkbox-label {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.005em;
    color: #276ef1 !important;
  }
  .c-checkbox-button.mat-checkbox-checked .mat-checkbox-layout {
    padding-left: 8px;
    border-width: 1px;
    background-color: transparent !important;
  }
  .c-checkbox-button .mat-checkbox-layout {
    padding: 6px 8px 5px;
    background-color: transparent !important;
    border: none;
  }

  .table.custom-table.header-bg th {
    background: #fafafa !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0.25em 3px;
  }
  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 4px !important;
    margin-top: -0.25em;
    position: relative;
    cursor: pointer;
  }
  .calendar-month-bg {
    background: #f5f5f5;
    border-radius: 12px 12px 0px 0px;
    height: 52px;
    margin: 0 auto;
    padding: 10px 20px;
  }
  .calenader-list {
    padding: 10px 24px 24px;
    .fc-toolbar-title {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.0025em;
      color: #212121;
    }
    .fc .fc-button-primary {
      background-color: transparent !important;
      border-color: transparent !important;
      color: #616161 !important;
      &:focus-visible {
        box-shadow: none !important;
        outline: none !important;
      }
      &:focus {
        box-shadow: none !important;
        outline: none !important;
      }
    }
    .fc-next-button {
      padding: 0;
    }

    .fc-prev-button:focus-visible {
      box-shadow: none !important;
      outline: none !important;
    }
    .fc-button-group:focus-visible {
      box-shadow: none !important;
      outline: none !important;
    }
    .fc .fc-toolbar.fc-header-toolbar {
      margin-bottom: 12px;
    }
    .fc .fc-col-header-cell-cushion {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.005em;
      color: #616161;
    }
    .fc-scrollgrid-sync-inner {
      height: 35px;
      padding-top: 5px;
      a {
        text-decoration: none;
      }
    }
    .fc .fc-daygrid-day-top {
      flex-direction: column;
      text-align: center;
    }
    .fc-daygrid-day-top a {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.005em;
      color: #9e9e9e;
    }
    .fc-col-header {
      background: #fafafa;
    }
    .fc-daygrid-event-harness {
      width: fit-content;
      .mat-menu-trigger {
        padding: 4px;
        cursor: pointer;
      }
    }
  }
  table thead tr th {
    border: 0 !important;
    &:last-child {
      border-right: 1px solid #ddd !important;
    }
  }
  table thead tr {
    border-bottom: 1px solid #eeeeee !important;
  }
  .c-checkbox-button .mat-checkbox-layout .mat-checkbox-label {
    font-weight: 500;
    font-size: 14px;
  }
}

.custom-dropdown-cal-menu {
  .mat-menu-content {
    padding: 8px;
    h2 {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.005em;
      color: #212121;
    }
    button {
      background-color: transparent;
      border: none;
      padding: 0;
      position: relative;
      top: -9px;
      font-size: 19px;
      &:hover {
        color: #616161 !important;
      }
    }
    .label-wrap {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      letter-spacing: 0.005em;
      color: #616161;
      margin-bottom: 5px;
    }
    .ctm-date-field {
      input {
        border: 1px solid #eeeeee;
        border-radius: 4px;
        height: 25px;
        padding: 7px 5px;
        width: 96px;
        font-family: "Manrope";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.005em;
        color: #616161;
      }
    }
  }
}
.ctm-end-mar {
  margin-left: 5px;
}

.card-process-actual {
  .badge-radio .mat-radio-container .mat-radio-outer-circle {
    height: 15px;
    width: 15px;
    border-color: #bdbdbd;
  }
  .badge-radio .mat-radio-container .mat-radio-inner-circle {
    height: 12px;
    width: 12px;
    top: 1.5px;
    left: 1.5px;
  }
  .block-title {
    margin-bottom: 9px;
  }
  .badge-radio {
    margin-right: 11px;
    padding: 5px 10px 7px;
  }
  .freeze-day-input {
    height: 40px;
  }
}

.user-confirmation-card {
  padding: 15px 0 15px !important;
  .c-s-input {
    padding-left: 16px;
    background-position: right 10px center;
  }
  .mat-card-header {
    padding: 0px 24px !important;
  }
  .table.custom-table tbody tr td {
    padding: 11px 0px;
    &:first-child {
      width: 24px;
      max-width: 24px;
      padding: 0 !important;
      border-bottom: 0;
      border-right: 0;
    }
    &:last-child {
      width: 24px;
      max-width: 24px;
      padding: 0 !important;
      border-bottom: 0;
      border-right: 0;
    }
  }
  .table.custom-table thead tr th {
    padding: 11px 0px;
    color: #9e9e9e;
  }
}

.math_block-user-profile-task-filter {
  .custom-task-filter {
    .image-text {
      background-color: greenyellow !important;
    }
  }
}

span.image-text {
  img {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.005em;
    color: #fff;
    height: 27px;
    width: 27px;
    display: inline-block;
    text-align: center;
    line-height: 27px;
    border-radius: 50%;
  }

  .user-short-name-other {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.005em;
    color: #fff;
    height: 27px;
    width: 27px;
    display: inline-block;
    text-align: center;
    line-height: 27px;
    border-radius: 50%;
    margin-right: 3px;
  }
}
.custom-task-filter {
  .select-all {
    display: flex;
    width: 100%;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 123;
  }
}

// User Selection Notifications Css

.custom-modal {
  .mub-user-selection-notifify {
    &.text-notify {
      .page-title {
        font-weight: 700;
        padding-bottom: 20px;
      }
    }
    .checklist-permission-table th {
      border-bottom: 1px solid #eee;
    }
    .checklist-permission-table td {
      padding: 7px 0;
    }
    img.img-avatar {
      padding: 0 !important;
      width: 33px !important;
      height: 33px !important;
    }
  }
}
:root .custom-multi-select-with-chips .mat-option.filter-option .mat-pseudo-checkbox.mat-pseudo-checkbox-checked:after {
  border-color: transparent !important;
  border-left-color: #fff!important;
  border-bottom-color: #fff!important;
  transform: rotate(-45deg)!important;
  height: 8px!important;
  width: 12px!important;
  left: 2px;
  top: 2px;
}