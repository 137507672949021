.leave-grp .mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #276EF1;
}

.leave-grp .mat-tab-body-content {
  height: 100%;
  overflow: unset;
}

.mat-tab-label .mat-tab-label-content {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  white-space: nowrap;
}


.add-leave-type .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 35px;
  font-size: 12px;
  font-weight: 500;
}


.add-leave-type .mat-button-toggle-checked {
  background-color: #276EF1 !important;
  color: #ffff !important;
}


.add-leave-type .mat-button-toggle-appearance-standard {
  color: #000000de;
  background: #ffff;
}

.month-date-cls {
  mat-select {
    display: inline-block;
    width: 75%;
    outline: none;
  }
}

.month-date-cls .mat-select-placeholder {
  color: #0000006b !important;
  font-weight: 500 !important;
}


.leave-list .mat-button-toggle-checked {
  background-color: #EDF3FD !important;
  color: #276EF1 !important;
}

.leave-list .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 28px;
  font-size: 14px;
}


.leave-list .mat-button-toggle-label-content:hover {
  color: black !important;
}


.financial-date-cls .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0 !important;
  height: 35px;
  width: 225px;
  display: flex;
  border: 1px solid #EDF3FD;
}

.financial-date-cls .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding: 0 !important;
}


.disable-input {
  opacity: 0.5;
  pointer-events: none;
}

.status-cls .mat-select-trigger {

  font-family: Manrope;
  font-size: 11px;
  padding: 8px;
  line-height: 2;
  padding: 8px;
  margin-top: 8px;
  font-weight: 600;
}


.leave-list-table .mat-elevation-z8 {
  box-shadow: none;
}


.custom-width {
  .my-leave-cls {
    min-width: 200px;
  }
}



.custom-date {
  align-self: center;
  align-items: center;
  gap: 20px;

  .mat-form-field-flex {
    border: 0.5px solid #E0E0E0;
    padding: 3px 18px;
    display: flex;
    align-items: center;
    border-radius: 6px;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 0px;
  }

  .mat-form-field-appearance-legacy .mat-form-field-prefix .mat-datepicker-toggle-default-icon,
  .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
    width: 1.5em;
  }
}


.calendar_management {
  align-items: center;

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-flex {
    border: 0.5px solid #e0e0e0;
    padding: 3px 0px;
    display: flex;
    align-items: center;
    border-radius: 6px;
  }

  &.financial-date-cls .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 3px 18px !important;
    width: 100% !important;
  }

  label#mat-form-field-label-1,
  label#mat-form-field-label-3 {
    position: relative;
    top: 9px;
    left: 6px;
    background: #fff;
    width: fit-content;
  }

  .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
    width: 1.5em;
  }

  .mat-datepicker-toggle .mat-focus-indicator .mat-icon-button .mat-button-base {
    margin-right: 10px !important;
  }
}

@media screen and (1201 <=width <=1520) {
  .lc_custom-width {
    flex: 0 0 30% !important;
    max-width: 30% !important;
  }
}

.typo-text {
  line-height: 2.5;

  .mat-select-min-line {
    font-weight: 500;
    font-size: 15px;
    text-transform: capitalize;
  }
}



.add_holiday_leave {
  .mat-form-field {
    width: 100%;
  }

  .mat-form-field-infix {
    border: 0.5px solid #e0e0e0;
    display: flex;
    padding: 5px 10px;
    justify-content: space-around;
    border-radius: 6px;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-appearance-legacy .mat-form-field-label,
  label#mat-form-field-label-5,
  label#mat-form-field-label-7 {
    position: relative;
    top: -7px !important;
    left: 6px !important;
    font-size: 12px;
    background: #fff;
    width: fit-content !important;
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
    transform: none !important;
  }

  .mat-date-range-input-container {
    display: flex;
    align-items: center;
    height: 42px;
    font-weight: 600;
    margin-left: 10px;
  }
}


.approve-leave-bg-cls {
  color: #16b975;
  background-color: #16b9752e;
}

.rejected-leave-bg-cls {
  color: #DC3535;
  background-color: #F8D7D7;
}

.pending-leave-bg-cls {
  color: #C88E1B;
  background-color: #FEF0D3;
}

.team-leave-list-table .mat-elevation-z8{
  box-shadow: none;
}

.customs-button-toggle {
  .mat-button-toggle {
    border: 1px solid #aaa;

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }

    &.mat-button-toggle-checked {
      background: #fff;
      border-color: var(--primary-color) !important;
    }

    .mat-button-toggle-label-content {
      line-height: 32px;
    }
  }
}


@media screen and (max-width: 1280px) {
  .mat-button-toggle {
    border: 1px solid #aaa;

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }

    &.mat-button-toggle-checked {
      background: #fff;
      border-color: var(--primary-color) !important;
    }

    .mat-button-toggle-label-content {
      line-height: 27.5px !important;
    }
  }
}

.present-status-cls {
  background-color: #D0F1E3;
  color: #12945E;
  font-size: 11px;
  font-weight: 600;
  padding: 4px 8px;
  text-transform: capitalize;
  border-radius: 4px;
}


.absent-status-cls {
  background-color: #F8D7D7CC;
  color: #DC3535;
  font-size: 11px;
  font-weight: 600;
  padding: 4px 8px;
  text-transform: capitalize;
  border-radius: 4px;
}


.week-status-cls {
  background-color: #FEF0D3;
  color: #FAB222;
  font-size: 11px;
  font-weight: 600;
  padding: 4px 8px;
  text-transform: capitalize;
  border-radius: 4px;
}


.holiday-status-cls {
  background-color: #D6E4F8;
  color: #2A5FB0;
  font-size: 11px;
  font-weight: 600;
  padding: 4px 8px;
  text-transform: capitalize;
  border-radius: 4px;
}



.date_field {
  input#mat-input-0 {
    padding: 5px 0px;
  }

  .mat-form-field {
    width: 100%;
  }

  mat-datepicker-toggle.mat-datepicker-toggle {
    position: relative !important;
    top: -12px !important;

    svg {
      font-size: 1.37rem;
    }
  }
}

.start-date {
  .mat-form-field-prefix {
    padding: 0 !important;
    position: relative;
    top: 6px;
  }

  .mat-form-field-infix {
    padding: 0px 3px !important;
    margin-top: 8px;
  }

  .mat-form-field-suffix {
    padding: 0px 5px !important;
    margin-top: 7px;
  }

  .mat-form-field-flex {
    margin: 3px 0 !important;
    padding: 0 !important;
    min-height: 50px !important;
  }
}

.end-date {
  .mat-form-field-prefix {
    padding: 0px 3px !important;
    margin-top: 8px;
    position: relative;
    top: 6px;
  }

  .mat-form-field-infix {
    padding: 0px 3px !important;
    margin-top: 8px;
  }

  .mat-form-field-suffix {
    padding: 0px 5px !important;
    margin-top: 7px;
  }

  .mat-form-field-flex {
    margin: 3px 0 !important;
    padding: 0 !important;
    min-height: 50px !important;
  }
}

.member-view-cls{
  max-height: 271px;
  overflow-y: auto;
}



.present-variance-cls {
  color: #12945E;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 4px;
}


.absent-variance-cls {
  color: #DC3535;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 4px;
}

.holiday-variance-cls,.weekend-variance-cls{
  color: #9E9E9E;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 4px;
}

.holiday-working-variance-cls{
  color: #000000de;
}

.leave_type_name_header {
  width: 200px;
}

.leave_type_name {
  display: block;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 50px;
  width: 250px;
  white-space: nowrap;
}


.reason_header {
  width: 150px;
}

.reason_details {
  display: block;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 50px;
  width: 200px;
  white-space: nowrap;
}


@media screen and (max-width: 1480px) {
  .reason_header {
    width: 100px;
  }
  
  .reason_details {
    display: block;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 50px;
    width: 150px;
    white-space: nowrap;
  }

}

.search-align-cls{
  margin: 0px 11px;
}

.select-all-txt{
  font-size: 14px;
}


.leave-record-table-cls .mat-elevation-z8 {
  box-shadow: none;
  th{
    font-size: 12px;
    color: #8c8c8c;
    font-weight: 600;
  }
  
  td{
    text-align: center;
    font-size: 12px;
    border-bottom: 1px solid #e0e0e0;
    font-weight: 500;
  }

  th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type{
    padding-left: 0px;
  }

  th.mat-header-cell{
    text-align: center;
  }
}


.team-attendance-lists-table.mat-elevation{
  box-shadow: none !important;
}


.main-user-attendance-content-coantainer{
  padding: 60px 20px;
}

.user-attendance-cls .attendance-data-scroll-cls{
  height: calc(100vh - 16rem) !important;
  overflow-y: scroll !important;
}

.user-attendance-cls .attendance-data-scroll-cls::-webkit-scrollbar {
  width: 0px;
}


.user-leave-report-table-cls .mat-elevation-z8 {
  box-shadow: none;
  th{
    font-size: 14px;
    color: #8c8c8c;
    font-weight: 600;
    padding: 0px 8px;
    background: #f6f6f6; 
    position: sticky;
    top: 0px; 
    z-index: 20; 
  }
  
  td{
    text-align: center;
    font-size: 14px;
    border-bottom: 1px solid #e0e0e0;
    font-weight: 500;
  }

  th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type{
    padding-left: 0px;
  }

  th.mat-header-cell{
    text-align: center;
  }
}



.user-leave-report-modal{
  max-width: 100% !important;
  max-height: 71.8vh !important;
}

.location-view-modal{
  width: 65% !important;
  max-height: 71.8vh !important;
}

.sr-cls{
  min-width: 90px !important;
}

.employee-width-cls{
  min-width: 160px !important;
  text-transform: capitalize;
}


.table-height-cls{
  max-height: 64vh;
  overflow-y: scroll;
}

.table-height-cls::-webkit-scrollbar {
  width: 0px;
  height: 4px;
}

.team-attendance-sr-cls{
  text-transform: capitalize;
}


.leave-main-content-coantainer{
  padding: 75px 20px 0px 20px;
}


.user-leave-status-cls{
  width: 60% !important;
  border: 1px solid #d8dcde;
  height: 35px;
  border-radius: 5px;
  margin-right: 8px;
  max-width: 180px !important;
  font-weight: 500;
}

.user-leave-status-cls .mat-select-trigger {
  height: 2.525em;
  padding-left: 5px;
  font-size: 14px;
}

.my-leave-tab-cls .mat-primary .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label {
  height: 30px !important;
}

.leave-list .user-leave-details-mat-btn-cls .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 34px;
  font-size: 13px;
}

@media screen and (max-width: 1280px) {
  .leave-list .user-leave-details-mat-btn-cls .mat-button-toggle .mat-button-toggle-label-content {
      line-height: 32.5px !important;
  }
}

.users-leave .mat-primary .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label {
  height: 30px !important;
}

.beta-parent-cls{
  position: relative;
}

.beta-version-cls{
    background-color: #f4fffa;
    color: #12945e;
    font-size: 11px;
    font-weight: 600;
    padding: 0px 4px;
    text-transform: capitalize;
    border-radius: 4px;
    margin-left: -2px;
    margin-bottom: 0;
    margin-top: -5px;
    margin-left: 2px;
    position: absolute;
}

.beta-attendance-cls{
  background-color: #efefef;
    color: #12945e;
    font-size: 11px;
    font-weight: 600;
    padding: 0 4px;
    text-transform: capitalize;
    border-radius: 4px;
    margin-bottom: 0;
    margin-top: 14px;
    margin-left: 109px;
    position: absolute;

}


.beta-leave-cls{
  background-color: #efefef;
  color: #12945e;
  font-size: 11px;
  font-weight: 600;
  padding: 0 4px;
  text-transform: capitalize;
  border-radius: 4px;
  margin-bottom: 0;
  margin-top: -8px;
  margin-left: 158px;
  position: absolute;
}

.my-leave-tab-cls .mat-tab-group.mat-primary .mat-ink-bar, .users-leave .mat-tab-group.mat-primary .mat-ink-bar{
  background-color: #276ef1;
}