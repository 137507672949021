.custom-button-toggle {
  .mat-button-toggle {
    border: 1px solid #aaa;
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
    }
    &.mat-button-toggle-checked {
      background: #fff;
      border-color: var(--primary-color) !important;
    }
    .mat-button-toggle-label-content {
      line-height: 32px;
    }
  }
}

.card.attachment--card {
  border-radius: 0;
  box-shadow: none;
  background-color: #fff;
  border: 0;
  .card-header {
    background: #f5f5f5;
    border-bottom: 0;
    display: flex;
    align-items: center;
    border-radius: 0;
    input {
      width: calc(100% - 100px);
      border-radius: 8px;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      letter-spacing: 0.005em;
      color: #9e9e9e;
      border: 1px solid #aaa !important;
      background-color: #fafafa;
    }
  }
}

.list-view-table {
  thead {
    th {
      background: #efefef !important;
      border-bottom: 0;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.005em;
      color: #222;
      padding: 5px !important;
      border-right: 1px solid #eeeeee;
      &:nth-child(2) {
        padding-left: 0 !important;
      }
      &:nth-last-child(2) {
        padding-right: 0 !important;
        border-right: 0;
      }
      &:first-child,
      &:last-child {
        width: 24px;
        max-width: 24px;
        padding: 0 !important;
        border-right: 0;
      }
    }
  }
  tbody {
    tr {
      td {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.005em;
        color: #616161;
        border-bottom: 1px solid #eeeeee;
        padding-top: 5px;
        padding-bottom: 5px;
        &:first-child,
        &:last-child {
          width: 24px;
          max-width: 24px;
          padding: 0 !important;
          border-bottom: 0;
          border-right: 0;
          border-right: 1px solid #eeeeee;
        }
        &:nth-child(2) {
          padding-left: 0 !important;
        }
        &:nth-last-child(2) {
          padding-right: 0 !important;
          border-right: 0;
        }
        .user-short-name {
          height: 24px;
          width: 24px;
          min-height: 24px;
          min-width: 24px;
        }
      }
      &.table-title {
        td {
          border-bottom: 0;
          label {
            font-weight: 700;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: 0.005em;
            color: #212121;
            margin: 1em 0em;
          }
        }
      }
      &:hover {
        background: #f9f8f8;
      }
    }
  }
  &:not(:first-child):hover {
    background: #f5f5f5;
  }
  &.table-title {
    td {
      border-bottom: 0;
      label {
        font-weight: 700;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.005em;
        color: #212121;
      }
    }
  }
}

.attachment-list-block {
  display: flex;
  column-gap: 18px;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.005em;
  color: #222;
  .doc-name {
    max-width: 40%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    color: #222;
  }
}

.add-checklist-hover {
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: fit-content;
  .show-on-hover {
    opacity: 0;
    transition: opacity 0.2s linear;
  }
  &:hover {
    .show-on-hover {
      opacity: 1;
    }
  }
  span {
    line-height: 18px;
  }
}
