@import "./custom-variable";

@mixin common-text(
  $size,
  $font-weight,
  $line-height,
  $letter-spacing,
  $text-align-left,
  $color: null
) {
  font-size: $size;
  font-weight: $font-weight;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  text-align: $text-align-left;
  color: $color;
}

.mub-add-service {
  .mui_date {
    .mat-form-field-flex {
      height: 61px;
      padding-top: 16px;
    }

    .mat-form-field-infix {
      margin-bottom: 0 !important;
      padding-bottom: 0px !important;
    }
  }

  .mui_frequency {
    .a-r-list {
      gap: 7px;
    }

    ul {
      li {
        .mat-checkbox {
          label.mat-checkbox-layout {
            background: linear-gradient(0deg, #e0e0e022, #e0e0e022),
              linear-gradient(0deg, #f5f5f522, #f5f5f522);
          }

          &.mat-checkbox-checked {
            label.mat-checkbox-layout {
              background: #fff !important;

              .mat-checkbox-label {
                color: rgba(39, 110, 241, 1);
              }
            }
          }
        }
      }
    }
  }

  .accordion-item {
    border: 0px;

    .accordion-body {
      padding: 0;
    }

    .accordion-button:focus {
      z-index: 3;
      border-color: transparent;
      outline: 0;
      box-shadow: none;
    }

    .accordion-button:not(.collapsed) {
      color: rgba(158, 158, 158, 1) !important;
      background-color: transparent;
      box-shadow: none;
    }

    .accordion-button:not(.collapsed)::after {
      background-image: var(--bs-accordion-btn-icon);
      width: 14px;
      background-size: 100%;
    }

    .accordion-button::after {
      width: 14px;
      background-size: 100%;
    }
  }

  .mui_add-client {
    .mat-form-field-flex {
      height: 51px;
    }
  }
}

.mat-primary {
  .mui-add-service-drop-li {
    .mat-pseudo-checkbox-checked {
      background-color: transparent !important;
    }
  }
}

// Add New Members styles
.mub-add-new-member {
  .mat-form-field {
    margin-top: 14px;
  }
  .mui-add-service-drop-li {
    .user-short-name {
      &.no-more-border {
        border: 0px solid transparent;
      }
    }
  }
  .mat-icon-button {
    position: relative;
    top: -25px;
    right: -25px;
    &.mat-accent {
      color: #000 !important;
    }
    .mat-button-focus-overlay {
      background-color: #fff !important;
    }
  }
  .mat-ripple {
    &.mat-button-ripple {
      &.mat-button-ripple-round {
        display: none !important;
      }
    }
  }
}

// Add Client Table styles
.mub-table-client {
  .cdk-column-staticClientName {
    display: flex;
    align-items: center;
  }
}

.only-this-custom-cls-add-selection-all .mat-form-field-flex {
  height: auto !important;
}

.column-structure-scroll-cls::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #bdbbbb !important;
  z-index: 0;
  border-radius: 10px;
  overflow: auto;
}

.column-structure-scroll-cls::-webkit-scrollbar-thumb {
  background-color: #bdbbbb !important;
  z-index: 0;
  border-radius: 10px;
  overflow: auto;
}
