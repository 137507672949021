@import "./custom-variable";

.common-list-nav-bar.user-profile-list li {
  justify-content: flex-start;
}

.user-img-personalized {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 8px;
}

.ctm-user-list-view {
  .mat-drawer-inner-container {
    overflow-x: hidden;
    padding-right: 15px;
  }

  .file-folder-views {
    padding: 0 !important;
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(256px,1fr));
  grid-gap: 1rem;
  height: auto !important;
  align-items: flex-start;
  overflow-y: scroll;
    ul {
      width: 95%;
      margin: auto;

      li {
        align-items: center;
        padding: 10px;
        position: relative;
        cursor: pointer;

        .user-list-view-wrap {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 20px;
        }

        img {
          width: 18px;
          height: 18px;
        }

        img.user-img-personalized {
          width: 32px;
          height: 32px;
          object-fit: cover;
          margin-left: 12px;
          object-position: top;
        }

        .user-short-name {
          margin-left: 10px;
        }

        &.activeClass {
          background-color: #edf3fd;
          border-radius: 10px;
          span.text-dwindle.text-capitalize {
            color: #276ef1;
            font-weight: 600;
          }
          &::after {
            position: absolute;
            width: 8px;
            height: 32px;
            top: 50%;
            transform: translateY(-50%);
            background: var(--primary-color);
            border-radius: 0 14px 14px 0;
            left: -4px;
          }
        }

        &:hover {
          cursor: pointer;

          &::after {
            position: absolute;
            width: 8px;
            height: 32px;
            top: 50%;
            transform: translateY(-50%);
            background: var(--primary-color);
            border-radius: 0 14px 14px 0;
            left: -4px;
          }
        }
      }
    }
  }
}

.ctm-user-list-view {
  .task-search-pos-fixed {
    margin-bottom: 50px;
    background-color: #fff;
    z-index: 9;
    position: fixed;
    top: 74px;
    border-right: 1px solid #eeeeee;
    width: 335px;
  }

  .project-list-view-search ul .input-width {
    width: 100% !important;
  }

  .dms-sidebar {
    .mat-drawer-inner-container {
      padding-top: 55px;
    }
  }

  .user-short-name {
    margin-right: 10px;
  }

  .mat-drawer-content {
    height: 100vh;
  }
}

.ctm-user-list-view .user-dms-container {
  padding-right: 0;
}
.fc-daygrid-day-bottom.cst-bottoms {
  display: none;
}
.fc-daygrid-day-events
  .fc-daygrid-event-harness:nth-last-child(-n + 2)
  .fc-daygrid-day-bottom.cst-bottoms {
  display: block;
}
