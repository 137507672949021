@import "./custom-variable";

@mixin common-text($size,
  $font-weight,
  $line-height,
  $letter-spacing,
  $text-align-left,
  $color: null) {
  font-size: $size;
  font-weight: $font-weight;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  text-align: $text-align-left;
  color: $color;
}

.mub_email_company_listing {
  background-color: #fafafa;
  height: 100vh;

  .close_btn {
    @include common-text($font-size-12,
      700,
      18px,
      0.005em,
      $text-align-left,
      $color: #276ef1);
    display: inline-block;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    z-index: 123;
    top: 64px;

    img {
      padding: 5px;
      margin-right: 6px;
    }
  }

  .bdcb_email,
  .bdcb_company_name {
    @include common-text($font-size-12, 500, 16px, 0.005em, $text-align-left);
  }

  .bdcb_email {
    color: var(--primary-color);
    margin: 0 5px 0 0;
  }

  .bdcb_company_name {
    color: var(--link-color-nav);
  }

  .email_heading {
    @include common-text($font-size-24,
      500,
      36px,
      0.0015em,
      $text-align-left,
      $color: $text-dark-black-color );
  }

  .mat-tab-label-container {
    .mat-tab-labels {
      .mat-tab-label-content {
        @include common-text($font-size-14,
          700,
          22px,
          0.005em,
          $text-align-left,
          $color: $text-dark-black-color );
      }

      .mat-ripple.mat-tab-label {
        padding: 0 20px;
        min-width: 130px;
      }

      .mat-ink-bar {
        color: var(--primary-color);
      }

      border-bottom: 1px solid $light-grey-color;
    }

    margin-bottom: 20px;
  }

  .inner-card-block {
    transition: all 0.5s ease-in-out;
    border-radius: 12px;
    cursor: pointer;
    margin-bottom: 20px;

    .mat-card {
      padding: 24px;
      border-radius: 12px;
      border: 1px solid $light-grey-color;
      min-width: 364px !important;
      max-width: 364px;

      .box-card {
        padding: 24px 32px !important;
      }

      .avatar.avatar--lg {
        height: 48px;
        width: 48px;
        min-width: 48px;
        border-radius: 12px;
        @include common-text($font-size-18,
          700,
          28px,
          0.0025em,
          $text-align-left,
          $color: $white-color );
      }

      .block-title {
        @include common-text($font-size-18,
          700,
          28px,
          0.0025em,
          $text-align-left,
          $color: $text-dark-black-color );
        text-transform: capitalize;
        margin: 0;
        width: 290px;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media screen and (max-width: 1450px) {
          width: 240px;
        }

        @media screen and (max-width: 1240px) {
          width: 190px;
        }
      }

      .block-sub-title {
        @include common-text($font-size-14,
          700,
          22px,
          0.005em,
          $text-align-left,
          $color: $text-dark-black-color );
        margin: 0;

        .date-title {
          color: #9e9e9e;
          font-weight: 500;
        }
      }

      .mat-card-content {
        img {
          width: 48px;
          height: 48px;
          object-fit: cover;
          border-radius: 12px;
          background-color: #fafafa;
        }
      }

      @media screen and (min-width: 1920px) {
        min-width: 504px !important;
      }

      &:hover {
        box-shadow: 0px 4px 12px 0px #21212114 !important;
        transition: all 0.3s ease-in-out;
      }
    }

    &.card-grid-view-custom {
      display: flex;
      grid-gap: 1rem;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }

  .email-cont {
    flex: 0 0 50%;
    max-width: 60%;
    margin: 0 auto;
  }

  @media screen and (max-width: 1650px) {
    .col-xl-3 {
      flex: 0 0 auto;
      width: 20%;
    }

    .col-xl-9 {
      flex: 0 0 auto;
      width: 80%;
    }
  }

  .extra-cards {
    height: calc(100vh - 325px);
    overflow-x: hidden;
    width: 100%;
    margin: 0 auto;
    max-width: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      background-color: #fafafa !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
      border: 31px solid #fafafa;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #fafafa !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
      border: 31px solid #fafafa;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #fafafa !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
      border: 31px solid #fafafa;
    }
  }
}

// Side Bar Styles

.mub_email_list_sidebar {
  background-color: #fafafa;
  height: 100%;

  .main-sidebar.dms-sidebar {
    top: 126px !important;
    background: $white-color;
    border: 1px solid #eeeeee;
    margin-top: 0 !important;
    padding: 0px;
    @include common-text($font-size-14,
      500,
      22px,
      0.005em,
      $text-align-left,
      $color: $text-gery-color );

    .mat-drawer-inner-container {
      background-color: transparent;
    }

    ul.list-unstyled {
      height: 86vh;
      overflow: auto;

      li {
        padding: 18px 15px;
        position: relative;
        cursor: pointer;

        &:hover,
        &.drawer-list-active {
          background-color: var(--light-bg-color);

          &::after {
            content: "";
            border-left: 4px solid var(--primary-color);
            position: absolute;
            height: 32px;
            border-radius: 0px 4px 4px 0px;
            left: 0;
            top: 13px;
          }

          a {
            color: $text-dark-black-color;
            font-weight: 600;
          }
        }

        a {
          @include common-text($font-size-14,
            500,
            22px,
            0.005em,
            $text-align-left,
            $color: $text-gery-color );
        }
      }
    }
  }

  .main-sidebar.mat-drawer {
    width: 250px;
    border-top: 0px solid transparent;
    height: 100vh;
  }

  @media screen and (max-width: 1550px) {
    .custom-width-top {
      flex: 0 0 auto;
      width: 20%;
    }

    .custom-width-bottom {
      flex: 0 0 auto;
      width: 80%;
    }
  }

  @media screen and (max-width: 1350px) {
    .custom-width-top {
      flex: 0 0 auto;
      width: 21%;
    }

    .custom-width-bottom {
      flex: 0 0 auto;
      width: 79%;
    }
  }

  @media screen and (max-width: 1300px) {
    .custom-width-top {
      flex: 0 0 auto;
      width: 22%;
    }

    .custom-width-bottom {
      flex: 0 0 auto;
      width: 78%;
    }
  }

  .custom-width-block {
    .px-4 {
      max-width: 90%;
      margin: 0 auto;

      .content-block {
        p {
          @include common-text($font-size-12,
            500,
            18px,
            0.005em,
            $text-align-right,
            $color: #9e9e9e);
          display: flex;
          justify-content: flex-end;
          align-items: center;

          img {
            width: 18px;
            height: 18px;
            object-fit: cover;
          }
        }
      }
    }

    img {
      width: 48px;
      height: 48px;
      object-fit: cover;
      border-radius: 16px;
    }
  }
}

// email List Config

.mub-email-list-config {
  padding-top: 230px;
  max-width: 90%;
  margin: 0 auto;

  .config-top-bar {
    padding: 10px;
    position: fixed;
    top: 126px;
    width: 100%;
    background-color: #fafafa;
    z-index: 123;

    .config-topbar-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      position: relative;

      .sync-up-mail {
        @include common-text($font-size-20,
          500,
          29px,
          0.0015em,
          $text-align-left,
          $color: $text-dark-black-color );
      }

      span {
        @include common-text($font-size-14,
          600,
          18px,
          0.005em,
          $text-align-left,
          $color: $text-gery-color );
        color: #001528;
        font-weight: 800;
        display: contents;

        &.sync-up-date {
          color: #616161 !important;
        }
      }

      img {
        margin: 0 3px;

        &.left-icon {
          margin: 7px 15px 0 0;
        }
      }

      .image-title {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        justify-content: flex-start;

        button {
          @include common-text($font-size-16,
            700,
            24px,
            0.0025em,
            $text-align-left,
            $color: $white-color );

          padding: 8px 16px 8px 12px;
          border-radius: 8px;
        }
      }
    }

    .btn-list {
      position: absolute;
      z-index: 12345678;
      right: 22%;
      top: 0px;
      width: 8%;
      height: 100%;

      button {
        border-radius: 8px !important;
        padding: 8px 16px 8px 12px !important;
      }
    }
  }

  .config-table {
    td {
      &.mat-column-status {
        .status-content {
          letter-spacing: 0.005em;
          text-transform: capitalize;
          background-color: #edf3fd;
          border-radius: 4px;
          padding: 2px 8px;
          color: #276ef1;
          font-size: 11px;
          font-weight: 600;
          line-height: 16px;
          text-align: right;
        }
      }

      &.mat-column-users {
        width: 150px;
      }

      img.img-avatar {
        height: 34px;
        width: 34px;
      }

      .image-relative {
        position: relative;
        display: inline-block;
      }
    }
  }

  .main-content-coantainer {
    padding: 0;

    table.mat-table tbody {
      border-top: 0px solid transparent;
      background-color: $white-color;
      position: relative;
      top: 0;

      tr {
        padding: 0 20px;

        &:last-child td {
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
        }

        td {
          @include common-text($font-size-14,
            600,
            18px,
            0.005em,
            $text-align-left,
            $color: $text-dark-black-color );
        }
      }
    }

    table {
      width: 100%;
      border-collapse: collapse;
      border-radius: 2em;

      thead {
        background-color: #fafafa !important;

        th {
          @include common-text($font-size-12,
            600,
            18px,
            0.005em,
            $text-align-left,
            $color: #9e9e9e);
        }
      }

      .mat-checkbox-frame {
        border-color: #bdbdbd;
      }
    }

    .mat-table {
      background-color: #fff !important;
    }

    table tr:last-child td

    /*To remove the last border*/
      {
      border-bottom: 0 solid;
    }
  }

  .table.custom-table.header-bg th {
    background-color: #fafafa !important;
  }

  .countdown-timer {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: $white-color;
    border-radius: 12px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;

    .countdown-content {
      position: relative;
      margin-top: 123px;

      .progress-bar {
        margin: 0 auto;
        width: 162px;
        height: 162px;

        .progress-ring__progress {
          stroke: #16b975;
        }
      }

      .countdown {
        @include common-text($font-size-28,
          700,
          32px,
          0,
          $text-align-center,
          $color: #1d2129);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
      }
    }

    h2 {
      @include common-text($font-size-14,
        600,
        14px,
        0.0025em,
        $text-align-center,
        $color: #616161);
      margin-top: 20px;
    }
  }

  &.bg-custom {
    background-color: $white-color;
    border: 1px solid #eeeeee;
    border-radius: 12px;
    margin-top: 15px;
  }
}

// Email Attachment Styles

.custom-email-attachment-modal {
  max-width: 100%;
  width: 65vw !important;
}

.email-attachment-only {
  padding: 15px 24px;

  .header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .subject.title-one {
    font-size: 22px;
    display: inline-block;
    font-weight: 600;
    letter-spacing: 0.0015em;
    text-align: left;
    color: #212121;
  }

  .date.title-two {
    font-size: 15px;
    display: inline-block;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0.0015em;
    text-align: left;
  }

  .mp_address_email {
    color: #276ef1;
    text-decoration: none;
  }

  .custom-check {
    display: flex;
    justify-content: flex-start;
  }

  .attachment-part {
    overflow: auto; /* Ensure vertical scrolling */
    max-height: 40vh;

    &::-webkit-scrollbar-track {
      background-color: #f0f0f0 !important;
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 10px !important;
      height: 10px !important;
      background-color: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #ccc !important; 
      border-radius: 10px;
      border: 2px solid #f0f0f0;
      width: 20% !important;
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background-color: #bdbdbd !important;
    }
  }

  .border-custom {
    border-bottom: 1px solid #212121;
    padding-bottom: 5px;
    margin-bottom: 10px !important;
  }
}

// For Email Dropdown Styles

.email-modal-dropdown {
  .email-dropdown-part {
    .custom-multiselect__dropdpwn {
      .dropdown-list {
        ul {
          &:nth-child(2) {
            max-height: 208px !important;
            position: absolute;
            background: #fff;
            width: 100%;
            z-index: 123;
          }
        }
      }
    }

    button:hover {
      color: initial !important;
    }
  }
}

// Email Add
.email-modal-dropdown-other {
  .email-permission {
    .mat-card-content {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 340px;
      min-height: 124px;
    }
  }
}

.email-configuration-rated {
  .company-info-block {
    table.table.custom-table.header-bg {
      tbody {
        ul {
          li {
            .user-short-name {
              width: 24px !important;
              height: 24px !important;
              min-height: 24px !important;
              min-width: 24px !important;
            }

            img.img-avatar {
              height: 24px !important;
              width: 24px !important;
            }
          }
        }
      }
    }
  }
}


.rt-email-management-list {
  .rt-email-management-list-right {
    .rt-email-management-list-right-config {
      .mub-email-list-config {
        max-width: 100%;

        .table {
          &.custom-table {
            &.header-bg {
              th {
                min-width: 200px;

                &.mat-column-checkBox {
                  min-width: 50px;
                }
              }
            }
          }
        }

        .main-content-coantainer {
          table {
            &.mat-table {
              tbody {
                tr {
                  td {
                    min-width: 200px;

                    &.mat-column-checkBox {
                      min-width: 50px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.rt-email-list-config {
  .config-table {
    th.mat-header-cell {
      position: sticky;
      top: 0;
      z-index: 125;
    }
  }
}

.download-btn-block {
  display: inline-flex;
  align-items: self-start;

  .download-file-block {
    padding: 0;
    height: 35px;
    min-width: 35px !important;
    text-align: center;
    line-height: 32px;

    .download-file {
      margin-left: 0px !important;
    }
  }
}