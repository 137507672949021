.custom-field-table {
  border-radius: 6px;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #eeeeee;
  tr {
    th {
      padding: 10px;
      max-width: 100px;
      font-size: 12px;
      font-weight: 600;
      color: #222;
      float: none;
      margin: 4px 0 0;
      border: 0;
      border-right: 1px solid #eeeeee;
      border-bottom: 1px solid #eeeeee;
    }
    td {
      padding: 0;
      border: 0;
      border-bottom: 1px solid #eeeeee;
      .mat-form-field-subscript-wrapper {
        display: none;
      }
      .mat-form-field {
        margin: 0;
        display: flex;
        height: inherit;
        align-items: center;
        min-height: 40px;
        border: 1px solid transparent;
        .mat-form-field-wrapper {
          width: 100%;
          input,
          textarea {
            padding: 10px !important;
            box-sizing: border-box;
            &::placeholder {
              color: #616161;
            }
          }
        }
        .mat-select {
          padding: 10px !important;
        }
        &:hover,
        &.mat-focused {
          border-color: #67acf0;
        }
        .mat-select-placeholder {
          color: #444;
          -webkit-text-fill-color: #444;
        }
      }
      .mat-radio-group {
        min-height: 40px;
        display: flex;
        align-items: center;
      }
    }
    &:last-child {
      th,
      td {
        border-bottom: 0;
      }
      td {
        .mat-form-field {
          border-radius: 0 0 6px 0;
        }
      }
    }
    &:first-child {
      td {
        .mat-form-field {
          border-radius: 0 6px 0 0;
        }
      }
    }
  }
}
