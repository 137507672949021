:root {
  --primary-color: #276ef1;
  --priamry-gredient: linear-gradient(180deg, #5a90f4 0%, #276ef1 100%);
  --link-color-nav: #9e9e9e;
  --light-bg-color: #edf3fd;
}

/*--- Font Weight ---*/

$light: 300;
$Regular: 400;
$Medium: 500;
$Semi-bold: 600;
$Bold: 700;
$Extra-bold: 800;
$Black: 900;

/*=== Font Size ===*/

$banner-title: 64px;
$font-size-17: 17px;
$font-size-16: 16px;
$font-size-15: 15px;
$font-size-14: 14px;
$font-size-11: 11px;
$font-size-9: 9px;
$font-size-12: 12px;
$font-size-13: 13px;
$font-size-20: 20px;
$font-size-18: 18px;
$font-size-16: 18px;
$font-size-19: 19px;
$font-size-24: 24px;
$font-size-26: 26px;
$font-size-34: 34px;
$font-size-28: 28px;
$font-size-42: 42px;
$font-size-32: 32px;
$font-size-30: 30px;
$font-size-58: 58px;
$font-size-38: 38px;

// new css
$font-size-40: 40px;
$font-size-22: 22px;
$font-size-10: 10px;
$font-size-46: 46px;
$font-size-8: 8px;
$font-size-38: 38px;
$font-size-24: 24px;
$font-size-30: 30px;
$font-size-44: 44px;

/*=== Font Color ===*/

$addmany-active-color: #4590a0;
$title-color: #003844;
$small-title-color: #222222;
$text-color: #666666;
$footer-title-color: #333333;
$white-color: #ffffff;
$black-color: #000;
$footrBg-color: rgba(0, 56, 68, 0.05);
$bodyBg-color: #f5f5f5;
$CopyrightBg-color: #d9e2e3;
$borderbefore-color: #2b7787;
$inputborder-color: #ccc;
$box-shadow-color: #0000000d;
$checkboxBorder-color: #a7a7a7;
$success-color: #57d15f;
$notification-color: #ff4646;
$profiletext-color: #202126;
$toggelbg: #a5bfc5;
$opecity7: rgba(0, 56, 68, 0.07);
$opecity71: rgba(0, 56, 68, 0.71);
$opecity5: rgba(178, 178, 178, 0.5);
$opecity6: rgba(194, 194, 194, 0.6);
$light-grey-color: #eeeeee;
$red-color: #dc3535;
$orange-color: #fab222;
$green-color: #16b975;
// new css
$main-ttl-color: #2d3134;
$auth-box-shadow-color: #0000004d;
$border-color: #c9c9c9;
$input-txt-color: #c1c1c1;
$link-txt-color: #003563;
$blue-btn-bg-color: #25ace6;
$nav-bg: #003664;
$sidebar-all-color: #6373801a;
$black: #000000;
$table-box-shadow-color: #00000024;
$table-border-color: #e7eaee;
$table-header-txt: #637380;
$search-placeholder-color: #63738080;
$table-even-tr-color: #00366408;
$drop-down-shadow: #00000073;
$box-border-color: #dbdbdb;
$box-header-ttl: #2d313480;
$box-header-border-color: #70707033;
$red-txt: #ff0000;
$green-txt: #00b169;
$light-bg: #f9f9f9;
$refer-border-color: #00366480;
$refer-btn-bg: #003564;
$month-txt: #ff3b3b;
$join-color: #21a82a;
$invite-color: #ff9191;
$plan-border-color: #f3f3f3;
$plan-user-txt: #a1a1a1;
$plan-feature-border-color: #707070;
$search-placeholder-color: #63738080;
$folder-box-border: #707070;
$drop-box-shadow-color: #00000029;
$text-gery-color: #616161;
$side-navbar-g-color: rgb(2, 3, 54);
$text-dark-grey-clor: #9e9e9e;
$text-dark-black-color: #212121;
$text-lit-grey-500: #bdbdbd;
$text-blue-dark-900: #2a5fb0;
$text-green-dark-900: #12945e;
$text-orange-dark-900: #c88e1b;

// text alignment
$text-align-left: left;
$text-align-right: right;
$text-align-center: center;
