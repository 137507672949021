.static-check-btn [value="month"] label.mat-radio-label {
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
}
.static-check-btn
  [value="month"]
  label.mat-radio-label
  span.mat-radio-inner-circle {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.static-check-btn [value="week"] label.mat-radio-label {
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
}
.static-check-btn
  [value="week"]
  label.mat-radio-label
  span.mat-radio-inner-circle {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
full-calendar.full-model-cal-ctm.dashboard-full-model-cal-ctm .user-short-name {
  margin-right: 10px;
  border-radius: 50px;
  margin-left: 10px;
  border: 2px solid #fff;
  position: absolute;
  right: 0px;
  top: 8px;
}

// Pod Statistics Styles
.pod-dashboard-ui-only {
  .pod-static-chart {
    .pod-title {
      margin-bottom: 0;
    }
    .pod-static-chart-list {
      .mat-form-field-appearance-legacy {
        .mat-form-field-wrapper {
          padding-bottom: 0 !important;
        }
      }
      .pod-check-block {
        .pod-check-btn {
          display: flex;
          align-items: center;

          .pod-radio-group {
            min-width: 126px;
          }
        }
      }
      .pod-chart-list-view {
        top: -1px;
      }
      .static-check-btn .dash-drop .mat-form-field-infix {
        padding: 2px 7px 5px;
      }
    }
  }
}
.table-resusable-module table .mat-column-priority {
  min-width: min-content;
}
.table-resusable-module table .mat-column-task_name {
  min-width: 23em !important;
}
