@import "./custom-variable";
.container {
  &-fixed-width {
    max-width: 744px;
    margin: 0 auto;
  }
  &-fixed-width-md {
    max-width: 936px;
    margin: 0 auto;
  }
}
.detail-list {
  > li {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.005em;
    color: #616161;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    > img {
      width: 20px;
      height: 20px;
      margin-right: 15px;
    }
  }
}
.o-avatar-preview {
  width: 92px;
  height: 92px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
  .upload-section {
    position: absolute;
    width: 100%;
    height: 30px;
    bottom: -30px;
    transition: bottom 0.2s linear;
    label {
      color: #fff;
      font-weight: 500;
      font-size: 12px;
      margin-bottom: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #21212175;
    }
  }
  &:hover {
    .upload-section {
      bottom: 0;
    }
  }
}

// modal css

.modal-body {
  padding: 24px;
  .avatar-preview {
    img {
      height: 120px;
      width: 120px;
      object-fit: cover;
      border-radius: 24px;
    }
  }
  .upload-section {
    height: 120px;
    width: 120px;
    overflow: hidden;
    > label {
      background: #eeeeee;
      border-radius: 24px;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      font-weight: 500;
      cursor: pointer;
    }
    input[type="file"] {
      position: absolute;
      left: -9999px;
    }
  }
  .form-group {
    .form-group-inner {
      position: relative;
      input {
        padding: 12px 12px 12px 30px;
      }
    }
  }
}
.password-modal {
  .placeholder {
    left: 0 !important;
  }
}
.s-a-list {
  > li {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #616161;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    > img {
      position: absolute;
      left: 0;
      top: 6px;
    }
  }
}

.upload-section {
  height: 120px;
  width: 120px;
  overflow: hidden;
  > label {
    background: #eeeeee;
    border-radius: 50%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    font-weight: 500;
    cursor: pointer;
    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.005em;
      color: #bdbdbd;
      margin-bottom: 0;
    }
  }
  input[type="file"] {
    position: absolute;
    left: -9999px;
  }
}
