@import "./custom-variable";

.add-edit-text-top-action {
  column-gap: 20px;
  .progress--slider {
    height: auto;
    min-width: 100px;
    padding: 0;
    .mat-slider-wrapper {
      position: static;
      height: 7px;
      .mat-slider-thumb {
        right: -10px;
        bottom: -5px;
        width: 10px;
        height: 10px;
      }
      .mat-slider-focus-ring {
        display: none;
      }
    }
  }
}
.create-task--card {
  .mat-card-header {
    height: 65px;
    padding: 16px 24px;
    border-bottom: 1px solid #e0e0e0;
    .mat-card-header-text {
      margin: 0;
    }
  }
  .mat-card-content {
    padding: 10px 24px;
    overflow: auto;
  }
  .mat-slider {
    &.mat-slider-min-value {
      &.mat-slider-thumb-label-showing {
        .mat-slider-thumb {
          background-color: #16b975;
        }
      }
    }
  }
  .mat-slider.mat-accent {
    .mat-slider-track-fill,
    .mat-slider-thumb-label {
      background-color: #16b975;
    }
  }
}
.cirlce {
  height: 17px;
  width: 17px;
  border: 1px solid #bdbdbd;
  border-radius: 50%;
}
.custom-textarea-form-control {
  background: rgb(250, 250, 250);
  border: 1px solid rgb(224, 224, 224);
  border-radius: 5px;
  padding: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.005em;
  color: #444;
  min-width: 120px;
  margin-top: 0px;
  margin-bottom: 0px;
  height: 128px;
  &:hover,
  &:focus,
  &:active {
    border-color: #67acf0 !important;
    box-shadow: none;
  }
}
.task-acion-bar {
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  border-bottom: 1px solid #eeeeee;
  padding: 6px 15px;
  column-gap: 4px;
  background: #fafafa;
  border-radius: 12px;
  input.form-control {
    border-radius: 0;
    border: 1px solid transparent;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.005em;
    color: #000000de;
    padding: 0;
    box-shadow: none;
    outline: 0;
    background: #fafafa;
  }
  &:hover {
    box-shadow: 0 0 10px rgba(0 0 0 / 13%);
    border-color: #67acf0;
  }
}
.create-task-block {
  display: inline-block;
  box-shadow: none;
  border-right: 2px solid #e0e0e0;
  .custom-textarea-form-control {
    background: #fff;
    border: 1px solid rgb(216, 220, 222);
    resize: none;
  }
  mat-form-field {
    .mat-form-field-infix {
      border-top: 0;
      padding: 12px 0 10px;
    }
    .mat-select-placeholder {
      color: rgb(47, 57, 65);
      -webkit-text-fill-color: rgb(47, 57, 65);
    }
    mat-select {
      .mat-chip.mat-standard-chip {
        background-color: rgb(233, 235, 237);
        color: rgb(73, 84, 92);
        min-height: 24px;
        font-size: 11px;
        font-weight: 700;
        padding: 3px;
        .short-name {
          height: 18px;
          width: 18px;
          min-width: 18px;
          min-height: 18px;
          font-size: 8px;
          margin-right: 5px;
        }
      }
    }
    .mat-form-field-appearance-outline.mat-focused
      .mat-form-field-outline-thick {
      color: #67acf0;
    }
    .mat-form-field-outline-start,
    .mat-form-field-outline-end,
    .mat-form-field-outline-gap {
      border: 0;
    }
    .mat-form-field-flex {
      padding: 0;
    }
    .mat-form-field-outline {
      color: #d8dcde;
      border: 0;
      border-bottom: 1px solid currentColor;
      border-radius: 0;
    }
    &:hover {
      .mat-form-field-outline {
        color: #67acf0;
      }
    }
  }
  .custom-label {
    font-size: 12px;
    font-weight: 600;
    color: #2f3941;
    float: none;
    margin: 4px 0 0;
  }
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
  .custom-textarea-form-control {
    height: auto;
  }
  .custom-checkbox.mat-checkbox-checked .mat-checkbox-background {
    background-color: #75a4fa;
  }
  .mat-checkbox-label {
    font-size: 11px;
  }
  .mat-checkbox-inner-container {
    height: 15px;
    width: 15px;
  }
  .mat-select-arrow-wrapper {
    transform: unset !important;
  }
}
.custom-field-block {
  border-right: 2px solid #e0e0e0;
  .mat-card.create-task--card {
    min-width: 408px;
    max-width: 408px;
    &.toggle-card {
      min-width: 68px;
      .block-title {
        position: absolute;
        transform: rotate(-90deg) translate(-65px, -105px);
        transform-origin: right top;
      }
    }
  }
}
.add-edit-task-main-block {
  display: flex;
  .left--block {
    display: flex;
    flex: 1 1 0px;
    min-width: 50%;
  }
  .right--block {
    flex: 1 1 0px;
  }
}
.custom-field-closed {
  .create-task-block {
    flex: 1 1 auto;
    max-width: 100%;
  }
}
.number-icon {
  width: 20px;
  height: 13px;
}
.grid-view {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-gap: 1rem;
}
.slider-list {
  max-width: 100px;
}
.invisible-date-picker-input {
  width: 0;
  position: absolute;
}
.doc--list {
  app-document-icon {
    width: 100%;
  }
}

.custom-error-msg {
  position: absolute;
  bottom: 0;
  font-size: 12px;
}
.bottom-action-row {
  border-top: 1px solid #e0e0e0;
}
.checklist-expansion-panel {
  box-shadow: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #eeeeee;
  .mat-expansion-panel-header {
    padding: 7px 18px;
    height: auto;
  }
  .mat-expansion-panel-body {
    overflow: visible;
  }
}
.checklist-user {
  > img {
    height: 25px;
    width: 25px;
    border-radius: 50px;
    border: 1px solid #ffffff;
    object-fit: cover;
    margin-right: 12px;
  }
  h4 {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #212121;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  p {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.005em;
    color: #9e9e9e;
    margin-bottom: 0;
  }
}
.checklist-lists {
  padding-left: 50px;
  > li {
    margin: 3px 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: #616161;
    &::marker {
      color: #616161;
    }
  }
}
.custom-field-box {
  .mat-form-field-wrapper {
    &,
    *:not(textarea) {
      padding: 0 !important;
    }
    .mat-form-field-subscript-wrapper {
      position: relative;
    }
    .mat-form-field-infix {
      border: 0 !important;
    }
  }
  .mat-form-field-underline {
    display: none;
  }
  input {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.005em;
    &::placeholder {
      color: var(--primary-color);
    }
  }
  .mat-select-placeholder {
    color: var(--primary-color);
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.005em;
  }
  .mat-form-field-label-wrapper:empty {
    display: none;
  }
  .ngx-mat-tel-input-container {
    display: flex;
    align-items: center;
    .country-selector {
      position: relative;
    }
  }
  .mat-form-field-prefix {
    width: 20px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    img {
      transform: translateY(3px);
    }
  }
  .mat-form-field {
    margin: 6px 0;
  }
}
.textarea-field {
  textarea {
    resize: none;
    background: #fafafa;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 16px !important;
    min-height: 120px !important;
    height: auto !important;
  }
}
.text-feild {
  input {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: #212121;
  }
}
img.digit-icon {
  width: 7px;
  transform: translateY(-2px);
}
.client-info-list {
  > li {
    position: relative;
    &:first-child {
      z-index: 1;
    }
    &:last-child {
      margin-top: -30px;
    }
  }
}
.client-card {
  padding: 16px;
  background: linear-gradient(180deg, #eff4fc 0%, #fef4f0 100%);
  border: 1px solid #ffffff;
  border-radius: 16px;
  .client-company-name {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.0025em;
    color: #212121;
    margin-bottom: 0;
  }
  .client-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: #616161;
  }
  .client-contact-info-list {
    > li {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.005em;
      color: #212121;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
      .img-box {
        width: 20px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
.other-info-card {
  background: #f5f5f5;
  border-radius: 16px;
  padding: 46px 16px 16px;
  h4 {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.0025em;
    color: #212121;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 4px;
    margin-bottom: 0;
  }
  .other-detail-block {
    margin-top: 20px;
    > div {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .label-block {
        min-width: 100px;
        max-width: 100px;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.0025em;
        color: #616161;
      }
      .other-detail-value-block {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.005em;
        color: #212121;
      }
    }
  }
}
.custom-menu-list {
  max-height: 400px !important;
  padding: 15px;
  .c-s-input {
    margin: 10px 0;
  }
  .custom-checkbox__only-border {
    .mat-checkbox-inner-container {
      height: 14.5px !important;
      width: 14.5px !important;
    }
  }
}
.custom-input-field.mat-form-field.task-name {
  input {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: #212121;
    box-shadow: none;
    outline: 0;
    max-width: inherit;
    padding: 6px;
    display: flex;
    box-sizing: border-box;
    height: 38px;
    border-radius: 5px;
    border: 1px solid transparent;
    margin-top: 10px;
    &:hover,
    &:active,
    &:focus {
      border-color: #67acf0;
    }
  }
  .mat-form-field-underline {
    display: none;
  }
  .mat-form-field-prefix {
    top: -3px !important;
  }
}
.reminder-block {
  .mat-form-field-underline {
    display: none;
  }
  .mat-calendar {
    border-bottom: 1px solid #eeeeee;
  }
  .mat-form-field-hide-placeholder {
    .mat-select-placeholder {
      color: #616161;
      -webkit-text-fill-color: #616161;
    }
  }
  .remind-to-dropdown {
    .mat-select-arrow {
      border: 0;
      background: url(../../assets/images/dropdowndownarro.svg) no-repeat;
      height: 8px;
      width: 14px;
    }
  }
}
.bg-grey-block {
  background: #f5f5f5;
  border-radius: 8px;
  padding: 16px;
}
app-log-management-tab {
  input.c-s-input {
    background-color: #fff;
    border: 1px solid #eeeeee !important;
  }
  a.mat-menu-trigger:after {
    content: "";
    border: 5px solid transparent;
    border-top-color: black;
    margin-left: 7px;
    margin-top: 5px;
  }
  .box-body {
    height: calc(100vh - 200px) !important;
    overflow-y: auto;
  }
  a {
    text-decoration: none;
  }
}
.mat-menu-panel.custom-user-list {
  transform-origin: right top;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 12px 12px rgb(33 33 33 / 8%);
  border-radius: 8px;
  min-width: 250px;
  .mat-menu-content {
    padding: 0;
  }
  ul {
    > li {
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 10px;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
        border-bottom: 0;
      }
    }
    img {
      height: 24px;
      width: 24px;
      border-radius: 50%;
      border: 1px solid #ffffff;
      object-fit: cover;
    }
    .c-details {
      h4 {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.005em;
        color: #212121;
        margin-bottom: 0;
        text-transform: capitalize;
      }
      p {
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.005em;
        color: #9e9e9e;
      }
    }
  }
}

.time-action-bar {
  background: #fff9ee;
  padding: 8px 24px;
  margin-bottom: 16px;
  ul {
    column-gap: 15px;
    > li {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.005em;
      color: #9e9e9e;
      span {
        font-weight: 700;
      }
      &.est-time {
        span {
          color: #1d53b7;
        }
      }
      &.actual-time {
        span {
          color: #12945e;
        }
      }
      &.total-time {
        span {
          color: #212121;
        }
      }
      &.v--divider {
        width: 1px;
        height: 24px;
        background: #eeeeee;
      }
    }
  }
}

::ng-deep .mat-select-panel {
  min-width: 100% !important;
}
.customClass {
  .mat-select-panel {
    min-width: calc(100% + 15px) !important;
    background: #ffffff;
    box-shadow: 4px 4px 8px rgba(33, 33, 33, 0.12) !important;
    border: 1px solid #2121211f;
    .mat-option-text {
      display: flex;
      align-items: center;
      color: #616161;
      font-weight: 500;
    }
    .search-box {
      position: sticky;
      top: 0;
      background: #fff;
      padding: 10px;
      z-index: 10;
    }
  }
  .mat-primary .mat-pseudo-checkbox-checked,
  .mat-primary .mat-pseudo-checkbox-indeterminate {
    background-color: #75a4fa;
  }
  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #75a4fa;
  }
  .mat-pseudo-checkbox {
    color: #292929;
    border-width: 1px;
  }
  .mat-pseudo-checkbox-checked::after {
    top: 3.4px;
    left: 2px;
  }
}
.attachment-doc-list {
  > li {
    border-bottom: 1px solid #eeeeee;
  }
}
.due-date-badge {
  background: #f8f8f8;
  padding: 5px 10px;
  border-radius: 5px;
  > div {
    font-size: 14px;
  }
  &.overdue {
    background: #ff000017;
    .due-date-txt {
      color: red;
    }
  }
  &.today {
    background: #0080000f;
    .due-date-txt {
      color: green;
    }
  }
}
.custom-badge {
  border-radius: 30px;
  padding: 4px 12px 6px;
  display: flex;
  align-items: center;
  justify-content: start;
  column-gap: 2px;
  text-transform: capitalize;
  &:after {
    top: 50% !important;
    transform: translateY(-50%);
  }
  &.status-badge {
    min-width: 123px;
  }
  &.priority-badge {
    min-width: 97px;
  }
}
.m-t-custom {
  transform: translateY(-3px);
}
.card.mat-card.doc-card {
  &:hover {
    border-color: #67acf0;
  }
}
.add-task-minimize-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  .mat-icon {
    background: #276ff19e;
    border-radius: 6px;
    padding-bottom: 18px;
    line-height: 15px;
    color: #fff;
  }
}
:root .form-feild-select-custom {
  width: 100%;
  background: #fff;
  padding: 12px 0 10px;
  border-bottom: 1px solid #d8dcde;
  color: #2f3941;
  position: relative;
  &:after {
    content: "";
    border: 5px solid transparent;
    border-top-color: #545b62;
    position: absolute;
    right: 0;
    top: 50%;
  }
  &:hover {
    border-bottom-color: #67acf0;
  }
  .mat-chip {
    background-color: #e9ebed;
    color: #222;
    min-height: 24px;
    font-size: 12px;
    font-weight: 700;
    padding: 2px !important;
    > div {
      img {
        width: 13px;
        vertical-align: text-bottom;
      }
    }
    .mat-chip-remove.mat-icon {
      margin-left: 3px;
    }
    .user-short-name {
      height: 18px;
      width: 18px;
      min-width: 18px;
      min-height: 18px;
      font-size: 8px;
      margin-right: 5px;
    }
  }
}
.mat-menu-custom-width {
  &.mat-menu-panel {
    max-width: 100%;
  }
}

// Add Status Styles
.mub-add-status {
  .note-color {
    .custom-color-radiobutton {
      .mat-radio-container {
        .mat-radio-outer-circle {
          &::after {
            left: -4px;
            top: -4;
            height: 40px;
            width: 40px;
          }
        }
        .mat-radio-inner-circle {
          top: 0;
          left: 0;
        }
      }
    }
  }
}
ul.add-edit-text-top-action li img {
  height: 1.2em;
  width: auto;
}
