@import "./custom-variable";

// Notigfication Screens
.ctm-select-all.select-all {
  .mat-option {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: #212121;
  }
}

.filter-notification-page-ctm {
  padding: 74px 30px 0px 0px !important;

  &.filter-notification-page-ctm-parent {
    padding: 0px 30px 5px 0px !important;
    margin-bottom: 20px;
    position: relative;
  }

  .scrollWindow {
    height: calc(100vh - 220px) !important;
  }

  &.mub-filter-notification {
    .mat-card {
      .notification-list-card {
        .card-user-information {
          .card-profile-title {
            .card-profile-img {
              .online--status {
                bottom: 2px;
                right: 13px;
              }
            }
          }
        }
      }
    }
  }

  .mat-menu-trigger.custom-input-btn {
    position: relative;
    top: 1px;
  }

  .notification-top-head {
    margin-left: 36px;
  }

  .notification-right-content {
    .mat-select {
      margin-right: 12px;

      .mat-select-trigger {
        background: #fafafa;
        border: 1px solid #eeeeee;
        border-radius: 8px;
        height: 40px;
        padding: 12px;

        .mat-select-value {
          font-style: normal;
          font-weight: 600;
          font-size: 11px;
          line-height: 16px;
          letter-spacing: 0.005em;
          color: #757575 !important;
        }

        .mat-select-placeholder {
          color: #757575 !important;
        }
      }
    }
  }

  .notification-wrp-content {
    .mat-tab-header {
      margin-bottom: 18px;
      margin-left: 36px;
    }

    .mat-tab-list {
      .mat-tab-labels {
        .mat-tab-label {
          .mat-tab-label-content {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.005em;
            color: #9e9e9e;
          }

          &.mat-tab-label-active .mat-tab-label-content {
            font-weight: 700;
            color: #212121;
            opacity: 1;
          }
        }
      }
    }

    .mat-tab-body-wrapper {
      padding-left: 36px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 15px;
        border-left: 1px dashed #bdbdbd;
        height: 100%;
        border-block-start-width: 26px;
        border-width: 1px;
      }
    }
  }

  .mat-card {
    width: 97%;
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 8px;
    box-shadow: none !important;
    margin-bottom: 10px;
    margin-top: 0 !important;
    margin-left: 36px;

    .notification-list-card {
      .card-title-ctm {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        &::before {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          border: 2px solid #f5f5f5;
          background-color: #16b975;
          left: -27px;
          top: 45%;
          border-radius: 50%;
        }

        &::after {
          content: "";
          position: absolute;
          left: -22px;
          border-left: 1px dashed #bdbdbd;
          height: 150%;
          border-block-start-width: 26px;
          border-width: 1px;
          z-index: -1;
        }

        .inside-card-title {
          display: flex;
          align-items: center;

          p {
            font-weight: 700;
            font-size: 12px;
            line-height: 18px;
            text-align: right;
            letter-spacing: 0.005em;
            color: #2a5fb0;
            padding: 4px 13px;
            background: #ffffff;
            border: 1px solid #2a5fb0;
            border-radius: 4px;
            margin-right: 8px;
          }
        }

        .card-company-name {
          p {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.005em;
            color: #616161;
            text-transform: capitalize;
          }
        }
      }

      .card-user-information {
        .card-profile-title {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .card-profile-img {
            display: flex;
            align-items: center;

            .yellow-notification-alert {
              background: #ffffff;
              border: 1px solid #f2793d;
              border-radius: 4px;
              font-weight: 600;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0.005em;
              color: #f2793d;
              margin-right: 5px;
              padding: 2px 8px;
            }

            .app-five {
              img {
                margin-right: 0;
              }
            }

            img {
              width: 46px;
              height: 46px;
              object-fit: cover;
              border-radius: 50%;
              margin-right: 11px;
            }

            p {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
              letter-spacing: 0.005em;
              color: #212121;
            }

            .user-short-name {
              width: 46px;
              height: 46px;
              object-fit: contain;
              border-radius: 50%;
              margin-right: 11px;
            }

            .online--status {
              background: #fab222;
              border: none;
              display: inline-block;
              height: 8px;
              width: 8px;
              border-radius: 50%;
              bottom: 0px;
              right: 23px;
              position: absolute;

              &.default-active-status {
                background: green;
              }
            }
          }

          .card-date-ctm {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.005em;
            color: #9e9e9e;
          }
        }
      }
    }
  }
}

.notification-cal-ctm {
  overflow: hidden !important;

  .calendar.right {
    display: none !important;
  }

  .notification-filter-date {
    padding: 9px 0 13px;

    span {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.005em;
      color: #616161;
    }
  }

  .md-drppicker {
    box-shadow: none;
    border-bottom: 1px solid #eeeeee;
    border-radius: inherit;
  }

  .border-top {
    padding: 17px 15px 30px;
  }

  .md-drppicker td.end-date:hover {
    border-radius: 0em 2em 2em 0em !important;
  }

  .md-drppicker td.start-date:hover {
    border-radius: 2em 0em 0em 2em !important;
  }

  .md-drppicker td.in-range {
    background-color: #edf3fd;

    &:hover {
      border-radius: 0;
    }
  }

  .md-drppicker td.today:hover {
    border-radius: 50px !important;
  }
}

.cust-class .md-drppicker td.start-date.end-date {
  border-radius: 50px !important;
}

.cust-class .md-drppicker td.active,
.md-drppicker td.active:hover {
  background-color: #276ef1 !important;
  border-radius: 50px !important;
}

.cust-class .md-drppicker .calendar td {
  min-width: 36px !important;
  height: 36px !important;
}

.cust-class .md-drppicker td {
  opacity: unset !important;
  border: none !important;
}



.ctm-select-all {
  overflow-y: scroll;
  max-height: 205px;
  max-width: 100%;
}

/* width */
.ctm-select-all::-webkit-scrollbar {
  width: 8.5px;
}

/* Track */
.ctm-select-all::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #eeeeee !important;
  border-radius: 8.5px !important;
}

/* Handle */
.ctm-select-all::-webkit-scrollbar-thumb {
  background: #e0e0e0 !important;
  border-radius: 8.5px !important;
}

/* Handle on hover */
.ctm-select-all::-webkit-scrollbar-thumb:hover {
  background: #e0e0e0;
}

.custom-sepacing {
  margin: 0 16px !important;
  padding: 10px 8px 10px 16px !important;
}

.checkbox-list-block-notification {
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: space-between;
  padding: 12px 10px 12px 30px;

  .checkbox-styles {
    display: inline-block !important;
    width: 18px;
    height: 26px;
  }
}