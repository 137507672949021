.chat-user-details {
  display: flex;
  align-items: center;
  .short-name {
    margin-right: 16px;
  }
  h4 {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #212121;
    margin-bottom: 0;
  }
  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.005em;
    color: #9e9e9e;
    margin-bottom: 0;
  }
}
.short-name {
  background: #fab222;
  border: 1px solid #ffffff;
  max-height: 34px;
  max-width: 34px;
  min-width: 34px;
  min-height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #ffffff;
  border-radius: 50%;
}
.chat-box-card {
  .mat-card-header {
    padding: 20px 24px;
    flex-direction: column;
    .mat-card-header-text {
      margin: 0;
    }
  }
  .mat-card-content {
    padding: 0 24px;
    flex: 1 1 auto;
    overflow: auto;
  }
  height: calc(90vh - 88px) !important;
}
.chat-message-list {
  > li {
    display: flex;
    align-items: flex-end;
    margin-bottom: 24px;
    .chat-msg {
      margin-left: 4px;
      display: flex;
      flex-direction: column;
      .chat-msg-content {
        background: #eeeeee;
        border-radius: 0px 8px 8px 0px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.005em;
        color: #212121;
        padding: 8px;
        margin-bottom: 4px;
        width: fit-content;
        &:first-child {
          border-radius: 8px 8px 8px 0px;
        }
        &:last-of-type {
          border-radius: 0px 8px 8px 8px;
        }
      }
      div:last-child {
        border-radius: 0px 8px 8px 8px;
      }
      p {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.005em;
        color: #616161;
      }
    }
    &.sender-msg {
      justify-content: flex-end;
      .chat-msg {
        text-align: right;
        align-items: flex-end;
        .chat-msg-content {
          background: #edf3fd;
          border-radius: 8px 0px 0px 8px;
          text-align: right;
          color: #1d53b7;
          &:first-child {
            border-radius: 8px 8px 0px 8px;
          }
          &:last-of-type {
            border-radius: 8px 0px 8px 8px;
          }
        }
      }
    }
  }
}
.mat-card-actions.chat-msg-send-action-panel {
  border-top: 1px solid #eeeeee;
  height: 74px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  app-comment-editor {
    width: 100%;
    max-height: 250px;
    overflow-y: auto;
  }
  .chat-content {
    column-gap: 10px;
    .quil-editor-box {
      flex: 1 1 auto;
    }
  }

  .textarea-emoji-control {
    right: auto;
    left: 10px;
    top: 8px;
  }
  .ql-container.ql-snow {
    border: 0;
  }
  .ql-editor {
    padding-left: 35px;
  }
  .ql-editor.ql-blank:before {
    left: 38px;
  }
  #textarea-emoji {
    right: unset;
  }
  .common-quill-editor {
    &.math_ui_block-common-quill-editor {
      .chat-content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .quill-editor-controls {
          padding: 6px 0;
          position: absolute;
          right: 0;
        }
      }
      li.emoji-tab.active {
        background: transparent !important;
        border: 0px;
      }
    }
  }
  .text-truncate {
    width: 130px;
  }
}

.right--block.mub-dms-comment-block-add-edit {
  .add-edit-task-main-block
    .right--block
    .add-task-rght-block-tab
    .mat-tab-body {
    height: 100% !important;
  }
}

// Checklist

.mub-dms-checklist-comment-block {
  .chat-room-modal-cls .msgContentBox {
    max-height: 460px !important;
    padding-bottom: 0px !important;
  }
  app-chat-room .dms-content-block {
    height: 565px;
  }
}

// Small one

.dms-content-block.mub-dms-content-block.mub-dms-small-modal {
  .ql-mention-list-container.ql-mention-list-container-bottom {
    top: unset !important;
    bottom: 38px !important;
  }
  .ql-mention-list-container.ql-mention-list-container-top {
    top: unset !important;
    bottom: 38px !important;
    height: 240px !important;
  }
}

// For Task List Small Modal
.task-list-tab-comment-editor {
  .small-quill {
    .chat-content {
      max-width: 76%;
    }
  }
}

//In Common Editor Checklist Comment Editor
.checklist-comment-editor {
  .checklist-common-quill {
    .chat-content {
      max-width: 81%;
    }
  }
}

// Discussion Comment Editor
.discussion-comment-editor {
  .discussion-common-quill {
    .chat-content {
      max-width: 93%;
    }
  }
}

// Common Right Comment Editor
.common-right-comment-editor {
  .common-quill-editor {
    .chat-content {
      max-width: 85%;
    }
  }
}
