.log-activity-list {
  margin-bottom: 0;

  > li {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    time {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.005em;
      color: #444;
      white-space: nowrap;
    }

    > .user-short-name {
      margin-top: -3px;
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      margin-right: 1.3em !important;
    }

    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.005em;
      color: #222;
      margin-bottom: 0;
    }

    &:before {
      content: "";
      height: 100%;
      width: 0px;
      top: 26px;
      position: absolute;
      left: 20px;
      background: #9e9e9e;
      border: 1px solid #e0e0e0;
    }

    &:last-child::before {
      display: none;
    }
  }
}

.mat-time-pciker {
  &.mat-form-field-appearance-outline {
    .mat-form-field-infix {
      padding: 5px 0;
    }

    .mat-form-field-label {
      margin-top: -15px;
    }
  }

  &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-label,
  &.mat-form-field-appearance-outline.mat-form-field-can-float
    .mat-input-server:focus
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    transform: translateY(-15px) scale(0.75);
  }
}

.title-w-divider {
  margin: 0 auto;
  background: #fff;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.005em;
}
