.auth-main-content {
  height: 100vh;
  display: flex;
  .left-block,
  .right-block {
    flex: 1 1 50%;
    max-width: 50%;
  }
  .right-block {
    background: #276ef1 url("../images/login-slide-bg.svg") no-repeat;
    background-size: cover;
  }
  .left-block {
    overflow-y: overlay;
    .overlay-content {
      flex-direction: column;
      min-height: 100vh;
      display: flex;
      padding: 100px 20px;
      justify-content: center;
      position: relative;
      .inner-form-block {
        position: static;
        max-width: 360px;
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        .form-block {
          margin-top: auto;
          margin-bottom: auto;
        }
      }
      .logo-block {
        max-width: 360px;
        margin: 0 auto;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        > img {
          position: absolute;
          top: 30px;
          left: 0;
        }
      }
      .footer-line {
        position: absolute;
        bottom: 20px;
        width: 100%;
        margin: 0 auto;
        left: 0;
        padding: 0 20px;
      }
    }
  }
}

.email-section,
.password-section,
.name-section,
.phone-section {
  border-bottom: 1px solid #eee;
  margin-bottom: 35px;
  input {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    width: 90% !important;
    border: unset;
    padding: 1em;
    &::placeholder {
      color: #9e9e9e;
    }
  }
}
.phone-section {
  > img {
    width: 20px;
    height: 20px;
  }
}

.align-login-btn {
  margin-top: 28px;
}
.sign-up-line,
.back-to-login-line {
  margin-top: 24px;
}

.footer-line {
  position: relative;
  p {
    left: 0;
    right: 0;
    margin: 0 auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #9e9e9e;
    bottom: 24px;
    word-spacing: 7px;
  }
}

.login-desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #616161;
}

.dt-block {
  width: 360px;
  display: flex !important;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  row-gap: 20px;
  flex-direction: column;
  p {
    &.font-24 {
      line-height: 36px;
    }
    &.font-16 {
      line-height: 24px;
    }
  }
  .circle {
    height: 200px;
    width: 200px;
    background: #1d53b7;
    border-radius: 50%;
  }
}

.auth-m-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.0015em;
  color: #212121;
}
.login-desc-sm {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.005em;
  color: #616161;
}
.lottie-animation {
  height: 128px;
  width: 128px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.botom-action-line {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.005em;
  color: #9e9e9e;
  text-align: center;
  margin-top: 24px;
  span {
    font-weight: 700;
    color: hsla(219, 88%, 55%, 1);
    cursor: pointer;
  }
}
