@import "./custom-variable";

// Align Styles

$text-align-left: left;
$text-align-center: center;
$text-align-right: right;

@mixin common-text($size,
  $font-weight,
  $line-height,
  $letter-spacing,
  $text-align-left,
  $color: null) {
  font-size: $size;
  font-weight: $font-weight;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  text-align: $text-align-left;
  color: $color;
}

// Padding/margin mixin
@mixin padding($top: null, $right: null, $bottom: null, $left: null) {
  padding-top: $top;
  padding-right: $right;
  padding-bottom: $bottom;
  padding-left: $left;
}

// Padding/margin mixin
@mixin margin($top: null, $right: null, $bottom: null, $left: null) {
  margin-top: $top;
  margin-right: $right;
  margin-bottom: $bottom;
  margin-left: $left;
}

.mub-reports {
  h1 {
    @include common-text($font-size-24,
      500,
      36px,
      0.0015em,
      $text-align-left,
      $color: $text-dark-black-color );
  }

  .example-card {
    width: 100%;
    height: 162px;
    border-radius: 24px;
    line-height: 80px;
    box-shadow: none !important;

    p {
      @include common-text($font-size-18,
        700,
        22px,
        0.0025em,
        $text-align-left,
        $color: #1d53b7);
    }

    span {
      display: inline-block;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      text-align: center;
      line-height: 47px;
      margin-bottom: 10px;
    }

    &.bg-color {
      background-origin: border-box;
      background-position-x: right;
      background-repeat: no-repeat;

      &.blue {
        background-image: url("../images/blue-image.svg"),
          linear-gradient(to right, #9fbff833, #9fbff833);

        span {
          background-color: #1d53b7;
        }

        p {
          color: #12945e;
        }
      }

      &.green {
        background-image: url("../images/green-image.svg"),
          linear-gradient(to right, #45c79133, #45c79133);

        span {
          background-color: #12945e;
        }

        p {
          color: #12945e;
        }
      }

      &.red {
        background-image: url("../images/red-image.svg"),
          linear-gradient(to right, #dc353533, #dc353533);

        span {
          background-color: #dc3535;
        }

        p {
          color: #dc3535;
        }
      }

      &.yellow {
        background-image: url("../images/yellow-image.svg"),
          linear-gradient(to right, #fbc14e33, #fbc14e33);

        span {
          background-color: #c88e1b;
        }

        p {
          color: #c88e1b;
        }
      }

      &.peach {
        background-image: url("../images/peach-image.svg"),
          linear-gradient(to right, #f7af8b33, #f7af8b33);

        span {
          background-color: #c26131;
        }

        p {
          color: #c26131;
        }
      }

      &.violet {
        background-image: url("../images/violet-image.svg"),
          linear-gradient(to right, #8c7de933, #8c7de933);

        span {
          background-color: #594ab6;
        }

        p {
          color: #594ab6;
        }
      }

      &.teal {
        background-image: url("../images/teal-image.svg"),
          linear-gradient(to right, #148e9633, #148e9633);

        span {
          background-color: #0f6a70;
        }

        p {
          color: #0f6a70;
        }
      }
    }
  }
}

// Report Timelog Common Header Styles

.mub-time-log-common-header {
  .company-details-title {
    display: flex;
    align-items: center;

    h2 {
      @include common-text($font-size-24,
        500,
        36px,
        0.0015em,
        $text-align-left,
        $color: #212121);
      @include margin($bottom: 0);

      span.title-date {
        color: #616161;
      }
    }
  }

  .calendar-title {
    button {
      @include common-text($font-size-12,
        500,
        18px,
        0.005em,
        $text-align-center,
        $color: #616161);
      @include padding($top: 3px, $right: 8px, $bottom: 3px, $left: 8px);
      border: 1px solid #eee;
      background: #fff;

      &:hover,
      &.active {
        color: #276ef1 !important;
        background-color: #edf3fd !important;
        border: 1px solid #276ef1;
      }

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }

    &.report-other-title {
      button {
        display: flex;
        align-items: center;
        padding: 6px 8px;
        border: 0px solid transparent;
        background: transparent;
        font-weight: 700;
        color: #276ef1;

        &:last-child {
          @include padding($left: 0px, $right: 0px);
          @include margin($left: 20px);
        }
      }

      img {
        @include margin($left: 10px);
      }
    }

    &.over-view-button {
      padding: 0;
    }
  }

  .search-button {
    position: relative;
    top: 1px;

    button {
      border-radius: 0 4px 4px 0;
      border: 0px solid;
      text-align: center;
      background-color: #276ef1;
      @include padding($top: 9px, $right: 32px, $bottom: 9px, $left: 32px);
      @include common-text($font-size-14,
        500,
        22px,
        0.005em,
        $text-align-center,
        $color: #ffffff);
    }
  }

  .search-dropdown {
    display: flex;
    align-items: center;

    .select-custom {
      width: 175px;
      border: 1px solid #eee;
      display: flex;
      justify-content: space-between;
      padding: 5px 13px;
      margin: 0;
      height: 40px;
      align-items: center;
      position: relative;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      top: 1px;

      &.second {
        border-radius: 0px;

        .typo-text {
          color: #276ef1;

          &.typo-prod {
            color: #616161;
          }
        }
      }

      &.third {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      .typo-text {
        @include common-text($font-size-14,
          500,
          22px,
          0.005em,
          $text-align-left,
          $color: #9e9e9e);
      }

      .over-view-cal-btn {
        background: transparent;
        border: 0;
      }

      .mat-select-arrow {
        color: #9e9e9e;
      }
    }

    mat-select {
      .mat-select-min-line {
        position: relative;
        top: -1px;
        @include common-text($font-size-14,
          500,
          22px,
          0.005em,
          $text-align-left,
          $color: #616161);
      }
    }
  }

  .main-content-coantainer {
    padding: 55px 20px 12px 20px;
    background-color: #fafafa;
  }

  .search-customize {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .custom-input {
      max-width: 264px;
      height: 32px;
      border-radius: 8px;
      border: 1px solid #eeeeee;
      background: transparent url("./../icons/searchicon.svg") no-repeat;
      background-position: left center;
      background-origin: content-box, padding-box;
      padding: 4px 10px;
      text-indent: 32px;
      background-size: 17px;
      @include margin($right: 5px);
      @include common-text($font-size-14,
        500,
        22px,
        0.005em,
        $text-align-left,
        $color: #9e9e9e);
    }

    .customize-input {
      border: 0px solid transparent;
      background: transparent;
      display: flex;
      align-items: center;
      @include common-text($font-size-12,
        500,
        18px,
        0.005em,
        $text-align-left,
        $color: #9e9e9e);

      img {
        width: 16px;
        height: 16px;
      }

      &:hover {
        color: #9e9e9e !important;
      }
    }
  }
  &.report-header-for-date {
    .search-dropdown {
      .select-custom {
        width: auto;
        max-width: 250px;

        .dash-date {
          display: none;
        }

        .dash-date-show {
          display: inline-block;
          padding: 0 3px;
        }
      }
    }
  }
}

// Common Blank Component Styles
.empty-block-task {
  padding: 0 32px;

  .empty-block-task-inner {
    border-radius: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.color-bg-border {
      background-color: #fff;
      border: 1px solid #eeeeee;

      .no-task-title {
        @include common-text($font-size-14,
          500,
          22px,
          0.005em,
          $text-align-center,
          $color: #9e9e9e);
      }
    }

    &.empty-block-report {
      background-color: #fff;
    }

    &.empty-view-eighty {
      height: calc(100vh - 210px);
      border: 1px solid #eeeeee;
    }
  }
}

// Time Log Graph Styles
.mub-time-log-graph {
  .real-accrodian {
    .mat-expansion-panel-header.mat-expanded {
      height: 56px;
    }

    .mat-expansion-panel-body {
      padding: 0;
      box-shadow: none;

      @media screen and (max-width: 1200px) {
        overflow: auto !important;
      }
    }

    .mat-expansion-panel:not([class*="mat-elevation-z"]) {
      box-shadow: none;
      border: 1px solid #eeeeee;
      border-radius: 8px !important;
      margin-bottom: 15px;
    }

    mat-panel-title.mat-expansion-panel-header-title {
      font-family: Manrope;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0.0015em;
      text-align: left;
      color: #000;
    }

    table {
      &.mat-elevation-z8 {
        box-shadow: none;
      }

      thead {
        tr {
          th {
            background-color: #fafafa;
            border-bottom: 0px solid;
            @include common-text($font-size-14,
              500,
              22px,
              0.005em,
              $text-align-left,
              $color: #9e9e9e);
          }

          &.mat-header-row {
            height: 40px;
          }
        }
      }

      tbody {
        tr {
          &.mat-header-row {
            height: 56px;
          }
          &.mat-row:last-child {
            td {
              border-bottom: 1px solid #eee;
            }
          }
        }
      }

      .custom-spacing {
        @include common-text($font-size-14,
          500,
          22px,
          0.005em,
          $text-align-left,
          $color: #212121 !important);
      }

      @media screen and (max-width: 1200px) {
        width: 100%;
        min-width: calc(100vw - -10px);
        overflow-x: auto;
      }

      .mat-cell,
      .mat-footer-cell {
        color: #9e9e9e;
      }
    }
  }

  // Report Graphs
  .inside-block {
    border: 1px solid #eeeeee;
    margin: 0 20px;
    background-color: #fff;
    padding: 16px 22px;
    border-radius: 8px;
    margin-bottom: 15px;

    .block-image {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;

      img {
        &.custom-wh {
          min-width: 38px;
          min-height: 38px;
          height: 38px;
          width: 38px;
        }
      }

      p {
        text-transform: capitalize;
        @include common-text($font-size-16,
          700,
          24px,
          0.0025em,
          $text-align-left,
          $color: #212121);
      }
    }

    .block-status {
      .over-chart-list-view {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;

        .over-view-chart-list {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 2px;

          p {
            text-transform: capitalize;
            @include common-text($font-size-12,
              500,
              18px,
              0.005em,
              $text-align-right,
              $color: #616161);
          }
        }
      }
    }

    .real-time-graph {
      margin-top: 25px;
    }
  }
}

// Reports Table

.reports-table {
  overflow: auto;

  .rt-width {
    width: 100%;
  }

  table {
    thead {
      tr {
        th {
          position: sticky;
          top: 0;
          z-index: 1;
          border-right: 1px solid #eee;
          padding: 10px 12px;

          &:first-of-type {
            padding-left: 24px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          border-right: 1px solid #eee;
          padding: 10px 12px;
        }
      }
    }
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f1f5 !important;
    z-index: 0;
    border-radius: 0px;
    overflow: auto;
    border: 0px solid #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    z-index: 0;
    border-radius: 10px;
    overflow: auto;
    border: 5px solid #f5f1f5;
  }

  .math_ui-onHover-sub-block {
    &.new-position {
      top: 40px;
      left: -47px;
    }
  }
}

// Report Drawer
.mat-drawer {
  &.customize--drawer {
    &.report-drawer {
      height: calc(100vh - 60px) !important;
      top: 60px !important;

      .mat-drawer-inner-container {
        padding-right: 20px;

        &::-webkit-scrollbar-track {
          background-color: #fff !important;
          z-index: 0;
          border-radius: 10px;
          overflow: auto;
          border: 10px solid #fff !important;
        }

        &::-webkit-scrollbar {
          width: 4px;
          background-color: #fafafa !important;
          z-index: 0;
          border-radius: 10px;
          overflow: auto;
          border: 31px solid #fafafa;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #ccc;
          z-index: 0;
          border-radius: 14px;
          overflow: auto;
          border: 6px solid #ccc;
        }
      }
    }

    .cross-icon-drawer {
      width: 12px;
      min-width: 12px;
      height: 12px;
      min-height: 12px;
      margin: 6px 20px 6px 6px;
      cursor: pointer;
    }
  }
}

// Report Time Sheet

.report-time-sheet {
  .report-sheet-card {
    border: 1px solid #eeeeee;
    margin: 0 20px;
    background-color: #fff;
    padding: 16px 18px 20px 18px;
    border-radius: 8px;
    margin-bottom: 15px;

    h4 {
      &.text-typo {
        &.typo-report {
          @include common-text($font-size-18,
            700,
            28px,
            0.0025em,
            $text-align-left );
        }

        &.dark-typo {
          color: #212121;
        }
      }

      .light-typo {
        color: #9e9e9e;
      }

      margin-bottom: 25px;
      margin-top: 5px;
    }

    .report-card-main-block {
      mat-card-content {
        &.card-content-styling {
          width: 100%;
        }
      }

      .over-view-card-task {
        &.report-view-card-task {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
          align-content: space-around;
          width: 100%;
        }

        .over-view-card-task-title {
          &.report-view-card-task-title {
            display: flex;
            flex-direction: column;

            p {
              @include common-text($font-size-14,
                700,
                22px,
                0.0025em,
                $text-align-left );
              color: #616161;
              text-transform: capitalize;
              margin-bottom: 8px;
            }

            h2 {
              @include common-text($font-size-32,
                800,
                30px,
                0em,
                $text-align-left );
              color: #212121;
              text-transform: capitalize;
              margin-bottom: 0;

              &.red-title {
                color: #dc3535;
              }
            }

            .description {
              .time {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 3px;

                p {
                  @include common-text($font-size-13,
                    700,
                    20px,
                    0.0025em,
                    $text-align-left );
                }
              }

              .number {
                &.red-number {
                  color: #dc3535;
                }

                &.yellow-number {
                  color: #fab222;
                }

                &.green-number {
                  color: #16b975;
                }
              }
            }
          }
        }

        .task-list-com {
          &.report-list-com {
            width: 50px;
            height: 50px;
            min-width: 50px;
            min-height: 50px;
            border-radius: 50%;
            text-align: center;
            line-height: 44px;

            &.blue {
              background-color: #276ef1;
            }

            &.purple {
              background-color: #8c7de9;
            }

            &.red {
              background-color: #e54a4a;
            }

            &.green {
              background-color: #19b1bb;
            }
          }
        }
      }

      mat-card {
        &.card-styling {
          border-left: 1px solid #eeeeee;
          border-right: 1px solid #eeeeee;
          border-top: 1px solid #eeeeee;
          box-shadow: none;
          padding: 12px 18px;
          width: 100%;
          display: flex;
          align-items: center;
          border-radius: 6px;

          &.blue-border {
            border-bottom: 3px solid #276ef1;
          }

          &.purple-border {
            border-bottom: 3px solid #8c7de9;
          }

          &.red-border {
            border-bottom: 3px solid #e54a4a;
          }

          &.green-border {
            border-bottom: 3px solid #19b1bb;
          }
        }
      }

      .cstm-padding {
        padding: 0 6px;

        &:first-child {
          padding-left: 12px;
        }

        &:last-child {
          padding-right: 12px;
        }
      }
    }
  }

  .report-sheet-table-title {
    border: 1px solid #eeeeee;
    margin: 0 20px;
    background-color: #fff;
    border-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    .sheet-table-title {
      h2 {
        padding: 15px 20px;
        @include common-text($font-size-24,
          500,
          36px,
          0.0015em,
          $text-align-left,
          $color: #212121);
        margin-bottom: 0px;
      }
    }
  }

  .report-sheet-table {
    table {
      width: 100%;
    }
  }
}

// Report >

// User Report
.user-report-only {
  .heading-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 14px 8px;
  }

  .mub-time-log-common-header {
    .calendar-title {
      button {
        &:hover {
          background-color: transparent !important;
        }
      }
    }
  }
}

// Save Report Component

.main-custom-notes-module {
  &.custom-modal-save-report {
    max-width: 77vh !important;
    min-width: 630px;

    .mat-dialog-container {
      box-shadow: 5px 5px 24px 0px #00000029;
      border-radius: 8px;
    }

    .user-save-report {
      max-height: 430px;

      .container {
        padding: 0;

        .save-report-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #eee;
          padding: 17px 17px 14px 17px;
          margin-bottom: 10px;

          h2 {
            &.title {
              @include common-text($font-size-20,
                700,
                28px,
                0.0025em,
                $text-align-left,
                $color: #212121);
              margin-bottom: 0;
            }
          }

          a {
            &.anchor-cross {
              cursor: pointer;

              img {
                &.cross-icon {
                  width: 12px;
                  height: 12px;
                }
              }
            }
          }
        }

        .save-report-input {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 15px;
          padding: 0 17px;

          input {
            &.input-styles {
              border: 1px solid #eee;
              border-radius: 4px;
              @include common-text($font-size-14,
                500,
                22px,
                0.005em,
                $text-align-left,
                $color: #212121);

              &::placeholder {
                color: #9e9e9e;
              }

              &:focus {
                outline: none;
                box-shadow: none;
              }
            }
          }

          button {
            &.save-button {
              padding: 5px 28px;
              border-radius: 4px;
              @include common-text($font-size-14,
                600,
                22px,
                0.005em,
                $text-align-left,
                $color: #fff);
            }
          }
        }

        .save-report-table {
          overflow: auto;
          .save-report-table-inside {
            thead {
              th {
                background-color: #fafafa;
                position: sticky;
                top: 0;
                @include common-text($font-size-12,
                  600,
                  18px,
                  0.005em,
                  $text-align-left,
                  $color: #9e9e9e);
              }
            }

            tbody {
              tr {
                td {
                  @include common-text($font-size-14,
                    500,
                    22px,
                    0.005em,
                    $text-align-left );
                  color: #9e9e9e;

                  &.cdk-column-report_name {
                    color: #212121;
                  }

                  &.cdk-column-action {
                    a {
                      cursor: pointer;

                      &:nth-child(1) {
                        img {
                          width: 19px;
                          margin-right: 2px;
                        }
                      }

                      &:nth-child(2) {
                        img {
                          width: 16px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &::-webkit-scrollbar-thumb {
            background-color: #fff;
            z-index: 0;
            border-radius: 10px;
            overflow: auto;
          }

          &::-webkit-scrollbar-track {
            background-color: #fff !important;
            z-index: 0;
            border-radius: 10px;
            overflow: auto;
          }
        }
      }

      .report-title-input {
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 12345;
        padding-bottom: 10px;
      }

      .save-report-table {
        position: relative;
        z-index: 1234;
        margin: 0;
      }
    }
  }
}

// Common Report Bg Color
.common-gray-color {
  background-color: #fafafa;
  height: 100%;
}

// User Performance Report
.user-performance-block {
  .custom-height-user-performance {
    .reports-table {
      &.performance-table {
        max-height: 855px;

        table {
          box-shadow: none;

          thead {
            tr {
              th {
                background-color: #fff;
              }
            }
          }
        }

        &:-webkit-scrollbar-thumb {
          background-color: #fff !important;
          z-index: 0;
          border-radius: 10px;
          overflow: auto;
          border: 20px solid #fff !important;
        }

        &::-webkit-scrollbar-track {
          background-color: #fff !important;
          z-index: 0;
          border-radius: 10px;
          overflow: auto;
          border: 20px solid #fff !important;
        }
      }
    }
  }
  .mat-expansion-panel {
    &:not([class*="mat-elevation-z"]) {
      box-shadow: none;
    }
  }

  .mat-expansion-panel:not(.mat-expanded) {
    .mat-expansion-panel-header {
      &:hover {
        &:not([aria-disabled="true"]) {
          background-color: transparent;
        }
      }
    }
  }

  .mat-expansion-panel-header {
    &.mat-expanded {
      height: 48px;
    }
  }
}

// Time-log-Table-Reuse
.time-loggraphtablereuse {
  .table-resusable-module {
    &.more-table-scroll {
      height: 100%;
    }
  }
}

// table-resusable Module
.table-resusable-module {
  .badge.caret {
    &.arrow-none {
      padding-right: 6px;

      &::after {
        content: none;
      }
    }
  }

  table {
    thead {
      tr {
        &.mat-header-row {
          height: 28px;
        }

        th {
          width: 211px;
          min-width: 211px;
          background-color: #fafafa !important;
          border-bottom: 0px solid;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0.005em;
          text-align: left;
          color: #9e9e9e;

          &.mat-column-priority {
            max-width: 100px;
            min-width: 100px !important;
            width: 100px !important;
          }

          &.mat-column-task_name {
            width: 511px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          width: 211px;
          min-width: 211px;

          &.mat-column-priority {
            max-width: 100px;
            min-width: 100px !important;
            width: 100px !important;
          }

          &.mat-column-task_name {
            width: 511px;
            min-width: 511px;
          }

          .custom--status-menu-btn {
            max-width: 20px;
          }
        }
      }
    }
  }

  .countdown-content {
    .progressed {
      position: relative;
      width: 33px;

      .countdown {
        position: absolute;
        left: 56%;
        top: 54%;
        transform: translate(-54%, -57%);
        font-size: 8px;
        font-weight: 700;
        line-height: 8px;
        letter-spacing: 0.005em;
        text-align: center;
        color: #757575;
      }
    }
  }

  &.more-table-scroll {
    max-height: calc(100vh - 472px);
    overflow: auto;

    &::-webkit-scrollbar-track {
      background-color: #fff !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #fafafa !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
      border: 31px solid #fafafa;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      z-index: 0;
      border-radius: 14px;
      overflow: auto;
      border: 6px solid #fff;
    }
  }
}

// Time log Graph For Name
.time-log-graph-name {
  .user-short-names {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f2793d;
    border: 1px solid #ffffff;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.005em;
    color: #fff;
    height: 34px;
    width: 34px;
    min-height: 34px;
    min-width: 34px;
    border-radius: 50%;
  }
}

//
.status-color {
  width: 8px;
  height: 8px;
  border-radius: 2px;
  margin-right: 5px;
  display: inline-block;
}

// Pie Chart Styles
.slick-only-pie {
  .mat-card {
    width: 300px !important;
    height: 304px;
    min-width: 280px;
  }

  .slick-track {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: row;
  }

  #responsive-canvas {
    width: 100%;
  }
}

.status-report {
  canvas {
    height: 440px !important;
    width: 100% !important;
  }

  .card-containner {
    max-height: 435px;
  }
}

// User Report Table Styles
.user-report-table-only {
  .user-report-table-inner-only {
    .card-containner {
      &.user-report-table-independent {
        .card-header {
          .report-name {
            font-family: Manrope;
            font-size: 24px;
            font-weight: 500 !important;
            line-height: 36px;
            letter-spacing: 0.0015em !important;
            text-align: left;
          }
        }

        .search-customize {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .custom-input {
            max-width: 264px;
            height: 32px;
            border-radius: 8px;
            border: 1px solid #eeeeee !important;
            background-position: left center;
            background-origin: content-box, padding-box;
            padding: 4px 10px;
            text-indent: 32px;
            background-size: 17px;
            margin-right: 5px;
            @include common-text($font-size-14,
              500,
              22px,
              0.005em,
              $text-align-left,
              $color: #9e9e9e);

            &.custom-input-design {
              border: 1px solid #aaa !important;
            }
          }

          .customize-input {
            border: 0px solid transparent;
            background: transparent;
            display: flex;
            align-items: center;
            @include common-text($font-size-12,
              500,
              18px,
              0.005em,
              $text-align-left,
              $color: #9e9e9e);

            span.mat-button-focus-overlay {
              display: none;
            }
          }
        }

        .mat-tab-table-block {
          .mat-tab-header {
            margin-bottom: 15px;

            .mat-tab-list {
              .mat-tab-labels {
                .mat-tab-label {
                  padding: 0 15px;
                  min-width: 100px;

                  .mat-tab-label-content {
                    @include common-text($font-size-14,
                      500,
                      22px,
                      0.005em,
                      $text-align-left );
                    text-transform: capitalize;
                  }

                  &.mat-tab-label-active {
                    .mat-tab-label-content {
                      font-weight: 700;
                      color: #212121;
                      opacity: 1 !important;
                    }
                  }
                }
              }
            }
          }

          .mat-tab-body-wrapper {
            .status-report-only {
              overflow: auto;
              width: calc(100vh - -80px);
              display: flex;
              flex-wrap: nowrap;
              align-items: center;
              flex-direction: row;
              padding-bottom: 12px;
              max-width: 100%;

              .status-badge {
                min-width: 76px;
                padding: 3px 8px;
                border-radius: 4px;
                margin-right: 5px;

                .status-title {
                  @include common-text($font-size-11,
                    600,
                    16px,
                    0.005em,
                    $text-align-center );
                }
              }

              &::-webkit-scrollbar-track {
                background-color: #fff !important;
                z-index: 0;
                border-radius: 10px;
                overflow: auto;
                border: 10px solid #fff !important;
              }

              &::-webkit-scrollbar {
                width: 4px;
                background-color: #fafafa !important;
                z-index: 0;
                border-radius: 10px;
                overflow: auto;
                border: 31px solid #fafafa;
              }

              &::-webkit-scrollbar-thumb {
                background-color: #ccc;
                z-index: 0;
                border-radius: 14px;
                overflow: auto;
                border: 6px solid #fff;
              }

              &.activity-report-status {
                width: calc(100vh + 47em);
              }
            }

            .status-content-icon {
              display: flex;
              justify-content: flex-end;

              .status-icon-content {
                width: fit-content;
                display: flex;
                align-items: flex-end;
                flex-direction: row;
                gap: 7px;

                .icon-text {
                  @include common-text($font-size-11,
                    600,
                    16px,
                    0.005em,
                    $text-align-center );
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  gap: 5px;
                  min-width: 93px;
                  justify-content: space-evenly;
                }
              }
            }

            .mat-tab-body-content {
              overflow: unset;
            }

            .mat-tab-body.mat-tab-body-active {
              overflow: unset !important;
            }
          }

          .user-report-table {
            .reports-table {
              &.user-report-table-only {
                table {
                  thead {
                    th {
                      background-color: #fafafa;
                      min-width: 211px;

                      &.mat-column-task_name {
                        min-width: 33em !important;
                      }
                    }
                  }

                  tbody {
                    tr {
                      td {
                        min-width: 211px;

                        &.mat-column-task_name {
                          min-width: 33em !important;
                        }
                      }
                    }
                  }
                }

                &::-webkit-scrollbar-track {
                  background-color: #fff !important;
                  z-index: 0;
                  border-radius: 10px;
                  overflow: auto;
                  border: 10px solid #fff !important;
                }

                &::-webkit-scrollbar {
                  width: 4px;
                  background-color: #fafafa !important;
                  z-index: 0;
                  border-radius: 10px;
                  overflow: auto;
                  border: 31px solid #fafafa;
                }

                &::-webkit-scrollbar-thumb {
                  background-color: #ccc;
                  z-index: 0;
                  border-radius: 14px;
                  overflow: auto;
                  border: 6px solid #fff;
                }
              }
            }
          }

          .only-table-more {
            overflow: auto;
            max-width: 100%;
          }

          .nomore-only-table-more {
            width: 100%;
          }
        }

        &.only-overflow {
          .mat-tab-body-wrapper {
            display: block;

            &::-webkit-scrollbar-track {
              background-color: #fff !important;
              z-index: 0;
              border-radius: 10px;
              overflow: auto;
              border: 10px solid #fff !important;
            }

            &::-webkit-scrollbar {
              width: 4px;
              background-color: #fafafa !important;
              z-index: 0;
              border-radius: 10px;
              overflow: auto;
              border: 31px solid #fafafa;
            }

            &::-webkit-scrollbar-thumb {
              background-color: #ccc;
              z-index: 0;
              border-radius: 14px;
              overflow: auto;
              border: 6px solid #fff;
            }
          }
        }
      }
    }
  }
}

// Activity-report Block Styles

.activity-report-block {
  .card-containner {
    .card-sub-header {
      .status {
        .status-color {
          min-width: 8px;
          min-height: 8px;
          margin-right: 10px !important;
        }

        .status-name {
          text-align: left !important;
          min-width: fit-content;
          white-space: nowrap;
          margin-right: 5px;
        }
      }
    }
  }

  .card-sub-header {
    overflow-x: auto;

    &::-webkit-scrollbar-track {
      background-color: #fff !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
      border: 10px solid #fff !important;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #fafafa !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
      border: 31px solid #fafafa;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      z-index: 0;
      border-radius: 14px;
      overflow: auto;
      border: 6px solid #fff;
    }

    .status {
      display: flex;
      margin-top: 16px;
      align-items: center;
      flex-wrap: nowrap;
    }
  }

  .mat-expansion-panel {
    &:not([class*="mat-elevation-z"]) {
      box-shadow: none;
    }
  }

  .mat-expansion-panel:not(.mat-expanded) {
    .mat-expansion-panel-header {
      &:hover {
        &:not([aria-disabled="true"]) {
          background-color: transparent;
        }
      }
    }
  }

  .mat-expansion-panel-header {
    &.mat-expanded {
      height: 48px;
    }
  }
}

// Status Scroll Styles
.status-scroll-only {
  .card-containner {
    .card-sub-header {
      .status {
        .status-color {
          min-width: 8px;
          min-height: 8px;
          margin-right: 10px !important;
        }

        .status-name {
          text-align: left !important;
          min-width: fit-content;
          white-space: nowrap;
          margin-right: 5px;
        }
      }
    }
  }

  .card-sub-header {
    overflow-x: auto;

    &::-webkit-scrollbar-track {
      background-color: #fff !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
      border: 10px solid #fff !important;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #fafafa !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
      border: 31px solid #fafafa;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      z-index: 0;
      border-radius: 14px;
      overflow: auto;
      border: 6px solid #fff;
    }

    .status {
      display: flex;
      margin-top: 16px;
      align-items: center;
      flex-wrap: nowrap;
    }
  }

  &.status-card-container-padding {
    .card-containner {
      padding-bottom: 25px;
    }
  }
}

// Activity Pie Chart
.activity-pie-chart {
  .activity-chart-card {
    border: 0px solid transparent;
    margin: 0 auto;

    canvas {
      border: 0px solid;
    }
  }
}

// Activiey Bar Chart
.activity-bar-chart {
  .height-auto {
    height: auto;
  }
}

// Common Header Report Dropdown List
.common-header-projectwise,
.common-header-usertype,
.common-header-selectstatus {
  max-width: 272px !important;
  min-width: 272px !important;
  box-shadow: 5px 5px 24px 0px #21212129;
}
.common-header-selectuser {
  max-width: 282px !important;
  min-width: 282px !important;
  box-shadow: 5px 5px 24px 0px #21212129;
  max-height: 342px !important;
  position: relative;

  .common-searchBar {
    position: sticky;
    top: 0;
    background-color: #fff;
    padding: 7px 15px 7px 15px !important;
    z-index: 12;
  }

  .common-header-selectuser-list {
    max-height: 270px;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      background-color: #fff !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
      border: 10px solid #fff !important;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #fafafa !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
      border: 31px solid #fafafa;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      z-index: 0;
      border-radius: 14px;
      overflow: auto;
      border: 6px solid #ccc;
    }

    .margin-left-only {
      margin-left: 0px;
    }

    .list-name {
      margin-left: 10px;
    }
  }
}

.common-header-selectstatus {
  max-height: 302px !important;
  position: relative;

  .common-searchBar {
    position: sticky;
    top: 0;
    background-color: #fff;
    padding: 7px 15px 7px 15px !important;
    z-index: 12;
  }

  .common-header-selectstatus-list {
    max-height: 270px;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      background-color: #fff !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
      border: 10px solid #fff !important;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #fafafa !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
      border: 31px solid #fafafa;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      z-index: 0;
      border-radius: 14px;
      overflow: auto;
      border: 6px solid #ccc;
    }

    .bg-checked {
      background-color: #fef0d3;
    }
  }
}

.common-header-usertype,
.common-header-projectwise,
.common-header-selectactivity {
  position: relative;

  .common-searchBar {
    position: sticky;
    top: 0;
    background-color: #fff;
    padding: 7px 15px 7px 15px !important;
    z-index: 12;
  }
}

.common-header-selectactivity {
  .common-header-selectactivity-list {
    max-height: 250px;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      background-color: #fff !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
      border: 10px solid #fff !important;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #fafafa !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
      border: 31px solid #fafafa;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      z-index: 0;
      border-radius: 14px;
      overflow: auto;
      border: 6px solid #ccc;
    }
  }
}

// .common-header-selectuser{
.custom-opacity {
  opacity: 1 !important;
}

.common-style {
  margin-top: 5px;

  ul {
    padding: 0;

    li {
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0.005em;
      text-align: left;
      display: flex;
      align-items: center;
      margin: 0;
      gap: 5px;
      margin-bottom: 0 !important;
      padding: 5px 15px;
    }
  }

  .mat-menu-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .display-part {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  .select-all-checkbox {
    margin-right: 0;
  }
}

.custom-task-option.ctm-select-all.cst.custom-spacings {
  overflow-y: inherit;
}

.mub-time-log-common-header.report-header-for-date .search-dropdown .select-custom.cst-width {
  width: 220px;
}

.mub-time-log-common-header.report-header-for-date
  .search-dropdown
  .select-custom.second.cst-date {
  width: 270px;
  min-width: 270px;
}

.custom-modal-padding {
  .mat-dialog-container {
    padding: 20px 15px;
  }
}

// Project Report
.project-report-only {
  .report-header {
    .title {
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
      letter-spacing: 0.0015em;
      text-align: left;
    }

    .report-name {
      font-size: 22px;
      font-weight: 500;
      line-height: 36px;
      letter-spacing: 0.0015em;
      text-align: left;
    }
  }

  .project-report-list-table {
    max-height: 865px;

    &::-webkit-scrollbar-track {
      background-color: #fff !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
      border: 10px solid #fff !important;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #fafafa !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
      border: 31px solid #fafafa;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      z-index: 0;
      border-radius: 14px;
      overflow: auto;
      border: 6px solid #ccc;
    }

    table {
      thead {
        tr {
          th {
            position: sticky;
            top: 0;
            background: #fafafa;
            z-index: 123;
          }
        }
      }
    }
  }

  .app-report-searchbar {
    background-color: #fafafa;
    @include common-text($font-size-14, 500, 22px, 0.005em, $text-align-left);
  }

  .project-report-dashboard-table {
    &::-webkit-scrollbar-track {
      background-color: #fff !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
      border: 10px solid #fff !important;
    }
  
    &::-webkit-scrollbar {
      width: 4px;
      background-color: #fafafa !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
      border: 31px solid #fafafa;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      z-index: 0;
      border-radius: 14px;
      overflow: auto;
      border: 6px solid #fff;
    }
    thead {
      tr {
        height: 40px;

        th {
          min-width: 211px;
          width: 211px;
          border: 0px solid transparent;
          @include common-text($font-size-12,
            600,
            18px,
            0.005em,
            $text-align-left );
        }
      }
    }

    tbody {
      tr {
        height: 55px;

        td {
          min-width: 211px;
          width: 211px;
          border-bottom: 1px solid #eee;
          cursor: unset !important;
          @include common-text($font-size-14,
            500,
            22px,
            0.005em,
            $text-align-left,
            $color: #9e9e9e);

          &.mat-column-project_name {
            color: #212121;
            min-width: 311px;
            width: 311px;
            cursor: pointer !important;
          }

          .progress-blocked {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            max-width: 80%;

            .mat-progress-bar-buffer {
              background: #e0e0e0;
            }

            .mat-progress-bar-fill {
              &::after {
                background-color: #16b975;
              }
            }

            .mat-progress-bar {
              border-radius: 2px;
            }

            mat-progress-bar {
              min-width: 85%;
            }
          }
        }
      }
    }
  }



  .card-with-empty {
    padding: 16px 25px;
    border: 1px solid #eee;
    margin: 25px 0;
    border-radius: 8px;
    background-color: #fff;
  }
}

// Project Report COntent Only
.project-report-content-only {
  .user-report-empty-large-card {
    height: calc(calc(100vh - 17em));
  }

  .user-report-empty-small-card {
    height: calc(calc(100vh - 35em));
  }

  .project-report-empty-large-card {
    height: calc(calc(100vh - 31em));
    border: 0px solid;
  }
}

// For Activity reports Styles for empty reports
.user-report-table-inner-only {
  .user-report-empty-small-card {
    height: calc(calc(100vh - 38em));
    margin: 0;
    border: 0px;
  }
}

// For User Report Table Fifteen task
.user-report-table-independent {
  .card-containner {
    max-height: 1010px;
  }
}

// Report Tab Expansion for User Report
.report-tabExpansion-chart {
  .status-bullet-points {
    max-width: 86%;
  }

  .mat-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 0;
  }

  .status-icon-none {
    display: none;
  }
}

// Project Report
.project-report-only {
  .report-header {
    .title {
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
      letter-spacing: 0.0015em;
      text-align: left;
    }
    .report-name {
      font-size: 22px;
      font-weight: 500;
      line-height: 36px;
      letter-spacing: 0.0015em;
      text-align: left;
    }
  }
}

@media screen and (width <= 1566px) {
  .custom-media-first{
    width: 60%;
  }
  .custom-media-second{
    width: 40%;
  }
}


.report-sheet-table .mat-elevation-z8 {
  box-shadow: none;
}