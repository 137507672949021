@import "./custom-variable";
.header-elements {
  padding: 0 24px;
  position: fixed;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  top: 0;
  right: 0;
  z-index: 999;
  height: 60px;
  width: 100%;
  padding-left: 1em !important;
  ul li a > img {
    cursor: pointer;
  }
}

.main-sidebar.mat-drawer {
  border-right: 1px solid $light-grey-color;
  bottom: 0;
  float: none;
  height: calc(100vh - 50px);
  position: fixed;
  top: 60px;
  width: 240px;
  padding: 0;
  z-index: 999;
  .mat-drawer-inner-container {
    background-color: #001528;
    overflow-y: overlay;
  }
  .sidebar-menu-section {
    > li {
      outline: none;
      cursor: pointer;
      .nav-link {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 500;
        background: transparent;
        border-radius: 12px;
        padding: 16px;
        max-width: 95%;
        margin: 0 auto;
        > img {
          margin-right: 14px;
          width: 22px;
          height: 18px;
          filter: invert(63%) sepia(95%) saturate(18%) hue-rotate(309deg)
            brightness(161%) contrast(104%);
        }
        label {
          font-size: $font-size-14;
          line-height: 22px;
          letter-spacing: 0.005em;
          color: #e0e0e0;
          margin-bottom: 0;
        }
        &::after {
          content: none;
          position: absolute;
          width: 8px;
          height: 32px;
          top: 50%;
          transform: translateY(-50%);
          background: var(--primary-color);
          border-radius: 0 14px 14px 0;
          display: none;
          left: -4px;
        }
        &.active-link {
          background: #fff;
          border-radius: 10px;
          padding: 16px;
          max-width: 97%;
          margin: 0 auto;

          > img {
            color: #276ef1;
            filter: invert(47%) sepia(90%) saturate(5477%) hue-rotate(212deg)
              brightness(99%) contrast(92%);

            svg {
              fill: #276ef1;
            }
          }
          label {
            color: #001528;
            font-weight: 600;
          }
        }
      }
    }
  }
  .divider-hr {
    width: 100%;
    border-top: 0px solid #eeeeee;
    margin: 20px 0 10px;
  }
  &.dms-sidebar {
    top: 130px;
    height: calc(100vh - 150px);
    .mat-drawer-inner-container {
      background-color: #fafafa;
      .sidebar-menu-section {
        > li {
          a {
            label {
              color: #9e9e9e;
            }
            &:hover,
            &.active-link {
              background: #edf3fd;
              label {
                color: #9e9e9e;
              }
              img {
                filter: unset;
              }
            }
          }
        }
      }
    }
  }
}

.select-media {
  display: flex;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  padding: 26px 0px 24px;
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
  .c-f-l-name {
    background: $red-color;
    border-radius: 8px;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: font-manrope-600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: $white-color;
    margin-right: 10px;
  }
  .select-mediabody {
    select {
      border: 0;
      background: transparent;
      font-family: font-manrope-600;
      font-size: $font-size-14;
      line-height: 22px;
      letter-spacing: 0.005em;
      color: $text-dark-black-color;
      outline: none;
      margin-left: -4px;
    }
    p {
      font-family: font-manrope-500;
      font-size: $font-size-12;
      line-height: 18px;
      letter-spacing: 0.005em;
      color: $text-lit-grey-500;
      margin-bottom: 0;
    }
  }
}

.l-g-box {
  background: linear-gradient(180deg, #3faff4 0%, #0e9bf1 100%);
  border-radius: 12px;
  padding: 8px 8px 12px 16px;
  width: calc(100% - 32px);
  margin: 16px auto 7px;
  display: flex;
  flex-wrap: wrap;
  .l-g-i-txt {
    font-family: font-manrope-700;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.0025em;
    color: #ffffff;
    margin-bottom: 0;
  }
  .d-r-e-txt {
    font-family: font-manrope-500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.005em;
    color: #ffffff;
    opacity: 0.7;
    margin: 10px 0 20px;
  }
  .l-s-link {
    font-family: font-manrope-700;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.005em;
    color: #ffffff;
  }
}

.sidebar {
  &::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
    border: 1px solid #dcdcdc;
    border-radius: 24px;
  }
}

.scroll-container {
  height: inherit !important;
}

.h-s-list {
  padding: 10px 24px;
}

.profile-dropdown {
  border: 0;
  background: transparent;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  letter-spacing: 0.005em;
  color: #fff;
  > img {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 8px;
  }
  &:hover {
    .user-name {
      color: #001528 !important;
    }
  }
}

.help-section {
  background-image: url(./../icons/FAQ.svg),
    linear-gradient(180deg, #3faff4 0%, #0e9bf1 100%);
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: top 8px right 8px, top right;
  color: #fff;
  .help-start {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.005em;
    color: #ffffff;
    margin-bottom: 0;
    margin-left: auto;
  }
  .help-desc {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.005em;
    color: #ffffff;
    opacity: 0.7;
  }
  h2 {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.0025em;
    color: #ffffff;
    max-width: 98px;
  }
}

.select-area {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 30px 24px 0px;
  > div > div {
    &.avatar,
    & > img {
      border-radius: 8px;
      height: 32px;
      width: 32px;
      object-fit: cover;
      margin-right: 12px;
      min-width: 32px;
      font-weight: 600;
      font-size: 12px;
      line-height: 22px;
      letter-spacing: 0.05rem;
      color: #ffffff;
    }
  }
  .select-field {
    .mat-form-field {
      width: 100%;
      height: inherit;
      display: flex;
      .mat-form-field-wrapper {
        width: 100%;
      }
    }
    .mat-select-value {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.005em;
      color: #fff;
    }
    .count-members {
      margin-top: 0;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.005em;
      color: #9e9e9e;
      padding: 0;
    }
  }
  mat-select + img {
    transform: translateY(10px);
    filter: contrast(4.5);
    top: -10px;
    position: relative;
  }
  .mat-form-field-infix,
  .mat-form-field-appearance-fill .mat-form-field-flex {
    padding: 0 !important;
  }
  .divider-hr {
    width: 100%;
    margin: 20px 0 10px;
  }
}

app-side-bar {
  span.mat-select-min-line {
    color: #fff !important;
  }
}

// User Sidebar CSS
.profile-sidebar {
  width: 68px !important;
  .select-field {
    display: none;
  }
  ul.sidebar-menu-section.list-unstyled {
    li {
      &.h-s-list {
        display: none;
      }
      a {
        label {
          display: none;
        }
      }
    }
  }
  &.main-sidebar.mat-drawer .sidebar-menu-section > li .nav-link {
    padding: 18px 23px;
  }
  .select-area.flex-column {
    padding: 10px;
    .d-flex.align-items-center.w-100 {
      justify-content: center;
      .avatar {
        margin: 0;
      }
    }
  }
}

.profile-sidebar-active {
  .mat-drawer-container.example-container {
    padding-left: 0;
    .dms-sidebar {
      .mat-drawer-inner-container {
        padding-right: 0;
      }
    }
  }
  .mat-drawer-content.custom-mat-drawer-content-wrp {
    margin-left: 65px !important;
  }
}

.custom-mat-drawer-content-wrp {
  &.mat-drawer-content {
    margin-left: 240px !important;
  }
}

.mat-drawer-content-wrap-cls.mat-drawer-content {
  overflow-x: hidden !important;
}

.bell-icons {
  position: relative;
  .unread-notification-count {
    background: #dc3535;
    border-radius: 22px;
    padding: 0px 5px;
    color: #fff;
    text-decoration: none;
    position: absolute;
    top: -3px;
    font-size: 10px;
    font-weight: 400;
    left: 3px;
  }
}

/* Track */
.custom-dropdown-menu-ctm::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #eeeeee !important;
  border-radius: 10px !important;
}

/* Handle */
.custom-dropdown-menu-ctm::-webkit-scrollbar-thumb {
  background: #e0e0e0 !important;
  border-radius: 10px !important;
}

/* Track */
.mat-select-panel::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #eeeeee !important;
  border-radius: 10px !important;
}

/* Handle */
.mat-select-panel::-webkit-scrollbar-thumb {
  background: #e0e0e0 !important;
  border-radius: 10px !important;
}

// Header elements notifications bell
.mub-header-elements {
  li.bell-icons {
    img.mat-menu-trigger {
      width: 24px;
      height: 24px;
    }
    .unread-notification-count {
      left: 12px;
      top: 1px;
    }
  }
}
.user-name img {
  color: #000;
  filter: brightness(2) invert(1);
}
