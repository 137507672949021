.note-card.mat-card {
  border-radius: 0;
  clip-path: polygon(
    0 0,
    100% 0,
    100% 20%,
    100% 92%,
    95% 100%,
    20% 100%,
    0 100%,
    0% 20%
  );
  background: #fef0d3;
  border: 1.5px solid transparent;
  padding: 0;
  cursor: pointer;
  &:hover {
    clip-path: unset;
    border: 1.5px solid #148e96;
    .triangle-images {
      display: none;
    }
  }
  .mat-card-header {
    padding: 16px 16px 8px;
    .mat-card-header-text {
      margin: 0;
    }
    .mat-card-title {
      margin: 0;
      .mat-card-title {
        margin-bottom: 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.005em;
        color: #212121;
      }
    }
  }
  .mat-card-content {
    padding: 0 16px 16px;
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.005em;
      color: #616161;
    }
  }
}

.notes-section {
  .mat-card-header {
    .mat-card-header-text {
      .mat-card-title {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.005em;
        color: #212121;
      }
    }
  }
  .mat-card-content {
    p {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.005em;
      color: #616161;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: 8px;
      strong {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.005em;
        color: #616161;
      }
      em {
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0.005em;
          color: #616161;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      }
    }
    h1 {
      line-height: 0 !important;
      strong {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0.005em;
        line-height: 22px;
        color: #212121;
      }
    }
    ol {
      li {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.005em;
        color: #616161;
      }
    }
    ul {
      li {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.005em;
        color: #616161;
      }
    }
    h3 {
      em {
        span {
          font-family: "Manrope";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.005em;
          color: #212121;
        }
      }
    }
  }
  .notes-edit-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .color-indicator {
      border: none;
      padding: 0;
      min-width: auto;
      &:hover {
        background-color: transparent;
      }
    }
    img {
      cursor: pointer;
    }
  }
  .mat-input-element {
    border: 1px solid #ccc !important;
    padding-left: 12px !important;
  }
}
.shareNoteForm {
  .shareNoteForm-title {
    justify-content: space-between;
    padding: 18px 24px 0;
  }
  .card.mat-card {
    border: none !important;
  }
}
.grid-div-class {
  position: relative;
}

.triangle-images {
  position: absolute;
  bottom: -3px;
  right: -2px;
}
.custom-color-design {
  margin-bottom: 0;
  padding-left: 10px;
  li {
    span {
      display: inline-block;
      padding-bottom: 8px;
    }
  }
}

.note-card mat-card-content.mat-card-content:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  border-width: 16px 0px 0px 16px;
  border-style: solid;
  border-color: transparent;
  background: #000;
  display: block;
  width: 0;
  opacity: 0.1;
}
.note-card.mat-card:hover mat-card-content.mat-card-content:before {
  background: transparent;
}
h2.page-title .form-control:focus {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none;
}
.notes-fixed-scroll::-webkit-scrollbar {
  display: none;
}
.notes-modal mat-dialog-container {
  padding: 0px;
}
// Notes Page Layout Changes
.ctm-nots-tabs {
  .mat-tab-body-content {
    overflow: unset;
  }
  .mat-tab-list {
    .mat-tab-labels {
      .mat-tab-label {
        min-width: 100px;
        padding: 0;
      }
    }
    .mat-tab-label-active {
      opacity: 1;
      .mat-tab-label-content {
        font-weight: 700;
        color: #212121;
      }
    }
    .mat-tab-label-content {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.005em;
      color: #9e9e9e;
    }
  }
}

.add-nots-ctm {
  padding-left: 12px;
  margin: 0 !important;
  li {
    margin: 0 !important;
    .mat-ripple.mat-radio-ripple.mat-focus-indicator {
      display: none;
    }
  }
  li > span {
    padding-bottom: 6px;
    display: inline-block;
  }
}

.notes-edit-icons.notes-edit-icons-ctm {
  display: inline-block !important;
  width: 100%;
  .custom-pin {
    float: left;
  }
  .custom-all-pin {
    float: left;
  }
}

.quill-edit strong {
  font-weight: bolder !important;
}

.grid.all-data.all-notes {
  overflow: hidden scroll !important;
}
app-add-notes .ql-container.ql-snow .ql-editor {
  height: 18vh;
}
.notes-modal-md {
  .ql-editor {
    max-height: 290px !important;
  }
  app-add-notes {
    .ql-container {
      &.ql-snow {
        .ql-editor {
          height: 290px;
        }
      }
    }
  }
}
