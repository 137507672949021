@import "./custom-variable";

@mixin common-text($size, $color, $font-weight, $line-height, $letter-spacing) {
  font-size: $size;
  color: $color;
  font-weight: $font-weight;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
}

.component-heading {
  font-weight: 500;
  font-size: $font-size-24;
  line-height: 36px;
  margin-bottom: unset;
}
.page-title {
  @include common-text($font-size-24, #212121, 700, 2.25rem, 0.0015em);
  &--lg {
    font-size: 36px;
    line-height: 54px;
  }
  &--normal {
    font-weight: 400;
  }
}
.block-title {
  @include common-text($font-size-18, #212121, 700, 1.75rem, 0.0025em);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &-16 {
    font-size: 16px;
  }
  &-32 {
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 0.0015em;
  }
  &-14 {
    font-size: 14px;
  }
  &-10 {
    font-size: 10px;
  }
  &-12 {
    font-size: 12px;
  }
}
.sub-block-title {
  @include common-text($font-size-14, #9e9e9e, 500, 1.375rem, 0.005em);
  &-sm {
    font-size: $font-size-12;
  }
  color: #444;
}
.cmn-txt {
  @include common-text($font-size-14, #616161, 500, 22px, 0.005em);
  &-sm {
    font-size: $font-size-12;
  }
  &-md {
    font-size: 16px;
  }
}

//anchor link
.anchor-link {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.005em;
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
  &:disabled {
    border: 0;
    color: var(--primary-color);
  }
  &.btn {
    &:hover,
    &:focus,
    &:active {
      color: var(--primary-color) !important;
    }
  }
}

.text {
  &-light-grey {
    color: $text-dark-grey-clor;
  }
  &-grey {
    color: $text-gery-color;
  }
  &-light-blue {
    color: #d4e2fc !important;
  }
  &-black {
    color: $text-dark-black-color !important;
  }
  &-red {
    color: #dc3535;
  }
  .white-space-no-wrap {
    white-space: nowrap;
  }
  .bg-light-grey {
    background-color: $bodyBg-color;
  }
  &-dark-blue {
    color: $text-blue-dark-900;
  }
  &-dark-green {
    color: $text-green-dark-900;
  }
  &-dark-orange {
    color: $text-orange-dark-900;
  }
}

.font {
  &-12 {
    font-size: $font-size-12;
  }
  &-10 {
    font-size: 10px;
  }
  &-weight-medium {
    font-weight: 500;
  }
  &-weight-bold {
    font-weight: 700;
  }
}

//field text
.field-label {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.005em;
  color: #616161;
}

.m-s-txt {
  color: #9e9e9e;
  font-weight: 700;
  font-size: 14px;
}

// Log Activity CSS
.log-management-activity-ctm {
  ul {
    li {
      a.mat-menu-trigger:after {
        border-top-color: #9e9e9e !important;
      }
      ul.scrollable-menu {
        border: 1px solid #eeeeee;
        box-shadow: 0px 8px 8px rgba(33, 33, 33, 0.08);
        border-radius: 8px;
        li {
          margin-bottom: 9px;
          margin-top: 9px;
          .img-avatar {
            width: 20px;
            height: 20px;
            object-fit: contain;
          }
          &.active.mention-active {
            a {
              background-color: transparent;
              color: #212121;
            }
          }
          a {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.005em;
            color: #616161;
            padding: 0 7px;
            cursor: pointer;
            .user-short-name {
              height: 20px;
              width: 20px;
              min-height: 20px;
              min-width: 20px;
              font-size: 8px;
            }
          }
        }
      }
    }
  }
}

.log-cal-mega-menu.mat-menu-panel {
  width: 300px;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(33, 33, 33, 0.12);
  border-radius: 8px;
  .mat-menu-content {
    background-color: #ffffff;
  }
  .search-cal-ctm {
    padding: 0;
    overflow-x: hidden;
    .mat-calendar-header {
      padding-top: 0;
      .mat-calendar-controls {
        margin-top: 0;
      }
    }
    .mat-calendar-table {
      .mat-calendar-table-header {
        tr {
          th {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: #616161;
          }
        }
      }
      .mat-calendar-body {
        .mat-calendar-body-label {
          opacity: 0;
        }
        tr {
          td {
            button {
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.005em;
              color: #212121;
            }
          }
        }
      }
    }
  }
  .search-cal-ctm-wrp {
    background-color: #ffffff;
  }
  .search-cal-start-end-date-stm {
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #616161;
    border-top: 1px solid #eeeeee;
    padding: 10px;
  }
  .mat-calendar-controls {
    button:hover {
      color: #212121 !important;
    }
  }
}

// Account Settings Screen CSS
.main-account-settings-wrap-ctm {
  .mat-card {
    background: #ffffff;
    border: 1px solid #e6e6e6 !important;
    border-radius: 12px !important;
    padding: 24px 14px 0px 14px !important;
    .mat-card-header {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
    .mat-card-content {
      height: calc(100vh - 220px);
      overflow-y: auto;
      overflow-x: hidden;
      padding-left: 10px;
      padding-right: 10px;
      ul {
        li {
          .me-auto {
            p {
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }
  }
}

.filter-notification-page-ctm .scrollWindow::-webkit-scrollbar {
  width: 0px !important;
}
.ctm-select-all .mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #276ef1;
}
.ctm-select-all .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #276ef1;
}
.mat-primary .mat-pseudo-checkbox-checked{
  background-color: #276ef1 !important;
}

/* width */
.main-account-settings-wrap-ctm .mat-card .mat-card-content::-webkit-scrollbar {
  width: 6px;
}
/* Track */
.main-account-settings-wrap-ctm
  .mat-card
  .mat-card-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #eeeeee !important;
  border-radius: 6px !important;
}

/* Handle */
.main-account-settings-wrap-ctm
  .mat-card
  .mat-card-content::-webkit-scrollbar-thumb {
  background: #e0e0e0 !important;
  border-radius: 6px !important;
}
