.company-card {
  .divider {
    border-top: 1px solid #eeeeee;
    margin: 22px 0 16px;
  }
  .bottom-info-list {
    h6 {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      letter-spacing: 0.0015em;
    }
    p {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.005em;
    }
  }
}
.s-a-d-menu.mat-menu-panel {
  padding: 24px 24px 0;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 12px 24px rgb(33 33 33 / 16%);
  border-radius: 12px;
  width: 100vw;
  max-width: 380px;
  .mat-menu-content:not(:empty),
  .table > :not(caption) > * > * {
    padding: 0;
  }
  .table {
    margin: 0 -24px;
    width: calc(100% + 48px);
    th,
    td {
      border: 0;
      padding: 12px 0;
    }
    th {
      &,
      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.005em;
        color: #616161;
      }
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.005em;
        color: #9e9e9e;
      }
    }
    td {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.005em;
      color: #212121;
      text-align: right;
    }
    .s-t-row {
      td,
      th {
        background-color: #fff9ee;
      }
    }
    .g-t-row {
      th {
        padding: 14px 0 14px 24px;
        background: #edfaf4;
        color: #12945e;
        font-size: 16px;
      }
      td {
        padding: 14px 24px 14px 0;
        background: #edfaf4;
        color: #12945e;
        font-size: 16px;
      }
    }
  }
}
.p-b-d {
  position: absolute;
  p {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.0015em;
    color: #9e9e9e;
    span {
      color: #212121;
    }
  }
  p + p {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #9e9e9e;
  }
}

:root .down-arrow-btn {
  line-height: 0;
  margin-left: 8px;
  .mat-button-wrapper {
    border: 5px solid transparent;
    border-top-color: #3477dc;
    display: inline-block;
  }
}
