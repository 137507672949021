.math-block-one-calendar-remainder {
  .calendar-title {
    padding: 10px 20px !important;
  }
  .reminder-block {
    .left-side-blocking {
      min-width: 49%;
      .mat-calendar {
        border-bottom: 0px solid #eeeeee !important;
        button {
          span {
            &.mat-button-wrapper {
              span {
                color: #212121;
                font-size: 12px;
                line-height: 18px;
                font-weight: 700;
              }
            }
          }
        }
        .mat-calendar-arrow {
          fill: #424242;
        }
      }
      .mat-calendar-table-header th {
        padding: 7px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.005em;
        color: #9e9e9e;
        padding-bottom: 0px;
      }
      .mat-calendar-controls {
        display: flex;
        margin: 0;
      }
      .mat-calendar-header,
      .mat-calendar-content {
        padding: 0 0 0 0px;
      }
      button.mat-focus-indicator.mat-calendar-period-button.mat-button.mat-button-base {
        padding: 0 0 0 17px;
      }
      .mat-calendar-table-header-divider::after {
        content: none;
      }
    }
    .border-full {
      border-left: 1px solid #eeeeee;
    }
    .right-side-blocking {
      min-width: 50.5%;
      .math_ui-hours {
        padding: 0;
        margin: 0;
        .timer-block {
          display: flex;
          justify-content: space-evenly;
        }
      }
      .mat-form-field-infix {
        padding: 0;
        width: auto;
        border-top: 0;
      }
      .mat-form-field-appearance-outline .mat-form-field-wrapper {
        margin: 0;
        padding: 0;
      }
      .input-mat input {
        width: 90px !important;
      }
      .mat-select {
        margin-left: 17px;
      }
      .mat-select-arrow-wrapper {
        padding-left: 15px;
      }
      .reminder-notify {
        padding: 10px 0 8px 0;
      }
      ul.list-unstyled.a-r-list {
        padding-top: 8px;
      }
      .mat-form-field-appearance-outline .mat-form-field-outline {
        display: none;
      }
      .custom-height {
        height: 40px;
      }
      .a-r-list {
        gap: 5px;
      }
      .c-checkbox-button {
        border: 1px solid transparent;
        .mat-checkbox-layout {
          padding: 5px 7px;
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 4px;

          span.mat-checkbox-label {
            font-family: "Manrope";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 22px;
          }
          .mat-checkbox-inner-container {
            .mat-checkbox-background {
              svg {
                display: none !important;
              }
            }
          }
        }
        &.mat-checkbox-checked {
          background-color: #edf3fd !important;
          border-radius: 4px;
          border: 1px solid #276ef1 !important;
          &.mat-accent {
            .mat-checkbox-background {
              background-color: #edf3fd !important;
              border: 1px solid transparent !important;
              border-radius: 4px;
            }
          }
          span.mat-checkbox-label {
            color: #276ef1;
            font-weight: 700;
            letter-spacing: -0.2px;
          }
        }
      }
    }
    .footer-calendar {
      justify-content: space-between;
      padding: 13px 17px 13px 14px;

      a,
      button {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;

        &.math_ui-text {
          background: transparent !important;
        }
      }
    }
  }
  .typo-text,
  .mat-select-placeholder {
    font-size: 14px;
    line-height: 22px;

    &.font-12 {
      font-size: 12px;
    }
  }
  .inner-first-div {
    padding-right: 3px;
    width: 100%;

    &.customWidth {
      width: 50%;
    }

    .math_ui-remind-on {
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.005em;
      text-align: left;
      color: #444;
    }

    .mat-slide-toggle {
      padding-right: 0px;
    }
    .mat-slide-toggle-bar {
      background-color: #eeeeee;
    }
  }
  .inner-second-div a {
    text-decoration: none;
    cursor: pointer;
  }
}
