@import "./custom-variable";

.table.custom-table {
  box-shadow: none;
  thead {
    tr {
      height: auto;
      th {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.005em;
        color: #9e9e9e;
        padding: 11px 16px;
        box-shadow: none;
        border: 0;
        white-space: nowrap;
        font-weight: 700;
        color: #616161;
        &.blank__th {
          width: 24px;
          padding: 0;
        }
      }
    }
  }
  tbody {
    td {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.005em;
      color: #616161;
      padding: 11px 16px;
      border-bottom: 1px solid #eeeeee;
    }
  }
  &.header-bg {
    th {
      background: #f9f8f8;
    }
  }
  &.v-a-m {
    td,
    th {
      vertical-align: middle;
    }
  }
  &.c-d-table {
    td {
      padding: 16px;
      vertical-align: middle;
    }
  }
  &.n-b-b {
    td {
      border-bottom: 0;
    }
  }
  &.timesheet-table {
    th,
    td {
      padding: 21px 3px;
      vertical-align: middle;
    }
    tfoot {
      th {
        background: #f5f5f5;
        position: relative;
        border-bottom: 0;
        &:first-of-type:after {
          content: "";
          position: absolute;
          right: 100%;
          background: #f5f5f5;
          top: 0;
          height: 100%;
          width: 24px;
        }
        &:last-of-type:after {
          content: "";
          position: absolute;
          left: 100%;
          background: #f5f5f5;
          top: 0;
          height: 100%;
          width: 24px;
        }
      }
    }
  }
}

.inner-detail-table {
  background-color: #fafafa;
  th {
    padding: $font-size-11;
    font-weight: 600;
    padding: 12px 16px;
    line-height: 18px;
    letter-spacing: 0.005em;
    color: $text-dark-grey-clor;
    border-bottom: 1px solid #eeeeee;
  }
  td {
    padding: 16px 11px;
    font-weight: 500;
    font-size: $font-size-11;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #212121;
    border-bottom: 1px solid #eeeeee;
  }
  tfoot {
    background-color: $bodyBg-color !important;
    td {
      border-bottom: 0 !important;
    }
  }
}

:root .i-h-table {
}
.task-subtask
  .task-subtask-parent
  td.mat-cell.cdk-cell.cdk-column-task_name.mat-column-task_name {
  padding-left: 0px !important;
  min-width: 44.7em !important;
  width: 100%;
}
