.card.mat-card {
  box-shadow: none !important;
  border: 1px solid #eeeeee;
  margin-bottom: 0;
  border-radius: 12px;
  transition: 0.2s ease;
  &.doc-card {
    border-radius: 8px;
    .card-footer {
      background: #fff;
      border-top: 0;
    }
  }
  &.box-card {
    border-radius: 12px;
    padding: 24px;
  }
  &.company-card {
    border-radius: 12px;
    border: 1px solid #eeeeee;
    padding: 24px;
  }
  &.hover:hover {
    box-shadow: 0px 4px 12px rgb(33 33 33 / 8%) !important;
    border-color: #67acf0 !important;
  }
  .mat-card-header {
    padding: 24px 24px 24px;
    .mat-card-header-text {
      margin: 0;
    }
  }
  &.inner-detail-card {
    .mat-card-header {
      padding: 16px 16px 8px;
      .block-title {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.005em;
        font-weight: 500;
      }
    }
  }
  &.p-d-card {
    .mat-card-content {
      .u-i {
        height: 64px;
        width: 64px;
        border-radius: 50%;
        object-fit: cover;
        margin: 0 auto;
        margin-bottom: 16px;
      }
      .u-n {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.005em;
        color: #212121;
        margin-bottom: 0;
      }
      .u-p {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.005em;
        color: #9e9e9e;
        margin-bottom: 8px;
      }
    }
  }
}

.m-h-b-c {
  > .mat-card-content {
    padding: 0 15px 15px;
  }
}
.math_ui_block-kanban-master {
  .math_ui_block-kanban-master-card-content {
    div {
      &:nth-child(3) {
        mat-radio-button.mat-radio-checked {
          .mat-radio-label-content {
            color: #0d6efd !important;
            font-weight: 600 !important;
          }
        }
        mat-radio-button {
          label {
            span.mat-radio-container {
              position: absolute !important;
              z-index: -1 !important;
            }
          }
        }
      }
    }
  }
  mat-card.mat-card.mat-focus-indicator.card.box-card.company-info-block {
    box-shadow: 0px 0px 12px #eee !important;
  }
}

.canvas-center {
  display: flex;
  justify-content: center;
  align-items: center;
  .donut-inner {
    position: absolute;
  }
}

.mub-add-status .note-color {
  .custom-radio-spc {
    margin-right: 1.3rem;
    margin-bottom: 1rem;

    .mat-radio-button .mat-radio-ripple {
      position: absolute;
      left: calc(50% - 21px);
      top: calc(50% - 21px);
      height: 35px;
      width: 35px;
    }
    .custom-color-radiobutton {
      .mat-radio-container {
        .mat-radio-inner-circle {
          top: -2px;
          left: -2px;
        }
        .mat-radio-outer-circle {
          &:after {
            height: 35px;
            width: 35px;
            top: -5px;
            left: -5px;
          }
        }
      }
    }
  }
}
