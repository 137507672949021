.mat-drawer.org-setting-sidebar {
  width: 240px;
  height: calc(100vh - 141px);
  border-right: 1px solid #eeeeee;
  padding-top: 16px;
  position: fixed;
  top: 141px;
  .nav-item-list {
    > li {
      > a {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.005em;
        color: #616161;
        text-decoration: none;
        padding: 17px 24px;
        position: relative;
        display: flex;
        align-items: center;
        &:hover,
        &.active {
          background: #edf3fd !important;
          font-weight: 600;
          color: #212121;
          &::before {
            display: block;
          }
        }
      }
    }
  }
}
.checklist-lable-name {
  border-top: 1px solid #dbd9d9ee;
  padding: 10px;
}
.checklist-list {
  > li {
    border-bottom: 1px solid #eeeeee;
    .checklist-box {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.005em;
      color: #212121;
      h4 {
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.0025em;
        color: #212121;
      }
      .mat-checkbox-label {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.005em;
        color: #616161;
      }
    }
    &:last-child {
      border-bottom: 0;
    }
    a {
      text-decoration: none;
      > input {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.005em;
        color: #222;
        margin-top: 2px;
        max-width: 70px;
      }
    }
  }
}
a.checklist-menu-btn {
  min-width: auto;
  padding: 0;
}
.user {
  > img {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 1px solid #ffffff;
    margin: 0 16px;
  }
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #212121;
  }
}
.checklist-permission-table {
  td {
    padding: 17px 0;
    border-bottom: 1px solid #eeeeee;
    align-items: center;
  }
}
.custom-field-box-list {
  > li {
    border-top: 1px solid #eeeeee;
    padding: 12px 0;
  }
}
.day-badge {
  background: #ffffff;
  border: 1px solid #276ef1;
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.005em;
  color: #276ef1;
  padding: 5px 16px;
}
.freeze-day-input {
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  max-width: 120px;
}
.badge-radio {
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  .mat-radio-label {
    display: flex;
    align-items: center;
  }
  .mat-radio-container {
    height: 15px;
    width: 15px;
    .mat-radio-outer-circle {
      height: 15px;
      width: 15px;
      border-color: #bdbdbd;
    }
    .mat-radio-inner-circle {
      height: 9px;
      width: 9px;
      top: 3px;
      left: 3px;
      background: #276ef1 !important;
    }
    & + .mat-radio-label-content {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.005em;
      color: #616161;
      transform: translateY(1px);
    }
  }
  &.mat-radio-checked {
    border-color: #276ef1 !important;
    background: #fff;
    .mat-radio-label-content {
      color: #276ef1 !important;
      font-weight: 700;
    }
    .mat-radio-outer-circle {
      border-color: #276ef1 !important;
    }
  }
}
.disable-link {
  opacity: 0.5;
  pointer-events: none;
}
.timepicker {
  .mat-toolbar-single-row {
    height: auto;
  }
}
.timepicker-form-field {
  margin-left: -15px;
  .mat-form-field-label-wrapper {
    overflow: visible;
    .mat-form-field-label {
      position: absolute;
      width: 200px;
      left: -15px;
      top: 23px;
    }
  }
}
.timesheet-table {
  tbody {
    td {
      white-space: nowrap;
      max-width: 82px;
      min-width: 82px;
    }
  }
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}
.card-grid-view {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  grid-gap: 1rem;

  .text-cut {
    word-break: break-all;
  }
}
app-company-details {
  .company-info-block.company-info-top-block {
    background-color: #f5f5f5;
    padding: 12px 12px 12px 24px !important;
  }
}

.capitalizeText {
  text-transform: capitalize;
}
